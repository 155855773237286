import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styles from './Feedback.module.css';
import { useFireStore } from './../hooks/useFireStore'

export default function Feedback({open,handleClose,user}) {
  const {addDocument, repsonse} = useFireStore('feedback')

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedRating, setSelectedRating] = useState('');
    const [feedback, setFeedback] = useState('');
    const [bug, setBug] = useState('');
  
    const handleFeedbackChange = (event) => {
      setFeedback(event.target.value);
    };
  
    const handleBugChange = (event) => {
      setBug(event.target.value);
    };
    const handleRatingChange = (rating) => {
      setSelectedRating(rating);
    };
    const handleSubmit = () => {
      // Here you can implement the logic to send the feedback and bug report
      console.log('Feedback:', feedback);
      console.log('Bug:', bug);
  
      // Reset the form after submission
      setFeedback('');
      setBug('');

      const userInfo = {
        userAgent: navigator.userAgent,
        browserLanguage: navigator.language,
        screenResolution: window.screen.width + "x" + window.screen.height,
        availableScreenWidth: window.screen.availWidth,
        availableScreenHeight: window.screen.availHeight,
        platform: navigator.platform,
        cookiesEnabled: navigator.cookieEnabled,
        onlineStatus: navigator.onLine,
        currentDate: new Date().toLocaleDateString(),
        timeZoneOffset: new Date().getTimezoneOffset(),
        browserCookiesEnabled: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
        time:Date.now(),
        javaEnabled: navigator.javaEnabled()
    };
    let obj={
      name:user.displayName,
      email:user.email,
      id:user.uid,
      feedback,
      bug,
      selectedRating,
      userInfo
    }
    addDocument(obj)
    handleClose()
    };

  return (
    <>
  
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Feedback and Bug Reporting"}
        </DialogTitle>
        <div className={styles.feedbackContainer}>
        <div className={styles.ratingContainer}>
        <p className={styles.label}>How would you rate your experience?</p>
        <div className={styles.faces}>
          {['terrible', 'bad', 'okay', 'great', 'amazing'].map((rating) => (
            <button
              key={rating}
              className={`${styles.face} ${
                selectedRating === rating ? styles.selected : ''
              }`}
              onClick={() => handleRatingChange(rating)}
            >
              {rating}
            </button>
          ))}
        </div>
      </div>
      <div className={styles.inputContainer}>
        <label htmlFor="feedback" className={styles.label}>Feedback:</label>
        <textarea
          id="feedback"
          value={feedback}
          onChange={handleFeedbackChange}
          className={styles.textarea}
          placeholder="Enter your feedback here..."
        ></textarea>
      </div>
      <div className={styles.inputContainer}>
        <label htmlFor="bug" className={styles.label}>Bug Report:</label>
        <textarea
          id="bug"
          value={bug}
          onChange={handleBugChange}
          className={styles.textarea}
          placeholder="Enter any bugs or issues here..."
        ></textarea>
      </div>

    </div>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            cancel
          </Button>
          <Button onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
