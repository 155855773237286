import React from 'react';
import Index from './Index';
import Perscholas from './perscholas/Index';

const Main = ({ setProfileNotDone, setNewUser }) => {


    return (
        <div>
            <Index setProfileNotDone={setProfileNotDone} setNewUser={setNewUser}/>
            {/* <Perscholas setProfileNotDone={setProfileNotDone} setNewUser={setNewUser}/> */}
        </div>
    );
}

export default Main;
