import {useState,useEffect} from 'react';
import styles from './InterviewTypes.module.css'

import { Typography } from '@mui/material';
const InterviewRole = ({profile,setrequestInfo,requestInfo,userData}) => {
  console.log(profile.real)
  console.log('gggg',userData.targetRole)
  console.log("g",profile.targetRole)
  const [roles,setroles] = useState([userData.targetRole,profile.targetRole])




      useEffect(() => {
        // let results = userData.InterviewRole?.map((item)=>{
        //   console.log("🚀 ~ results ~ item:", item)
        //   let result =list.find((element)=>element.type.toLowerCase()===item.toLowerCase())
        //   if(result) return result
        //   else{
        //     return {type:item,description:"",}
        //   }
        // })
        // console.log("🚀 ~ results ~ profile.interviewTypes:", userData.interviewTypes)
    //   setroles([])
        if(userData.targetRole.toLowerCase() === profile.targetRole.toLowerCase()){
            setroles([userData.targetRole])
            setrequestInfo({ ...requestInfo, role: userData.targetRole })
        }
       
      }, []);




      
    return (
        <div className={styles.container}>
              {roles.map((item,index)=>(
                <div key={index} className={styles.interview} onClick={()=>setrequestInfo({ ...requestInfo, role: item })}
                style={{border:item === requestInfo.role?'#fbae41 solid 3px':''}}
                >
                  

                    <Typography variant="h7" gutterBottom sx={{fontSize:18,m:'auto',textAlign:'center'}}>
                    {item}
      </Typography>
                </div>
              ))}
          

        </div>
    );
}

export default InterviewRole;
