import React, { useState } from 'react';
import styles from './ExperienceLevel.module.css';
// import { ChevronLeft, ChevronRight } from 'lucide-react';

const seniorityLevels = [
  { name: 'Mock Interview', experience: '0 yrs of experience' },
  { name: 'Entry Level', experience: '0-1 yrs of experience' },
  { name: 'Intermediate', experience: '1-3 yrs of experience' },
  { name: 'Mid-senior', experience: '3-5 yrs of experience' },
  { name: 'Senior', experience: '5-8 yrs of experience' },
  { name: 'Senior+', experience: '8-10 yrs of experience' },
  { name: 'Staff', experience: '10+ yrs of experience' },
];
const SenioritySelector = () => {
    const [selectedIndex, setSelectedIndex] = useState(1); // Default to 'Entry Level'
  
    const handleSelect = (index) => {
      setSelectedIndex(index);
      // You can add additional logic here, such as calling a parent component function
      console.log(`Selected: ${seniorityLevels[index].name}`);
    };
  
  return (
    <div className={styles.container}>
    <div className={styles.header}>
      {/* <ChevronLeft className={styles.backIcon} /> */}
      <h2 className={styles.title}>Select Seniority</h2>
    </div>
    <div className={styles.optionsList}>
      {seniorityLevels.map((level, index) => (
        <div 
          key={level.name} 
          className={`${styles.option} ${index === selectedIndex ? styles.selected : ''}`}
          onClick={() => handleSelect(index)}
        >
          <div className={styles.optionContent}>
            <div className={styles.optionName}>{level.name}</div>
            <div className={styles.optionExperience}>{level.experience}</div>
          </div>
          {/* <ChevronRight className={styles.chevronRight} /> */}
        </div>
      ))}
    </div>
  </div>
  );
};
export default SenioritySelector;
