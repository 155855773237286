import React, { useState, useEffect } from "react";

import styles from "../Index.module.css";
import { Alert, Button, Container, TextField, Typography } from "@mui/material";
import CurrentJob from "../CurrentJob";
import { useAuthContext } from "hooks/useAuthContext";
import { useUpdate } from "hooks/useUpdate";
import { useNavigate } from "react-router-dom";
import Education from "../Education";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrentCompany from "../CurrentCompany";
import Availability from "../Availability";
import Course from "./Course";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 30,
    margin: "-10px 0px",
    backgroundColor: theme.palette.mode === "green" ? "green" : "#faad41",
  },
}));

const Index = ({ setProfileNotDone, setNewUser }) => {
  const { update, success } = useUpdate("users");
  const [pages, setPages] = useState([
    "course",
    "experience",
    "availability",
  ]);
  const [currentPage, setCurrentPage] = useState(pages[0]);

  const [PageAnimation, setPageAnimation] = useState("form");
  const { authIsReady, user: userAuth, dispatch, userData } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [bar, setBar] = useState(0);
  const navigate = useNavigate();

  const [isStudent, setIsStudent] = useState(false);
  const [suggestedSkills, setSuggestedSkills] = useState(null);
  const [user, setUser] = useState({
    interviewTypes: [],
    skills: [],
    currentPosition: "",
    currentCompany: "",
  });
  const handleNext = async (e, student, value) => {
    if (e) e.preventDefault();
    console.log("next");
    // setLoading(true)

    let newPages = pages;
     // when role answer came back we either
    // we either add "interviews or we delete it"
    console.log(user)
    
   
    if (currentPage === "experience") {
      if (!student) {
        console.log('is student')
        let index = pages.indexOf("experience");
        newPages.splice(index + 1, 0, "company");
        setPages(newPages);
        setUser((originalUser=>{
          return{...originalUser,isStudent:false}           
        }))  
      } else {
        if (pages.includes("company")) {
          console.log(pages);
          newPages = pages.filter((item) => item !== "company");
          setPages(newPages);
        }
        setUser((originalUser=>{
          return{...originalUser,isStudent:true}           
        }))  
      }
    }

 

    if (currentPage === pages[pages.length - 1]) {
      handleSubmit();
      return;
    }
    let currentIndex = newPages.indexOf(currentPage);
    // setCurrentPage
    let newPage = newPages[currentIndex + 1]

    setPageAnimation("nextVanish");
    await new Promise((resolve) => setTimeout(resolve, 300));
    setPageAnimation("nextAppear");
    setCurrentPage(newPage);

      
if(newPage ==="availability"){

  setBar(100)
}else{
  setBar(bar + 30);
}
    
  };

  const handlePrevious = () => {
    let currentIndex = pages.indexOf(currentPage);
    let newPage = pages[currentIndex - 1]
    setPageAnimation("previousVanish");
    setTimeout(() => {
      setPageAnimation("previousAppear");
      if(newPage ==="course"){
        setBar(0)
      }else{
        setBar(bar - 30);
      }
 
      setCurrentPage(newPage);
    }, 300);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    console.log(user);
    
    if (loading) return;
    setLoading(true);
    setError(null);
    console.log(user);
    try {
      let obj = {
        educationLevel: "certificate",
        areaOfStudy: "Software Engineering",
        university: "Perscholas",
        bootCampGraduate: true,
        bootCamp: "Perscholas",
        currentPosition: "",
        currentCompany: "",
        aboutMe: "",
        skills: [],
        Certifications: [],
        targetRole: "Software Developer",
        targetCompany: "",
        experience: "Entry Level",
        interviewTypes: [],
        availability: [],
        category: "",
        subCategory: "",
        rating: {
          rating: 0,
          numberOfReviews: 0,
          totalRatingNotDevided: 0,
        },
        reviews: [],
        camera: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        interviews: 0,
        costumInterviews: true,
        codingDifficulty: 4,
        codingLevel: null,
        previousCodingProblems: [],
        ...user,
      };
      obj.onBoarding = true;
      obj.perscholas = true;

      switch (obj.course){
        case "Software Engineering":
        obj.course = "Software Engineering"
        obj.skills=["HTML","Css","JavaScript","React","Express",]
        obj.interviewTypes = ["Behavioral Interview","coding Interview","Technical Interview"]
        obj.category = ""
        obj.subCategory = ""
        break;
        default:
      }


      await update(userAuth.uid, obj);

      let privateData = {
        acceptedUsers: [],
        ignoredUsers: [],
        absences: 0,
      };

      dispatch({ type: "profile", payload: { privateData, userData: obj } });
      setProfileNotDone(false);
      setNewUser(false);
      navigate("/browse");

      console.log("success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  var props = {
    setIsStudent,
    handleSubmit,
    user,
    setUser,
    handleNext,
    handlePrevious,
    loading,
    suggestedSkills,
  };

  return (
    <div className={styles.container}>
      {/* <Container> */}

      <div className={styles.filler}></div>
      <div className={styles.top}>
        {currentPage !== "course" && (
          <div onClick={handlePrevious} style={{ cursor: "pointer" }}>
            <ArrowBackIcon sx={{ height: 30, width: 30 }} />
          </div>
        )}
        <div className={styles.bar}>
          <BorderLinearProgress variant="determinate" value={bar} />
        </div>
      </div>

      <form
        onSubmit={bar < 100 ? handleNext : handleSubmit}
        className={`${styles[PageAnimation]} ${styles.form}`}
      >

        {currentPage === "course" && <Course {...props} />}
        {currentPage === "experience" && <CurrentJob {...props} />}
        {currentPage === "company" && <CurrentCompany {...props} />}
        {currentPage === "availability" && <Availability {...props} />}

        <div
          className={styles.buttonContainer}
          style={{
            justifyContent:
              currentPage === "experience" ? "space-between" : "center",
          }}
        >
          {currentPage === "experience" && (
            <Button
              onClick={() => {
                setIsStudent(true);
                handleNext(false, true);
              }}
              type="button"
              color="success"
              variant="contained"
              sx={{ fontSize: 18, width: 200 }}
            >
              {" "}
              I am a student
            </Button>
          )}

          <Button type="submit" variant="contained" sx={{ fontSize: 18 }}>
      
            {currentPage !== "availability"&& "Next"}
            {(currentPage === "availability" && loading)&& "..."}
            {(currentPage === "availability" && !loading)&& "Submit"}
          </Button>
        </div>
      </form>
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          something wrong happened try again
        </Alert>
      )}
    </div>
  );
};

export default Index;
