import React from 'react';
import styles from './meetUsers.module.css'
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SmartToyIcon from '@mui/icons-material/SmartToy';
const Tools = () => {

    return (
        <div>
<div className={styles.container}>

<div className={styles.midContainer}>
              <div className={styles.title}>
              <Typography variant="h2" gutterBottom sx={{color:'White',fontWeight:400}}>
              Powerful Tools to Boost Your  Success
      </Typography>
              </div>
              <div className={styles.content}>


    <div className={styles.card}>
      <SmartToyIcon sx={{width:75,height:75,color:'#4960A7'}}/>
    <Typography variant="h5" gutterBottom sx={{color:'black',fontWeight:400,fontSize:30}}>
    AI-Powered Cover Letter Generator
      </Typography>
    <Typography variant="h6" gutterBottom sx={{color:'black',fontWeight:400,fontSize:20}}>
    Say goodbye to the hassle of writing cover letters! Our AI-driven tool effortlessly crafts personalized and impactful cover letters for you, saving time and eliminating the frustration of trying to find the right words. 
      </Typography>
        <Link to="/coverletter" style={{textDecoration:'none'}}>
      <button style={{width:100}}>
        Generate 
        </button>
        </Link>
    </div>


    <div className={styles.card}>
    <QuestionAnswerIcon sx={{width:75,height:75,color:'#4960A7'}}/>
    <Typography variant="h5" gutterBottom sx={{color:'black',fontWeight:400,fontSize:30}}>
    Extensive Bank of Interview Questions
      </Typography>
    <Typography variant="h6" gutterBottom sx={{color:'black',fontWeight:400,fontSize:20}}>
    Access a comprehensive collection of interview questions across various fields and roles. Practice with real-world scenarios and be prepared for any question that comes your way
      </Typography>
      <Link to="/login" style={{textDecoration:'none'}}>
      <button style={{width:100}}>
         Questions
        </button>
        </Link>
    </div>
    
</div>

              </div>
</div> 
    <div className={styles.allusers}>
        <Link  to="signup"> <button>Sign Up</button> </Link>
    </div>
        </div>
    );
}

export default Tools;
