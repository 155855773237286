import React,{useState,useEffect} from 'react'
import styles from"../content.module.css"
import Typography from '@mui/material/Typography';
import BoltIcon from '@mui/icons-material/Bolt';
import Categories from './Filters';
import jobList from "../../../assets/jobList"
import companyList from "../../../assets/companyList"

export default function FiltersList({setFilters,filter,setFilter}) {
  let list = jobList.map((item)=>item.title)
 

      const interviewtypes = [
        "Behavioral",
        "Technical",
        "System Design",
        "coding interview",
      ];



      const Languages = [
        "C",
        "Python",
        "Java",
        "C++",
        "C#",
        "JavaScript",
        "PHP",
        "SQL",
        "Groovy",
        "Classic Visual Basic",
        "Fortran",
        "R",
        "Ruby",
        "Swift",
        "Matlab",
        "Go",
        "Perl",
        "SASS",
        "pascal",
        "Delphi",
        "Objective-C",
        "Rust",
        "Julia",
        "Ada",
        "Lisp",
        "Dart",
        "Scala",
        "COBOL",
        "ABAP",
        "Kotlin",
        "Lua",
        "TypeScript",
        "HTML",
        "CSS",
        "Visual Basic .NET",
        "Assembly language",
        "F#",
        "Scheme",
        "Prolog",
        "Bash",
        "Angular",
        "Azure",
        "AmazonWebServices",
        "Vue",
        "googleCloud",
        "jQuery",
        "WebSockets",
        "React",
        "React Native",
        "Docker",
        "NodeJs",
        "ExpressJs",
        "Mongodb",
        "Next",
        "Nuxt",
        "Redux",
        "Spring",
        "Hibernate",
        "GitHub",
        "Struts",
        "Google web toolkit",
        "Grails",
        "Vaadin",
        "Blade",
        "Play",
        "Ember",
        "Meteor",
        "Polymer",
        "Aurelia",
        "Backbone",
      ];
    
      const days = [
        "Mon","Tue","Wed","Thu","Fri","Sat","Sun"
      ]
    const categories = [
        { name: "Role", menu: list.filter((com)=>com.length<18) },
      
        { name: "Company", menu: companyList.filter((com)=>com.length<18) },
        { name: "Experience level", menu: [
          "Entry Level",
          "Mid Level",
          "Senior Level",
        ]},
        { name: "Availability", menu: days},
     
      ];

 
    const [filterArray, setFilterArray] = useState([]);
useEffect(() => {
  console.log(filter)
}, [filter])

  return (
    <div className={styles.content}>
 {/* {list.map((item,index)=>(
       <div key={item.name} className={styles.filterItem } onClick={()=>boost(index)} style={item.clicked?{backgroundColor:'#fbae41',color:'#4960a7'}:{}}>
       <Typography variant="h6" gutterBottom sx={{mb:0}}>
       {item.name}
       </Typography>
     <BoltIcon sx={{height:30,width:30,ml:2}}/>
     </div>
    ))} */}
    <div className={styles.filterContainer}>

     {categories.map((category)=>( 
       <Categories  key={category.name} category={category.name} menu={category.menu} setFilter={setFilter} filter={filter} setFilterArray={setFilterArray} filterArray={filterArray}/>
       ))}
      
       </div>
    </div>
  )
}
