import React from "react";

import { useState, useEffect } from "react";

/// materia ui imports
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import InterviewList from "./components/upcomingInterview/Interviews";
import RequestList from "./components/request/RequestList";

import { useAuthContext } from "../../hooks/useAuthContext";

///firebase imports
import useCollection from '../../hooks/useCollection'
import PastInterviews from "./components/pastInterview/PastInterviews";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InterviewsPage() {
  const [value, setValue] = useState(0);

  const {  user,userData,privateData } = useAuthContext();
  const {documents:requests } = useCollection('requests',[['UserReceivingRequestId','==',user.uid]],true)
  console.log("🚀 ~ InterviewsPage ~ requests:", requests)
  const {documents:Interviews,error } = useCollection('interviews',['userIds','array-contains',user.uid],true)


  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <Box sx={{ width: '100%' }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
       { Interviews &&  <Tab label={`Upcoming Practice Interviews {${Interviews.length}}`} {...a11yProps(0)} />}
       { requests &&  <Tab label={`Interview requests {${requests.length}}`} {...a11yProps(1)} />}
       { userData &&  <Tab label={`past interviews {${userData.interviews}}`} {...a11yProps(1)} />}
          {/* <Tab label="Previous Interviews" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <div className="panels">

      <TabPanel value={value} index={0}>
      { Interviews && < InterviewList documents={Interviews} privateData={privateData}/>}
      </TabPanel>
      <TabPanel value={value} index={1}>
      {requests &&  < RequestList documents={requests} user={user}/>}
      </TabPanel>

      <TabPanel value={value} index={2}>
        <PastInterviews/>
      </TabPanel>
      </div>
    </Box>
  )
}