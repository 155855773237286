let users =[
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Administrative Support",
    "interview": false,
    "targetRole": "Office Clerk",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": true,
    "displayName": "amber weasel",
    "userId": "618b91a572184c06a4fc",
    "id": "618b91a572184c06a4fc",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Strategic Planning",
      "Budgeting",
      "Leadership",
      "Supply Chain Management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Operations Manager",
    "currentCompany": "Amazon",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "chocolate duck",
    "userId": "d6c8c869fb954e5bb3b2",
    "id": "d6c8c869fb954e5bb3b2",
    "currentPosition": "Operations Supervisor",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Retail, Wholesale, and E-commerce",
    "subCategory": "Retail Sales",
    "interview": false,
    "targetRole": "Cashier",
    "currentCompany": "",
    "educationLevel": "High school diploma",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": true,
    "displayName": "bronze mongoose",
    "userId": "84e42d7a2fb7410a9c7b",
    "id": "84e42d7a2fb7410a9c7b",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient care",
      "Medication administration",
      "Wound care",
      "IV therapy"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": true,
    "targetRole": "Registered Nurse",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "displayName": "rose unicorn",
    "userId": "2af2f780d2be458b898c",
    "id": "2af2f780d2be458b898c",
    "currentPosition": "Nursing Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Customer Support",
    "interview": false,
    "targetRole": "Customer Service Representative",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": true,
    "displayName": "purple earwig",
    "userId": "3554907a2b784f54ad20",
    "id": "3554907a2b784f54ad20",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 07:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 08:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Internships and Entry-Level Positions",
    "subCategory": "Administrative Support",
    "interview": false,
    "targetRole": "Administrative Assistant",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Office Administration",
    "isEntryLevel": true,
    "displayName": "white ox",
    "userId": "10aee373ec3b44cd91b2",
    "id": "10aee373ec3b44cd91b2",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Financial reporting",
      "Auditing",
      "Tax preparation",
      "Budgeting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Accounting",
    "interview": true,
    "targetRole": "Accountant",
    "currentCompany": "JP Morgan Chase",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "displayName": "maroon hawk",
    "userId": "573a66acf95245bbb998",
    "id": "573a66acf95245bbb998",
    "currentPosition": "Financial Analyst",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Receptionist",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Office Administration",
    "isEntryLevel": true,
    "displayName": "apricot baboon",
    "userId": "4e2f9c59b3e04118abd6",
    "id": "4e2f9c59b3e04118abd6",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Education and Training",
    "subCategory": "K-12 Education",
    "interview": false,
    "targetRole": "Teacher Assistant",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Early Childhood Education",
    "isEntryLevel": true,
    "displayName": "jade cow",
    "userId": "3a7b15bd726842cda608",
    "id": "3a7b15bd726842cda608",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Java",
      "C++",
      "Python",
      "JavaScript",
      "SQL"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "Software Development",
    "interview": true,
    "targetRole": "Software Developer",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": true,
    "displayName": "gold gecko",
    "userId": "c10d48a6b6c54ea79beb",
    "id": "c10d48a6b6c54ea79beb",
    "currentPosition": "",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Sales strategy development",
      "Client relationship management",
      "Team leadership",
      "Negotiation"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Sales",
    "interview": false,
    "targetRole": "Sales Manager",
    "currentCompany": "ABC Corporation",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "moccasin woodpecker",
    "userId": "196d95844b70499e983f",
    "id": "196d95844b70499e983f",
    "currentPosition": "Sales Associate",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Government, Public Administration, and Defense",
    "subCategory": "Law Enforcement",
    "interview": false,
    "targetRole": "Security Guard",
    "currentCompany": "",
    "educationLevel": "High school diploma",
    "areaOfStudy": "Criminal Justice",
    "isEntryLevel": true,
    "displayName": "brown constrictor",
    "userId": "a3d43f181cda49b693b6",
    "id": "a3d43f181cda49b693b6",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient assessment",
      "Medication management",
      "Collaborative care",
      "Critical thinking"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": true,
    "targetRole": "Nurse Practitioner",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Master degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "displayName": "amaranth basilisk",
    "userId": "18d3325d496f444db827",
    "id": "18d3325d496f444db827",
    "currentPosition": "Registered Nurse",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Accounting",
    "interview": false,
    "targetRole": "Bookkeeper",
    "currentCompany": "ABC Accounting Services",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Accounting",
    "isEntryLevel": false,
    "displayName": "indigo gopher",
    "userId": "0e53c97768534918bafc",
    "id": "0e53c97768534918bafc",
    "currentPosition": "Accounting Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Personal Care Aide",
    "interview": false,
    "targetRole": "Personal Care Aide",
    "currentCompany": "",
    "educationLevel": "High school diploma",
    "areaOfStudy": "N/A",
    "isEntryLevel": true,
    "displayName": "jade octopus",
    "userId": "ba1b95d2e7cc4611949a",
    "id": "ba1b95d2e7cc4611949a",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Financial forecasting",
      "Budgeting",
      "Risk management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Finance",
    "interview": false,
    "targetRole": "Financial Manager",
    "currentCompany": "Goldman Sachs",
    "educationLevel": "Master degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "displayName": "tomato raven",
    "userId": "c5c3cf73de204cd79687",
    "id": "c5c3cf73de204cd79687",
    "currentPosition": "Financial Analyst",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Curriculum Development",
      "Classroom Management",
      "Student Assessment"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Education and Training",
    "subCategory": "Higher Education",
    "interview": false,
    "targetRole": "Teacher",
    "currentCompany": "University of California",
    "educationLevel": "Master degree",
    "areaOfStudy": "Education",
    "isEntryLevel": false,
    "displayName": "silver aardwolf",
    "userId": "7d3842f523db4afc9723",
    "id": "7d3842f523db4afc9723",
    "currentPosition": "Graduate Teaching Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Medical Administration",
    "interview": false,
    "targetRole": "Medical Assistant",
    "currentCompany": "UnitedHealth Group",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Medical Assisting",
    "isEntryLevel": false,
    "displayName": "black guanaco",
    "userId": "9659a43acf2f4bcbac0a",
    "id": "9659a43acf2f4bcbac0a",
    "currentPosition": "Medical Billing Specialist",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Manufacturing and Production",
    "subCategory": "Warehousing",
    "interview": false,
    "targetRole": "Warehouse Worker",
    "currentCompany": "",
    "educationLevel": "High school diploma",
    "areaOfStudy": "Logistics",
    "isEntryLevel": true,
    "displayName": "coral alligator",
    "userId": "88849130dd284ee2b87f",
    "id": "88849130dd284ee2b87f",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Data analysis",
      "Financial modeling",
      "Project management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Business Analysis",
    "interview": false,
    "targetRole": "Business Analyst",
    "currentCompany": "green solution llc",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "tan goose",
    "userId": "67054bfaa0344552bfe7",
    "id": "67054bfaa0344552bfe7",
    "currentPosition": "Business Analyst",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Digital marketing",
      "Social media management",
      "Market research",
      "Brand management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Marketing Management",
    "interview": true,
    "targetRole": "Marketing Manager",
    "currentCompany": "Adobe Inc.",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Marketing",
    "isEntryLevel": false,
    "displayName": "brown squirrel",
    "userId": "87e25cd7463145a89a0e",
    "id": "87e25cd7463145a89a0e",
    "currentPosition": "Marketing Specialist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Organizational skills",
      "Communication skills",
      "Budget management",
      "Team leadership"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Administrative Services Manager",
    "currentCompany": "Microsoft",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "teal ermine",
    "userId": "3642d4cca71a48b9b4a7",
    "id": "3642d4cca71a48b9b4a7",
    "currentPosition": "Administrative Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Sales Techniques",
      "Customer Relationship Management",
      "Negotiation Skills",
      "Communication Skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Sales",
    "interview": true,
    "targetRole": "Account Executive",
    "currentCompany": "Amazon",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "tan mouse",
    "userId": "09d0f7e393374b38972e",
    "id": "09d0f7e393374b38972e",
    "currentPosition": "Sales Associate",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "SQL",
      "Python",
      "Network Security",
      "Systems Analysis"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "IT Support",
    "interview": true,
    "targetRole": "Computer Systems Analyst",
    "currentCompany": "ibm",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "displayName": "pink pony",
    "userId": "1c8d8d9ec7c54bf48a3b",
    "id": "1c8d8d9ec7c54bf48a3b",
    "currentPosition": "IT Support Specialist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient assessment",
      "Medical terminology",
      "Prescription management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physician Services",
    "interview": true,
    "targetRole": "Physician Assistant",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Master degree",
    "areaOfStudy": "Physician Assistant Studies",
    "isEntryLevel": false,
    "displayName": "cyan bison",
    "userId": "7cfaacd3f73b422f9dca",
    "id": "7cfaacd3f73b422f9dca",
    "currentPosition": "Medical Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Hospitality, Tourism, and Food Services",
    "subCategory": "Food and Beverage Management",
    "interview": false,
    "targetRole": "Bartender",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Culinary Arts",
    "isEntryLevel": true,
    "displayName": "scarlet loon",
    "userId": "62e79b8095e843eb9823",
    "id": "62e79b8095e843eb9823",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 07:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Recruitment",
      "Employee Relations",
      "Training and Development"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Human Resources",
    "interview": true,
    "targetRole": "Human Resources Specialist",
    "currentCompany": "ABC Corporation",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Human Resources",
    "isEntryLevel": false,
    "displayName": "coffee mastodon",
    "userId": "fafe0bb9d4ee4b33bb69",
    "id": "fafe0bb9d4ee4b33bb69",
    "currentPosition": "HR Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Crisis intervention",
      "Case management",
      "Empathy",
      "Communication skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Community, Social Services, and Human Services",
    "subCategory": "Social Work",
    "interview": true,
    "targetRole": "Social Worker",
    "currentCompany": "UnitedHealth Group",
    "educationLevel": "Master degree",
    "areaOfStudy": "Social Work",
    "isEntryLevel": false,
    "displayName": "coral roundworm",
    "userId": "332420dd6a0b41c3838d",
    "id": "332420dd6a0b41c3838d",
    "currentPosition": "Case Manager",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Arts, Culture, and Entertainment",
    "subCategory": "Graphic Design",
    "interview": false,
    "targetRole": "Graphic Designer",
    "currentCompany": "cvs",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Graphic Design",
    "isEntryLevel": false,
    "displayName": "green cobra",
    "userId": "5ed3c3e459ec455a8a66",
    "id": "5ed3c3e459ec455a8a66",
    "currentPosition": "Graphic Design Intern",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient care",
      "Dental prophylaxis",
      "Radiography",
      "Oral health education"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Dentistry",
    "interview": false,
    "targetRole": "Dental Hygienist",
    "currentCompany": "SmileCare",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Dental Hygiene",
    "isEntryLevel": false,
    "displayName": "aquamarine gibbon",
    "userId": "a138a0801c684e94ad67",
    "id": "a138a0801c684e94ad67",
    "currentPosition": "Dental Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "HTML",
      "CSS",
      "JavaScript",
      "React",
      "Node.js"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "Software Development",
    "interview": true,
    "targetRole": "Web Developer",
    "currentCompany": "Ascensus",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "displayName": "lavender cattle",
    "userId": "9033b66e76ac4228935f",
    "id": "9033b66e76ac4228935f",
    "currentPosition": "Software Developer",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Receptionist",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": true,
    "displayName": "violet falcon",
    "userId": "e89077fea0c345faa209",
    "id": "e89077fea0c345faa209",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Microsoft Office proficiency",
      "Time management",
      "Calendar management",
      "Excellent communication skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": true,
    "targetRole": "Executive Assistant",
    "currentCompany": "Microsoft",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "jade jaguar",
    "userId": "9a6288ea374a40cf8b8c",
    "id": "9a6288ea374a40cf8b8c",
    "currentPosition": "Administrative Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Community, Social Services, and Human Services",
    "subCategory": "Child Welfare",
    "interview": false,
    "targetRole": "Childcare Worker",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Early Childhood Education",
    "isEntryLevel": true,
    "displayName": "silver amphibian",
    "userId": "0f7f248eb5814fee8978",
    "id": "0f7f248eb5814fee8978",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Java",
      "Python",
      "C++",
      "JavaScript",
      "SQL"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "Software Development",
    "interview": true,
    "targetRole": "Software Engineer",
    "currentCompany": "Tech Inc.",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "displayName": "cyan cow",
    "userId": "f6f2c607007f44d48b84",
    "id": "f6f2c607007f44d48b84",
    "currentPosition": "Junior Web Developer",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Medical terminology knowledge",
      "Medical billing and coding",
      "Proficiency in electronic health records systems"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Medical Administration",
    "interview": false,
    "targetRole": "Medical Secretary",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Healthcare Administration",
    "isEntryLevel": false,
    "displayName": "lavender koi",
    "userId": "a11a9eabbb8c41e9973b",
    "id": "a11a9eabbb8c41e9973b",
    "currentPosition": "Medical Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Microsoft Office Suite proficiency",
      "Excellent organizational skills",
      "Strong communication skills",
      "Experience with scheduling and event planning"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Office Manager",
    "currentCompany": "XYZ Corporation",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "displayName": "coffee unicorn",
    "userId": "b3335d4f90eb43c9afb4",
    "id": "b3335d4f90eb43c9afb4",
    "currentPosition": "Administrative Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Social media marketing",
      "SEO optimization",
      "Marketing analytics"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Market Research",
    "interview": false,
    "targetRole": "Marketing Specialist",
    "currentCompany": "Nike",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Marketing",
    "isEntryLevel": false,
    "displayName": "bronze crane",
    "userId": "62b3c9e2ef0e46eaacab",
    "id": "62b3c9e2ef0e46eaacab",
    "currentPosition": "Marketing Coordinator",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Financial modeling",
      "Budgeting",
      "Data analysis",
      "Excel proficiency"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Financial Analysis",
    "interview": true,
    "targetRole": "Financial Analyst",
    "currentCompany": "Bank of America",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "displayName": "turquoise chimpanzee",
    "userId": "5183a72dd4324f3eb882",
    "id": "5183a72dd4324f3eb882",
    "currentPosition": "Financial Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient care",
      "Medication administration",
      "Wound care",
      "Medical charting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": true,
    "targetRole": "Registered Nurse",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "displayName": "tomato bat",
    "userId": "4b6242962b074a1b8d69",
    "id": "4b6242962b074a1b8d69",
    "currentPosition": "Nursing Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Education and Training",
    "subCategory": "K-12 Education",
    "interview": false,
    "targetRole": "Teacher",
    "currentCompany": "Walden University",
    "educationLevel": "Master degree",
    "areaOfStudy": "Education",
    "isEntryLevel": false,
    "yoe": 5,
    "displayName": "teal spider",
    "userId": "81f8fbfd5f9b4e74a2ef",
    "id": "81f8fbfd5f9b4e74a2ef",
    "currentPosition": "Education Coordinator",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Therapeutic exercise techniques",
      "Manual therapy techniques",
      "Patient assessment and treatment planning"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physical Therapy",
    "interview": true,
    "targetRole": "Physical Therapist",
    "currentCompany": "HealthSouth Corporation",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Physical Therapy",
    "isEntryLevel": false,
    "yoe": 5,
    "displayName": "chocolate albatross",
    "userId": "ddab25f940d24e20b078",
    "id": "ddab25f940d24e20b078",
    "currentPosition": "Physical Therapy Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Dentistry",
    "interview": false,
    "targetRole": "Dental Assistant",
    "currentCompany": "ABC Dental Clinic",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Dental Assisting",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "chocolate woodpecker",
    "userId": "c1476afefd0542dcb99e",
    "id": "c1476afefd0542dcb99e",
    "currentPosition": "Dental Receptionist",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Surgical procedures",
      "Animal care",
      "Diagnostic imaging"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Veterinary Services",
    "interview": true,
    "targetRole": "Veterinarian",
    "currentCompany": "Banfield Pet Hospital",
    "educationLevel": "Doctoral degree",
    "areaOfStudy": "Veterinary Medicine",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "scarlet hamster",
    "userId": "bb07316dd27d4979abf6",
    "id": "bb07316dd27d4979abf6",
    "currentPosition": "Veterinary Technician",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Banking",
    "interview": false,
    "targetRole": "Bank Teller",
    "currentCompany": "Wells Fargo",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "silver shrew",
    "userId": "2236c127749844aeb86a",
    "id": "2236c127749844aeb86a",
    "currentPosition": "Customer Service Representative",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "knowledge of legal terminology",
      "familiarity with legal software",
      "excellent communication skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Corporate Law",
    "interview": true,
    "targetRole": "Legal Secretary",
    "currentCompany": "LegalZoom",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Legal Studies",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "crimson hyena",
    "userId": "e4bf2bc3025c4ac1acbc",
    "id": "e4bf2bc3025c4ac1acbc",
    "currentPosition": "Legal Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Legal research",
      "Document drafting",
      "Case management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Litigation",
    "interview": true,
    "targetRole": "Paralegal",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Paralegal Studies",
    "isEntryLevel": true,
    "yoe": 2,
    "displayName": "tomato parrot",
    "userId": "a2b8785ffdc7496ea83e",
    "id": "a2b8785ffdc7496ea83e",
    "currentPosition": "",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Legal research",
      "Writing legal documents",
      "Client communication"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Litigation",
    "interview": true,
    "targetRole": "Lawyer",
    "currentCompany": "LegalZoom",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": " Legal Studies",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "orange mole",
    "userId": "13ca1818b08945d4a849",
    "id": "13ca1818b08945d4a849",
    "currentPosition": "Paralegal",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Crisis management",
      "Conflict resolution",
      "Law enforcement procedures"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Government, Public Administration, and Defense",
    "subCategory": "Law Enforcement",
    "interview": false,
    "targetRole": "Police Officer",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Criminal Justice",
    "isEntryLevel": true,
    "yoe": 3,
    "displayName": "yellow orangutan",
    "userId": "25ab44fbef7d4650ad8d",
    "id": "25ab44fbef7d4650ad8d",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "CPR",
      "First Aid",
      "Emergency response",
      "Firefighting techniques"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Government, Public Administration, and Defense",
    "subCategory": "Emergency Management",
    "interview": false,
    "targetRole": "Firefighter",
    "currentCompany": "Ambulnz",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Fire Science",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "jade marmot",
    "userId": "1e6b8f9376ee4851ab82",
    "id": "1e6b8f9376ee4851ab82",
    "currentPosition": "Emergency Medical Technician",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Network security",
      "Vulnerability management",
      "Incident response",
      "Security information and event management (SIEM)"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "Information Security",
    "interview": true,
    "targetRole": "Security Analyst",
    "currentCompany": "IBM",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Cybersecurity",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "black chipmunk",
    "userId": "a2db2013d73541078941",
    "id": "a2db2013d73541078941",
    "currentPosition": "Cybersecurity Specialist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Negotiation",
      "Communication",
      "Sales",
      "Knowledge of Real Estate Market"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Real Estate",
    "interview": false,
    "targetRole": "Real Estate Agent",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": true,
    "yoe": 3,
    "displayName": "harlequin armadillo",
    "userId": "1c7a2175a4594b7680c6",
    "id": "1c7a2175a4594b7680c6",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Sales skills",
      "Customer service",
      "Product knowledge",
      "Communication skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Insurance",
    "interview": false,
    "targetRole": "Insurance Agent",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Insurance and Risk Management",
    "isEntryLevel": true,
    "yoe": 2,
    "displayName": "lavender bug",
    "userId": "08eb38da275542ae89cc",
    "id": "08eb38da275542ae89cc",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Sports, Fitness, and Recreation",
    "subCategory": "Fitness Instruction",
    "interview": false,
    "targetRole": "Fitness Trainer",
    "currentCompany": "Gold's Gym",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Exercise Science",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "beige carp",
    "userId": "1235f0f30ff044bca9e0",
    "id": "1235f0f30ff044bca9e0",
    "currentPosition": "Personal Trainer",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Customer Service",
      "Inventory Management",
      "Staff Training",
      "Budgeting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Hospitality, Tourism, and Food Services",
    "subCategory": "Restaurant Management",
    "interview": true,
    "targetRole": "Restaurant Manager",
    "currentCompany": "Chipotle Mexican Grill",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Hospitality Management",
    "isEntryLevel": false,
    "yoe": 5,
    "displayName": "brown jackal",
    "userId": "61b9756a5eae418484a7",
    "id": "61b9756a5eae418484a7",
    "currentPosition": "Assistant Manager",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "SolidWorks",
      "AutoCAD",
      "Thermodynamics",
      "Fluid Mechanics"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Mechanical Engineering",
    "interview": true,
    "targetRole": "Mechanical Engineer",
    "currentCompany": "Tesla",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Mechanical Engineering",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "peach parrotfish",
    "userId": "7f669e97210c453a913c",
    "id": "7f669e97210c453a913c",
    "currentPosition": "Mechanical Technician",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "AutoCAD",
      "Structural Analysis",
      "Geotechnical Engineering",
      "Project Management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Architecture, Engineering, and Construction",
    "subCategory": "Civil Engineering",
    "interview": true,
    "targetRole": "Civil Engineer",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Civil Engineering",
    "isEntryLevel": true,
    "yoe": 2,
    "displayName": "coral termite",
    "userId": "0f216d0457f142639650",
    "id": "0f216d0457f142639650",
    "currentPosition": "",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Emergency Medical Services",
    "interview": false,
    "targetRole": "Paramedic",
    "currentCompany": "American Medical Response",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Emergency Medical Services",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "lime hornet",
    "userId": "9e8723fce2eb482797d0",
    "id": "9e8723fce2eb482797d0",
    "currentPosition": "Emergency Medical Technician",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Nutritional counseling",
      "Menu planning",
      "Food science knowledge"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Occupational Therapy",
    "interview": true,
    "targetRole": "Dietitian",
    "currentCompany": "Whole Foods Market",
    "educationLevel": "Master degree",
    "areaOfStudy": "Nutrition and Dietetics",
    "isEntryLevel": false,
    "yoe": 4,
    "displayName": "chocolate salamander",
    "userId": "6f4cba0f1b1949e7bd1f",
    "id": "6f4cba0f1b1949e7bd1f",
    "currentPosition": "Nutritionist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Medical Administration",
    "interview": false,
    "targetRole": "Pharmacy Technician",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Pharmacy Technician",
    "isEntryLevel": true,
    "yoe": 2,
    "displayName": "olive toucan",
    "userId": "af204eaf2a584f69a44b",
    "id": "af204eaf2a584f69a44b",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Occupational Therapy",
    "interview": false,
    "targetRole": "Esthetician",
    "currentCompany": "Soothe",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Cosmetology",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "yellow armadillo",
    "userId": "3c3f83e05bee42b8b7a0",
    "id": "3c3f83e05bee42b8b7a0",
    "currentPosition": "Spa Receptionist",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Transportation, Distribution, and Logistics",
    "subCategory": "Air Transportation",
    "interview": false,
    "targetRole": "Flight Attendant",
    "currentCompany": "Delta Airlines",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Hospitality Management",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "violet loon",
    "userId": "9a5663d3274c403d9c9d",
    "id": "9a5663d3274c403d9c9d",
    "currentPosition": "Customer Service Representative",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "diagnosis",
      "patient care",
      "medical knowledge"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physician Services",
    "interview": true,
    "targetRole": "Doctor",
    "currentCompany": "Johns Hopkins Medicine",
    "educationLevel": "Doctoral degree",
    "areaOfStudy": "Medicine",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "maroon skunk",
    "userId": "665fb874369042a7b29f",
    "id": "665fb874369042a7b29f",
    "currentPosition": "Medical Resident",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Retail, Wholesale, and E-commerce",
    "subCategory": "Retail Sales",
    "interview": false,
    "targetRole": "Cashier",
    "currentCompany": "",
    "educationLevel": "High school diploma",
    "areaOfStudy": "Retail Management",
    "isEntryLevel": true,
    "yoe": 2,
    "displayName": "yellow ladybug",
    "userId": "6b81d325ac9a43ba8974",
    "id": "6b81d325ac9a43ba8974",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Business, Management, and Administration",
    "subCategory": "Operations Management",
    "interview": false,
    "targetRole": "Office Clerk",
    "currentCompany": "ABC Corp",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "red minnow",
    "userId": "b5027b35afe542c5b926",
    "id": "b5027b35afe542c5b926",
    "currentPosition": "Administrative Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient care",
      "Medication administration",
      "Wound care",
      "Documentation"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": false,
    "targetRole": "Nurse ",
    "currentCompany": "ABC Healthcare",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "chocolate dragonfly",
    "userId": "370dc913fa844519b907",
    "id": "370dc913fa844519b907",
    "currentPosition": "Licensed Practical Nurse",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Financial analysis",
      "Accounting software proficiency",
      "Budgeting",
      "Financial reporting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Accounting",
    "interview": true,
    "targetRole": "Accountant",
    "currentCompany": "Bank of America",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "brown walrus",
    "userId": "fb4e497fc2654bb099a4",
    "id": "fb4e497fc2654bb099a4",
    "currentPosition": "Financial Analyst",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Education and Training",
    "subCategory": "K-12 Education",
    "interview": false,
    "targetRole": "Teacher Assistant",
    "currentCompany": "YMCA",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Education",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "moccasin rattlesnake",
    "userId": "99e358b336d24632aae3",
    "id": "99e358b336d24632aae3",
    "currentPosition": "Camp Counselor",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Software Development",
    "interview": false,
    "targetRole": "Software Developer",
    "currentCompany": "XYZ Technologies",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "cyan fly",
    "userId": "066bb1b2ab7d4a83b4f0",
    "id": "066bb1b2ab7d4a83b4f0",
    "currentPosition": "Junior Software Engineer",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Sales pitching",
      "Customer relationship management",
      "Negotiation",
      "Team leadership"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Sales",
    "interview": true,
    "targetRole": "Sales Manager",
    "currentCompany": "ABC Company",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "yoe": 4,
    "aboutMe": "",
    "displayName": "aqua cow",
    "userId": "66727186cf464774b0e3",
    "id": "66727186cf464774b0e3",
    "currentPosition": "Sales Associate",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Patient assessment",
      "Medication administration",
      "Patient education",
      "Diagnostic testing"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": true,
    "targetRole": "Nurse Practitioner",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Master degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "yoe": 5,
    "displayName": "teal mollusk",
    "userId": "31aa5454b596403fb86b",
    "id": "31aa5454b596403fb86b",
    "currentPosition": "Registered Nurse",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Accounting",
    "interview": false,
    "targetRole": "Bookkeeper",
    "currentCompany": "ABC Company",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Accounting",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "tomato mite",
    "userId": "ed7f3f73908f4c4b85df",
    "id": "ed7f3f73908f4c4b85df",
    "currentPosition": "Accounting Clerk",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": false,
    "targetRole": "Medical Assistant",
    "currentCompany": "St. Joseph Health",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Nursing",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "beige cephalopod",
    "userId": "404384f62e794bd69ed8",
    "id": "404384f62e794bd69ed8",
    "currentPosition": "Nursing Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Digital Marketing",
      "Social Media Marketing",
      "Brand Management"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Marketing",
    "interview": true,
    "targetRole": "Marketing Manager",
    "currentCompany": "HubSpot",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Marketing",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "sapphire monkey",
    "userId": "fa3145f151b5445191a2",
    "id": "fa3145f151b5445191a2",
    "currentPosition": "Marketing Specialist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physician Services",
    "interview": false,
    "targetRole": "Physician Assistant",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Master degree",
    "areaOfStudy": "Physician Assistant Studies",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "copper guan",
    "userId": "c923fe2cd9dd4f6a927d",
    "id": "c923fe2cd9dd4f6a927d",
    "currentPosition": "Medical Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:45 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:15 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:45 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:15 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:20 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:45 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Recruitment",
      "Employee Relations",
      "HRIS",
      "Onboarding"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Human Resources and Talent Management",
    "subCategory": "Recruitment and Staffing",
    "interview": false,
    "targetRole": "Human Resources Specialist",
    "currentCompany": "green solutions",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Human Resources Management",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "harlequin armadillo",
    "userId": "673344f35a7845cbabf5",
    "id": "673344f35a7845cbabf5",
    "currentPosition": "HR Coordinator",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Community, Social Services, and Human Services",
    "subCategory": "Social Work",
    "interview": false,
    "targetRole": "Social Worker",
    "currentCompany": "American Red Cross",
    "educationLevel": "Master degree",
    "areaOfStudy": "Social Work",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "tomato louse",
    "userId": "24e9d1d804fb49adb3c9",
    "id": "24e9d1d804fb49adb3c9",
    "currentPosition": "Case Manager",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Arts, Culture, and Entertainment",
    "subCategory": "Graphic Design",
    "interview": false,
    "targetRole": "Graphic Designer",
    "currentCompany": "XYZ Design Studio",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Graphic Design",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "violet leopard",
    "userId": "3c8f3c531556410a92a4",
    "id": "3c8f3c531556410a92a4",
    "currentPosition": "Junior Designer",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Nursing",
    "interview": false,
    "targetRole": "Dental Hygienist",
    "currentCompany": "Smile Dental Clinic",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Dental Hygiene",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "pink ferret",
    "userId": "21828b4685de4a84829e",
    "id": "21828b4685de4a84829e",
    "currentPosition": "Dental Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physical Therapy",
    "interview": false,
    "targetRole": "Physical Therapist Assistant",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Physical Therapy Assistant",
    "isEntryLevel": true,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "apricot roundworm",
    "userId": "40edc3df48e94d37b834",
    "id": "40edc3df48e94d37b834",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 08:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Medical Administration",
    "interview": false,
    "targetRole": "Medical Secretary",
    "currentCompany": "Mayo Clinic",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Medical Office Management",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "red firefly",
    "userId": "36f6c7ac56314e279da9",
    "id": "36f6c7ac56314e279da9",
    "currentPosition": "Medical Administrative Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "SEO optimization",
      "Content creation",
      "Social media marketing",
      "Email marketing"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Marketing",
    "interview": true,
    "targetRole": "Marketing Specialist",
    "currentCompany": "HubSpot",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Marketing",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "plum hyena",
    "userId": "ec11c162d8c142beada6",
    "id": "ec11c162d8c142beada6",
    "currentPosition": "Marketing Coordinator",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Financial modeling",
      "Data analysis",
      "Budgeting",
      "Forecasting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Financial Analysis",
    "interview": true,
    "targetRole": "Financial Analyst",
    "currentCompany": "Berkshire Hathaway",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "turquoise snake",
    "userId": "415a3fc46f494f6889be",
    "id": "415a3fc46f494f6889be",
    "currentPosition": "Junior Financial Analyst",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Education and Training",
    "subCategory": "K-12 Education",
    "interview": false,
    "targetRole": "Teacher",
    "currentCompany": "ABC Learning Center",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Education",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "indigo peacock",
    "userId": "becf5f2b9bed4021bf87",
    "id": "becf5f2b9bed4021bf87",
    "currentPosition": "Instructional Aide",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Manual Therapy",
      "Exercise Prescription",
      "Patient Assessment"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Physical Therapy",
    "interview": true,
    "targetRole": "Physical Therapist",
    "currentCompany": "ABC Physical Therapy",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Physical Therapy",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "beige mongoose",
    "userId": "7d13b0cc649347e993e8",
    "id": "7d13b0cc649347e993e8",
    "currentPosition": "Physical Therapy Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Occupational Therapy",
    "interview": false,
    "targetRole": "Occupational Therapist",
    "currentCompany": "HealthSouth",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Occupational Therapy",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "tomato python",
    "userId": "b562a38264f345ca9359",
    "id": "b562a38264f345ca9359",
    "currentPosition": "Occupational Therapy Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Other Medical Services",
    "interview": false,
    "targetRole": "Dental Assistant",
    "currentCompany": "",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Dental Assisting",
    "isEntryLevel": true,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "apricot ermine",
    "userId": "a405f9c9bb384846bc95",
    "id": "a405f9c9bb384846bc95",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Animal handling",
      "Medical knowledge",
      "Surgical skills",
      "Customer service"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Veterinary Services",
    "interview": true,
    "targetRole": "Veterinarian",
    "currentCompany": "Banfield Pet Hospital",
    "educationLevel": "Doctoral degree",
    "areaOfStudy": "Veterinary Medicine",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "violet minnow",
    "userId": "1d1f2132fb534687b9c4",
    "id": "1d1f2132fb534687b9c4",
    "currentPosition": "Veterinary Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Banking",
    "interview": false,
    "targetRole": "Bank Teller",
    "currentCompany": "",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "isEntryLevel": true,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "brown puma",
    "userId": "ac9f8ea8c9cb448aaf52",
    "id": "ac9f8ea8c9cb448aaf52",
    "currentPosition": "",
    "interviewTypes": [
      "General Interview"
    ],
    "isStudent": true,
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Customer relationship management",
      "Negotiation",
      "Sales prospecting"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Marketing, Sales, and Customer Service",
    "subCategory": "Sales",
    "interview": false,
    "targetRole": "Sales Representative",
    "currentCompany": "Salesforce",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Business Administration",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "lime tapir",
    "userId": "b627dd18b0c7440195fa",
    "id": "b627dd18b0c7440195fa",
    "currentPosition": "Account Executive",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Legal Services",
    "interview": false,
    "targetRole": "Legal Secretary",
    "currentCompany": "Jones Day",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Legal Studies",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "lavender piranha",
    "userId": "2c058aa7ac584c60b3c4",
    "id": "2c058aa7ac584c60b3c4",
    "currentPosition": "Paralegal",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Legal research",
      "Case management",
      "Legal writing"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Legal Services",
    "interview": true,
    "targetRole": "Paralegal",
    "currentCompany": "Smith & Associates Law Firm",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Legal Studies",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "gray sloth",
    "userId": "61c7b254b5ee489e831e",
    "id": "61c7b254b5ee489e831e",
    "currentPosition": "Legal Assistant",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Legal research",
      "Writing legal briefs",
      "Client counseling"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Law, Public Policy, and Legal Services",
    "subCategory": "Corporate Law",
    "interview": true,
    "targetRole": "Lawyer",
    "currentCompany": "Jones Day",
    "educationLevel": "Doctoral degree",
    "areaOfStudy": "Law",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "magenta wren",
    "userId": "8e548d943ef5445f8627",
    "id": "8e548d943ef5445f8627",
    "currentPosition": "Associate Attorney",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Emergency response procedures",
      "Criminal law knowledge",
      "Physical fitness",
      "Communication skills"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Government, Public Administration, and Defense",
    "subCategory": "Law Enforcement",
    "interview": false,
    "targetRole": "Police Officer",
    "currentCompany": "Securitas USA",
    "educationLevel": "High school diploma",
    "areaOfStudy": "Criminal Justice",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "tomato ferret",
    "userId": "f721aac6f09d479cbf46",
    "id": "f721aac6f09d479cbf46",
    "currentPosition": "Security Officer",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Government, Public Administration, and Defense",
    "subCategory": "Emergency Management",
    "interview": false,
    "targetRole": "Firefighter",
    "currentCompany": "American Medical Response",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Emergency Medical Services",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "amber coral",
    "userId": "0e5f84a3f1f54161963c",
    "id": "0e5f84a3f1f54161963c",
    "currentPosition": "Emergency Medical Technician",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 08:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "network security",
      "penetration testing",
      "incident response"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Communications, Media, and Information Technology",
    "subCategory": "Information Security",
    "interview": true,
    "targetRole": "Security Analyst",
    "currentCompany": "Microsoft",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "orange rook",
    "userId": "db8a9b28d4e347c28998",
    "id": "db8a9b28d4e347c28998",
    "currentPosition": "IT Specialist",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Negotiation",
      "Client Relationship Management",
      "Market Analysis"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Real Estate",
    "interview": false,
    "targetRole": "Real Estate Agent",
    "currentCompany": "Keller Williams Realty",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Real Estate",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "bronze hookworm",
    "userId": "f4c177ae0cbe425388e1",
    "id": "f4c177ae0cbe425388e1",
    "currentPosition": "Real Estate Assistant",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "Customer Service",
      "Negotiation",
      "Risk Assessment"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Finance, Insurance, and Real Estate",
    "subCategory": "Insurance",
    "interview": false,
    "targetRole": "Insurance Agent",
    "currentCompany": "State Farm Insurance",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Insurance and Risk Management",
    "isEntryLevel": false,
    "yoe": 4,
    "aboutMe": "",
    "displayName": "purple capybara",
    "userId": "aa969d4da5be494a9f1d",
    "id": "aa969d4da5be494a9f1d",
    "currentPosition": "Claims Adjuster",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Sports, Fitness, and Recreation",
    "subCategory": "Sports Management",
    "interview": false,
    "targetRole": "Fitness Trainer",
    "currentCompany": "Equinox",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Exercise Science",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "salmon dolphin",
    "userId": "239741976fa04366a1c1",
    "id": "239741976fa04366a1c1",
    "currentPosition": "Personal Trainer",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "SQL",
      "Python",
      "Data Visualization",
      "Statistical Analysis"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Professional, Scientific, and Technical Services",
    "subCategory": "Analytics",
    "interview": true,
    "targetRole": "Data Analyst",
    "currentCompany": "ABC Corporation",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Data Science",
    "isEntryLevel": false,
    "yoe": 2,
    "displayName": "ivory gerbil",
    "userId": "d1d7b51fbfe3465ebe22",
    "id": "d1d7b51fbfe3465ebe22",
    "currentPosition": "Data Entry Clerk",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Installation, Repair, and Maintenance",
    "subCategory": "Electrical Installation",
    "interview": false,
    "targetRole": "Electrician",
    "currentCompany": "ABC Electrical Services",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Electrical Technology",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "copper rooster",
    "userId": "94c55a6cbad34336a329",
    "id": "94c55a6cbad34336a329",
    "currentPosition": "Apprentice Electrician",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Installation, Repair, and Maintenance",
    "subCategory": "HVAC Technician",
    "interview": false,
    "targetRole": "HVAC Technician",
    "currentCompany": "General Electric",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Heating, Ventilation, Air Conditioning, and Refrigeration Technology",
    "isEntryLevel": false,
    "yoe": 3,
    "displayName": "magenta bat",
    "userId": "b00d7890405949d7a12d",
    "id": "b00d7890405949d7a12d",
    "currentPosition": "Maintenance Technician",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 05:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 06:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "aboutMe": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "AutoCAD",
      "SolidWorks",
      "Finite Element Analysis"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Architecture, Engineering, and Construction",
    "subCategory": "Mechanical Engineering",
    "interview": true,
    "targetRole": "Mechanical Engineer",
    "currentCompany": "Boeing",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Mechanical Engineering",
    "isEntryLevel": false,
    "yoe": 3,
    "aboutMe": "",
    "displayName": "harlequin mink",
    "userId": "b398990e0e6d4ca1a9f1",
    "id": "b398990e0e6d4ca1a9f1",
    "currentPosition": "Engineering Technician",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:30 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:30 PM",
            "clicked": true
          },
          {
            "HourFormated": " 02:30 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "AutoCAD",
      "Structural Analysis",
      "Geotechnical Engineering"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Architecture, Engineering, and Construction",
    "subCategory": "Civil Engineering",
    "interview": true,
    "targetRole": "Civil Engineer",
    "currentCompany": "Jacobs Engineering Group",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Civil Engineering",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "green lark",
    "userId": "cf6ece216ace40fc9405",
    "id": "cf6ece216ace40fc9405",
    "currentPosition": "Junior Civil Engineer",
    "interviewTypes": [
      "Behavioral Interview",
      "Technical Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "rating": {
      "totalRatingNotDevided": 0,
      "numberOfReviews": 0,
      "rating": 0
    },
    "platform": "peerfect2023",
    "interviews": 0,
    "topics": [
      "CPR certification",
      "Basic life support",
      "Patient assessment",
      "Medical treatments",
      "Emergency vehicle operation"
    ],
    "previousCodingProblems": [],
    "reviews": [],
    "codingLevel": null,
    "camera": true,
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "active": true,
    "timeZone": "America/New_York",
    "persona": true,
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Emergency Medical Services",
    "interview": false,
    "targetRole": "Paramedic",
    "currentCompany": "American Medical Response",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Emergency Medical Services",
    "isEntryLevel": false,
    "yoe": 2,
    "aboutMe": "",
    "displayName": "brown peafowl",
    "userId": "b499a77b364349e6991d",
    "id": "b499a77b364349e6991d",
    "currentPosition": "EMT",
    "interviewTypes": [
      "General Interview"
    ],
    "availability": [
      {
        "day": "Mon",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 01:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Tue",
        "hour": [
          {
            "HourFormated": " 09:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 03:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Wed",
        "hour": [
          {
            "HourFormated": " 10:00 AM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Thu",
        "hour": [
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          },
          {
            "HourFormated": " 04:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Fri",
        "hour": [
          {
            "HourFormated": " 12:00 PM",
            "clicked": true
          }
        ]
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": [
          {
            "HourFormated": " 11:00 AM",
            "clicked": true
          },
          {
            "HourFormated": " 02:00 PM",
            "clicked": true
          }
        ]
      }
    ],
    "university": "",
    "experience": "",
    "numerOfInterviews": "",
    "targetCompany": "",
    "costumInterviews": "",
    "bootCamp": "",
    "onBoarding": ""
  },
  {
    "persona": true,
    "activeDuration": 2714743375622,
    "school": "University of California, Berkeley",
    "currentCompany": "Google",
    "camera": true,
    "codingDifficulty": 4,
    "reviews": [],
    "id": "8f549be72d7a4d039676",
    "interviews": 0,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "yoe": 1,
    "areaOfStudy": "Computer Science",
    "platform": "peerfect2023",
    "interview": true,
    "skills": [
        "Java",
        "Python",
        "SQL",
        "HTML",
        "CSS"
    ],
    "uid": "8f549be72d7a4d039676",
    "targetRole": "Software Developer",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "previousCodingProblems": [],
    "displayName": "moccasin badger",
    "currentJob": "Junior Software Engineer",
    "subCategory": "Software Development",
    "profile": true,
    "interviewTypes": [
        "Behavioral Interview",
        "Technical Interview",
        "Coding Interview",
        "System Design"
    ],
    "timeZone": "America/New_York",
    "educationLevel": "Bachelor degree",
    "isEntryLevel": false,
    "userId": "8f549be72d7a4d039676",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "codingLevel": "",
    "university": "University of California, Berkeley",
    "currentPosition": "Junior Software Engineer",
    "bootCampGraduate": false,
    "active": true
},
{
    "previousCodingProblems": [],
    "userId": "3fb8d1d9c01240f4869c",
    "id": "3fb8d1d9c01240f4869c",
    "persona": true,
    "interviewTypes": [
        "Behavioral Interview",
        "Technical Interview",
        "Coding Interview",
        "System Design"
    ],
    "codingDifficulty": 4,
    "currentCompany": "Google",
    "timeZone": "America/New_York",
    "codingLevel": "",
    "uid": "3fb8d1d9c01240f4869c",
    "category": "Communications, Media, and Information Technology",
    "areaOfStudy": "Computer Science",
    "platform": "peerfect2023",
    "isEntryLevel": false,
    "camera": true,
    "subCategory": "Software Development",
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "displayName": "amethyst bandicoot",
    "interviews": 0,
    "active": true,
    "yoe": 3,
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "currentPosition": "Web Developer",
    "targetRole": "Front-end Developer",
    "profile": true,
    "activeDuration": 2714743375622,
    "school": "Stanford University",
    "university": "Stanford University",
    "bootCampGraduate": false,
    "reviews": [],
    "skills": [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Angular"
    ],
    "interview": true,
    "educationLevel": "Bachelor degree",
    "currentJob": "Web Developer"
},
{
    "timeZone": "America/New_York",
    "interviewTypes": [
        "General Interview"
    ],
    "currentCompany": "Wells Fargo",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "educationLevel": "Bachelor degree",
    "codingDifficulty": 4,
    "previousCodingProblems": [],
    "profile": true,
    "isEntryLevel": false,
    "subCategory": "Accounting",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "displayName": "aqua cod",
    "userId": "360c01fe578444539e7f",
    "id": "360c01fe578444539e7f",
    "currentJob": "Financial Analyst",
    "interview": false,
    "platform": "peerfect2023",
    "category": "Finance, Insurance, and Real Estate",
    "activeDuration": 2714743375622,
    "school": "University of California, Berkeley",
    "reviews": [],
    "interviews": 0,
    "uid": "360c01fe578444539e7f",
    "yoe": 3,
    "bootCampGraduate": false,
    "codingLevel": "",
    "currentPosition": "Financial Analyst",
    "skills": [],
    "areaOfStudy": "Finance",
    "university": "University of California, Berkeley",
    "persona": true,
    "targetRole": "Accountant",
    "active": true,
    "camera": true
},
{
    "codingLevel": "",
    "timeZone": "America/New_York",
    "subCategory": "Software Development",
    "id": "b426f76aecc743438230",
    "isEntryLevel": true,
    "skills": [
        "Java",
        "Python",
        "SQL",
        "HTML",
        "CSS"
    ],
    "interviews": 0,
    "areaOfStudy": "Computer Science",
    "educationLevel": "Bachelor degree",
    "university": "Stanford University",
    "currentJob": "Junior Software Developer",
    "displayName": "yellow cougar",
    "previousCodingProblems": [],
    "uid": "b426f76aecc743438230",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "persona": true,
    "currentCompany": "Google",
    "interview": true,
    "profile": true,
    "userId": "b426f76aecc743438230",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "active": true,
    "targetRole": "Software Engineer",
    "school": "Stanford University",
    "reviews": [],
    "platform": "peerfect2023",
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "yoe": 2,
    "bootCampGraduate": false,
    "activeDuration": 2714743375622,
    "codingDifficulty": 4,
    "interviewTypes": [
        "Behavioral Interview",
        "Technical Interview",
        "Coding Interview",
        "System Design"
    ],
    "camera": true,
    "currentPosition": "Junior Software Developer"
},
{
    "interviewTypes": [
        "General Interview"
    ],
    "active": true,
    "uid": "29f4da359f8240f88a76",
    "interview": true,
    "currentJob": "Medical Assistant",
    "isEntryLevel": false,
    "activeDuration": 2714743375622,
    "profile": true,
    "codingLevel": "",
    "educationLevel": "Bachelor degree",
    "university": "University of California, Los Angeles",
    "areaOfStudy": "Biology",
    "skills": [
        "Patient care",
        "Medical knowledge",
        "Clinical skills"
    ],
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "yoe": 2,
    "category": "Healthcare and Medical Services",
    "bootCampGraduate": false,
    "previousCodingProblems": [],
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "school": "University of California, Los Angeles",
    "persona": true,
    "platform": "peerfect2023",
    "id": "29f4da359f8240f88a76",
    "currentPosition": "Medical Assistant",
    "currentCompany": "Mayo Clinic",
    "codingDifficulty": 4,
    "subCategory": "Physician Services",
    "timeZone": "America/New_York",
    "targetRole": "Doctor",
    "reviews": [],
    "camera": true,
    "userId": "29f4da359f8240f88a76",
    "interviews": 0,
    "displayName": "brown caribou"
},
{
    "previousCodingProblems": [],
    "school": "University of California, Los Angeles",
    "interviews": 0,
    "reviews": [],
    "timeZone": "America/New_York",
    "interview": false,
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "HourFormated": " 12:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "active": true,
    "category": "Installation, Repair, and Maintenance",
    "activeDuration": 2714743375622,
    "id": "3f4a737924ca49fc838f",
    "isEntryLevel": false,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "userId": "3f4a737924ca49fc838f",
    "persona": true,
    "codingDifficulty": 4,
    "currentCompany": "S&P Global",
    "interviewTypes": [
        "General Interview"
    ],
    "displayName": "jade heron",
    "codingLevel": "",
    "university": "University of California, Los Angeles",
    "subCategory": "Automotive Repair",
    "bootCampGraduate": false,
    "currentJob": "Finance Analyst",
    "currentPosition": "Finance Analyst",
    "areaOfStudy": "Accounting",
    "uid": "3f4a737924ca49fc838f",
    "targetRole": "Accountant",
    "yoe": 3,
    "skills": [],
    "profile": true,
    "educationLevel": "Bachelor degree",
    "camera": true,
    "platform": "peerfect2023"
},
{
    "currentJob": "Data Analyst",
    "activeDuration": 2714743375622,
    "active": true,
    "school": "Stanford University",
    "currentCompany": "Google",
    "id": "c426ace92d464969a5fe",
    "displayName": "azure mite",
    "university": "Stanford University",
    "interviewTypes": [
        "General Interview"
    ],
    "codingLevel": "",
    "educationLevel": "Master degree",
    "uid": "c426ace92d464969a5fe",
    "timeZone": "America/New_York",
    "yoe": 3,
    "previousCodingProblems": [],
    "camera": true,
    "bootCampGraduate": false,
    "subCategory": "Technical Writing",
    "category": "Professional, Scientific, and Technical Services",
    "codingDifficulty": 4,
    "targetRole": "Data Scientist",
    "currentPosition": "Data Analyst",
    "reviews": [],
    "areaOfStudy": "Computer Science",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 08:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 07:00 PM"
                }
            ]
        }
    ],
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "userId": "c426ace92d464969a5fe",
    "interviews": 0,
    "profile": true,
    "persona": true,
    "skills": [],
    "isEntryLevel": false,
    "platform": "peerfect2023",
    "interview": false
},
{
    "reviews": [],
    "id": "e95adc5de03946f1bffa",
    "persona": true,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "interview": true,
    "codingDifficulty": 4,
    "isEntryLevel": false,
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "educationLevel": "Bachelor degree",
    "profile": true,
    "yoe": 4,
    "uid": "e95adc5de03946f1bffa",
    "school": "Stanford University",
    "displayName": "harlequin urial",
    "currentCompany": "Google",
    "currentPosition": "Software Engineer",
    "skills": [
        "Java",
        "Python",
        "JavaScript",
        "SQL"
    ],
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "active": true,
    "subCategory": "Software Development",
    "interviews": 0,
    "userId": "e95adc5de03946f1bffa",
    "bootCampGraduate": false,
    "platform": "peerfect2023",
    "camera": true,
    "interviewTypes": [
        "Behavioral Interview",
        "Technical Interview",
        "Coding Interview",
        "System Design"
    ],
    "targetRole": "Software Developer",
    "areaOfStudy": "Computer Science",
    "codingLevel": "",
    "currentJob": "Software Engineer",
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        }
    ],
    "activeDuration": 2714743375622,
    "university": "Stanford University"
},
{
    "platform": "peerfect2023",
    "codingLevel": "",
    "targetRole": "Medical Interviewer",
    "category": "Healthcare and Medical Services",
    "timeZone": "America/New_York",
    "interviews": 0,
    "id": "1d0a822cdb6147528fb1",
    "active": true,
    "currentPosition": "Medical Assistant",
    "school": "University of California, Los Angeles",
    "educationLevel": "Bachelor degree",
    "persona": true,
    "interview": true,
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "areaOfStudy": "Nursing",
    "uid": "1d0a822cdb6147528fb1",
    "userId": "1d0a822cdb6147528fb1",
    "university": "University of California, Los Angeles",
    "skills": [
        "Patient communication",
        "Medical terminology knowledge",
        "Time management"
    ],
    "activeDuration": 2714743375622,
    "profile": true,
    "reviews": [],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:30 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:30 PM"
                }
            ]
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "displayName": "peach swallow",
    "subCategory": "Physician Services",
    "currentJob": "Medical Assistant",
    "camera": true,
    "currentCompany": "Kaiser Permanente",
    "interviewTypes": [
        "General Interview"
    ],
    "bootCampGraduate": false,
    "previousCodingProblems": [],
    "isEntryLevel": false,
    "yoe": 3,
    "codingDifficulty": 4
},
{
    "currentPosition": "Data Analyst",
    "reviews": [],
    "targetRole": "Database Administrator",
    "interviews": 0,
    "userId": "d66c353f53b14fe893be",
    "profile": true,
    "id": "d66c353f53b14fe893be",
    "subCategory": "Database Management",
    "isEntryLevel": false,
    "displayName": "amaranth falcon",
    "previousCodingProblems": [],
    "areaOfStudy": "Computer Science",
    "interview": true,
    "persona": true,
    "bootCampGraduate": false,
    "educationLevel": "Bachelor degree",
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "codingLevel": "",
    "currentCompany": "Google",
    "uid": "d66c353f53b14fe893be",
    "platform": "peerfect2023",
    "activeDuration": 2714743375622,
    "skills": [
        "SQL",
        "Database management",
        "Data modeling"
    ],
    "timeZone": "America/New_York",
    "currentJob": "Data Analyst",
    "codingDifficulty": 4,
    "university": "Stanford University",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "active": true,
    "camera": true,
    "yoe": 3,
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        }
    ],
    "school": "Stanford University"
},
{
    "skills": [
        "SQL",
        "PL/SQL",
        "Database Performance Tuning",
        "Backup and Recovery",
        "Database Security"
    ],
    "university": "University of California, Berkeley",
    "camera": true,
    "interviewTypes": [
        "General Interview"
    ],
    "currentPosition": "Database Developer",
    "targetRole": "Oracle Database Administrator",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "currentJob": "Database Developer",
    "subCategory": "Database Management",
    "codingDifficulty": 4,
    "timeZone": "America/New_York",
    "interview": true,
    "userId": "d45d703adfbd48479a50",
    "reviews": [],
    "id": "d45d703adfbd48479a50",
    "displayName": "rose hookworm",
    "currentCompany": "Oracle Corporation",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        }
    ],
    "yoe": 3,
    "isEntryLevel": false,
    "codingLevel": "",
    "persona": true,
    "activeDuration": 2714743375622,
    "educationLevel": "Bachelor degree",
    "category": "Communications, Media, and Information Technology",
    "bootCampGraduate": false,
    "active": true,
    "platform": "peerfect2023",
    "profile": true,
    "previousCodingProblems": [],
    "interviews": 0,
    "uid": "d45d703adfbd48479a50",
    "school": "University of California, Berkeley",
    "areaOfStudy": "Information Technology"
},
{
    "isEntryLevel": false,
    "skills": [
        "Java",
        "OOPS",
        "Data Structures",
        "Python"
    ],
    "profile": true,
    "userId": "93f95a6d5b694650adf6",
    "university": "Stanford University",
    "id": "93f95a6d5b694650adf6",
    "camera": true,
    "displayName": "gray opossum",
    "activeDuration": 2714743375622,
    "bootCampGraduate": false,
    "persona": true,
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "targetRole": "Java, OOPS, data structures, project, basic python",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "timeZone": "America/New_York",
    "currentPosition": "Software Engineer",
    "areaOfStudy": "Computer Science",
    "interviewTypes": [
        "General Interview"
    ],
    "subCategory": "Software Development",
    "platform": "peerfect2023",
    "reviews": [],
    "currentJob": "Software Engineer",
    "interviews": 0,
    "school": "Stanford University",
    "interview": true,
    "uid": "93f95a6d5b694650adf6",
    "previousCodingProblems": [],
    "currentCompany": "Google",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "educationLevel": "Bachelor degree",
    "active": true,
    "codingDifficulty": 4,
    "codingLevel": "",
    "yoe": 3
},
{
    "codingLevel": "",
    "error": "Cannot read properties of undefined (reading 'toLowerCase')",
    "real": "real"
},
{
    "profile": true,
    "id": "7009ba2bcb9e440497c8",
    "educationLevel": "Master degree",
    "uid": "7009ba2bcb9e440497c8",
    "isEntryLevel": false,
    "university": "Stanford University",
    "interviewTypes": [
        "General Interview"
    ],
    "active": true,
    "areaOfStudy": "Computer Science",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "currentPosition": "Data Analyst",
    "bootCampGraduate": false,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:30 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:30 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "yoe": 3,
    "camera": true,
    "activeDuration": 2714743375622,
    "userId": "7009ba2bcb9e440497c8",
    "currentCompany": "Google",
    "codingLevel": "",
    "timeZone": "America/New_York",
    "currentJob": "Data Analyst",
    "interviews": 0,
    "interview": true,
    "subCategory": "Artificial Intelligence",
    "skills": [
        "Machine Learning",
        "Deep Learning",
        "Python",
        "TensorFlow"
    ],
    "school": "Stanford University",
    "targetRole": "Generative AI",
    "persona": true,
    "displayName": "orange hummingbird",
    "platform": "peerfect2023",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "previousCodingProblems": [],
    "reviews": [],
    "codingDifficulty": 4
},
{
    "userId": "854c0aa8e7174dd4b7d7",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        }
    ],
    "currentCompany": "Amazon",
    "educationLevel": "Bachelor degree",
    "currentJob": "HR Coordinator",
    "school": "University of Michigan",
    "interviews": 0,
    "university": "University of Michigan",
    "bootCampGraduate": false,
    "interviewTypes": [
        "General Interview"
    ],
    "uid": "854c0aa8e7174dd4b7d7",
    "persona": true,
    "displayName": "brown primate",
    "camera": true,
    "areaOfStudy": "Human Resources Management",
    "timeZone": "America/New_York",
    "profile": true,
    "platform": "peerfect2023",
    "subCategory": "Human Resources",
    "yoe": 3,
    "codingDifficulty": 4,
    "category": "Human Resources and Talent Management",
    "id": "854c0aa8e7174dd4b7d7",
    "skills": [],
    "previousCodingProblems": [],
    "currentPosition": "HR Coordinator",
    "reviews": [],
    "interview": false,
    "codingLevel": "",
    "targetRole": "Human Resources",
    "active": true,
    "isEntryLevel": false,
    "activeDuration": 2714743375622
},
{
    "profile": true,
    "interviewTypes": [
        "General Interview"
    ],
    "currentCompany": "JP Morgan Chase",
    "targetRole": "Finance",
    "persona": true,
    "camera": true,
    "id": "f6c1d651e2bd41bdaa77",
    "subCategory": "Finance",
    "active": true,
    "userId": "f6c1d651e2bd41bdaa77",
    "previousCodingProblems": [],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ],
            "day": "Sun"
        }
    ],
    "school": "University of Michigan",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Finance",
    "yoe": 4,
    "codingDifficulty": 4,
    "timeZone": "America/New_York",
    "category": "Finance, Insurance, and Real Estate",
    "uid": "f6c1d651e2bd41bdaa77",
    "currentJob": "Financial Analyst",
    "activeDuration": 2714743375622,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "reviews": [],
    "codingLevel": "",
    "currentPosition": "Financial Analyst",
    "displayName": "gold turkey",
    "interview": false,
    "skills": [],
    "university": "University of Michigan",
    "bootCampGraduate": false,
    "platform": "peerfect2023",
    "interviews": 0,
    "isEntryLevel": false
},
{
    "targetRole": "Agricultural Grader",
    "bootCampGraduate": false,
    "subCategory": "Quality Control",
    "userId": "c8a61a5270cb41c1b4ce",
    "school": "University of California, Davis",
    "university": "University of California, Davis",
    "codingLevel": "",
    "persona": true,
    "displayName": "teal egret",
    "previousCodingProblems": [],
    "profile": true,
    "category": "Manufacturing and Production",
    "isEntryLevel": false,
    "uid": "c8a61a5270cb41c1b4ce",
    "areaOfStudy": "Agricultural Science",
    "currentCompany": "Smithfield Foods",
    "codingDifficulty": 4,
    "active": true,
    "activeDuration": 2714743375622,
    "currentPosition": "Agricultural Worker",
    "reviews": [],
    "interviewTypes": [
        "General Interview"
    ],
    "interview": false,
    "currentJob": "Agricultural Worker",
    "platform": "peerfect2023",
    "timeZone": "America/New_York",
    "camera": true,
    "educationLevel": "Bachelor degree",
    "interviews": 0,
    "skills": [],
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 07:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                }
            ]
        }
    ],
    "yoe": 2,
    "id": "c8a61a5270cb41c1b4ce"
},
{
    "interview": false,
    "school": "ABC College",
    "currentPosition": "Student",
    "yoe": 0,
    "bootCampGraduate": false,
    "timeZone": "America/New_York",
    "platform": "peerfect2023",
    "isEntryLevel": true,
    "active": true,
    "persona": true,
    "currentCompany": "XYZ University",
    "camera": true,
    "areaOfStudy": "Computer Science",
    "id": "9eea301f41d1499db32f",
    "userId": "9eea301f41d1499db32f",
    "subCategory": "Vocational Training",
    "university": "ABC College",
    "educationLevel": "Bachelor degree",
    "uid": "9eea301f41d1499db32f",
    "displayName": "maroon silverfish",
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        }
    ],
    "currentJob": "Student",
    "skills": [],
    "category": "Education and Training",
    "targetRole": "Student",
    "profile": true,
    "codingDifficulty": 4,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "codingLevel": "",
    "interviews": 0,
    "reviews": [],
    "interviewTypes": [
        "General Interview"
    ],
    "previousCodingProblems": [],
    "activeDuration": 2714743375622
},
{
    "error": "Cannot read properties of undefined (reading 'map')",
    "codingLevel": "",
    "real": "real"
},
{
    "category": "Communications, Media, and Information Technology",
    "currentCompany": "Google",
    "userId": "18e18333ec6c4986a71d",
    "bootCampGraduate": false,
    "timeZone": "America/New_York",
    "activeDuration": 2714743375622,
    "persona": true,
    "platform": "peerfect2023",
    "interviews": 0,
    "university": "Stanford University",
    "previousCodingProblems": [],
    "isEntryLevel": false,
    "displayName": "cyan egret",
    "interviewTypes": [
        "General Interview"
    ],
    "areaOfStudy": "Computer Science",
    "codingLevel": "",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "codingDifficulty": 4,
    "school": "Stanford University",
    "uid": "18e18333ec6c4986a71d",
    "reviews": [],
    "currentPosition": "Front-End Developer",
    "yoe": 3,
    "active": true,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "targetRole": "Web Developer",
    "id": "18e18333ec6c4986a71d",
    "camera": true,
    "skills": [
        "HTML",
        "CSS",
        "JavaScript",
        "React"
    ],
    "interview": true,
    "currentJob": "Front-End Developer",
    "educationLevel": "Bachelor degree",
    "subCategory": "Software Development",
    "profile": true
},
{
    "uid": "d6bd149da1564f7082b3",
    "isEntryLevel": false,
    "codingDifficulty": 4,
    "persona": true,
    "displayName": "amber meerkat",
    "interview": true,
    "codingLevel": "",
    "subCategory": "Information Security",
    "school": "Stanford University",
    "interviewTypes": [
        "General Interview"
    ],
    "currentJob": "IT Specialist",
    "profile": true,
    "bootCampGraduate": false,
    "yoe": 3,
    "interviews": 0,
    "previousCodingProblems": [],
    "reviews": [],
    "areaOfStudy": "Computer Science",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "id": "d6bd149da1564f7082b3",
    "platform": "peerfect2023",
    "activeDuration": 2714743375622,
    "currentCompany": "Google",
    "targetRole": "Cyber Security Analyst",
    "educationLevel": "Bachelor degree",
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "university": "Stanford University",
    "timeZone": "America/New_York",
    "userId": "d6bd149da1564f7082b3",
    "skills": [
        "Network Security",
        "Penetration Testing",
        "Incident Response",
        "Firewall Management"
    ],
    "currentPosition": "IT Specialist",
    "category": "Communications, Media, and Information Technology",
    "active": true,
    "camera": true
},
{
    "currentJob": "Data Analyst",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "activeDuration": 2714743375622,
    "id": "d7fba81bdbc2462694a6",
    "timeZone": "America/New_York",
    "previousCodingProblems": [],
    "skills": [
        "Machine Learning",
        "Python programming",
        "Deep Learning",
        "Natural Language Processing"
    ],
    "codingDifficulty": 4,
    "educationLevel": "Master degree",
    "yoe": 3,
    "interviews": 0,
    "camera": true,
    "reviews": [],
    "active": true,
    "university": "Stanford University",
    "isEntryLevel": false,
    "displayName": "gray silverfish",
    "uid": "d7fba81bdbc2462694a6",
    "currentCompany": "LinkedIn",
    "profile": true,
    "persona": true,
    "areaOfStudy": "Computer Science",
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "platform": "peerfect2023",
    "currentPosition": "Data Analyst",
    "codingLevel": "",
    "bootCampGraduate": false,
    "userId": "d7fba81bdbc2462694a6",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Artificial Intelligence",
    "targetRole": "Artificial Intelligence Engineer",
    "interview": true,
    "school": "Stanford University",
    "interviewTypes": [
        "General Interview"
    ]
},
{
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "id": "e78a5e09007444c29832",
    "active": true,
    "codingDifficulty": 4,
    "subCategory": "Data Science",
    "codingLevel": "",
    "reviews": [],
    "educationLevel": "Bachelor degree",
    "uid": "e78a5e09007444c29832",
    "targetRole": "Data Engineer",
    "bootCampGraduate": false,
    "platform": "peerfect2023",
    "isEntryLevel": false,
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:30 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "profile": true,
    "currentPosition": "Data Analyst",
    "currentCompany": "Google",
    "timeZone": "America/New_York",
    "yoe": 3,
    "areaOfStudy": "Computer Science",
    "displayName": "aqua firefly",
    "userId": "e78a5e09007444c29832",
    "school": "Stanford University",
    "activeDuration": 2714743375622,
    "persona": true,
    "camera": true,
    "interview": true,
    "currentJob": "Data Analyst",
    "university": "Stanford University",
    "skills": [
        "SQL",
        "Python",
        "Data modeling",
        "ETL processes"
    ],
    "interviews": 0,
    "previousCodingProblems": [],
    "interviewTypes": [
        "General Interview"
    ]
},
{
    "activeDuration": 2714743375622,
    "previousCodingProblems": [],
    "bootCampGraduate": false,
    "subCategory": "Customer Support",
    "reviews": [],
    "codingDifficulty": 4,
    "platform": "peerfect2023",
    "codingLevel": "",
    "yoe": 2,
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "isEntryLevel": true,
    "targetRole": "Customer Service Representative",
    "timeZone": "America/New_York",
    "id": "ea622b3195aa40139b36",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "interview": false,
    "currentJob": "Call Center Agent",
    "interviews": 0,
    "skills": [],
    "persona": true,
    "active": true,
    "currentPosition": "Call Center Agent",
    "category": "Marketing, Sales, and Customer Service",
    "school": "Community College of Philadelphia",
    "displayName": "amaranth crawdad",
    "university": "Community College of Philadelphia",
    "uid": "ea622b3195aa40139b36",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Business Administration",
    "interviewTypes": [
        "General Interview"
    ],
    "userId": "ea622b3195aa40139b36",
    "currentCompany": "Verizon Wireless",
    "camera": true,
    "profile": true
},
{
    "activeDuration": 2714743375622,
    "interviews": 0,
    "currentCompany": "Microsoft",
    "uid": "f0a518fed548447fbfac",
    "interviewTypes": [
        "General Interview"
    ],
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "yoe": 3,
    "targetRole": "Data Scientist",
    "persona": true,
    "educationLevel": "Master degree",
    "subCategory": "Data Science",
    "isEntryLevel": false,
    "school": "University of California, Berkeley",
    "codingDifficulty": 4,
    "userId": "f0a518fed548447fbfac",
    "camera": true,
    "reviews": [],
    "currentJob": "Data Analyst",
    "profile": true,
    "id": "f0a518fed548447fbfac",
    "bootCampGraduate": false,
    "currentPosition": "Data Analyst",
    "university": "University of California, Berkeley",
    "platform": "peerfect2023",
    "areaOfStudy": "Data Science",
    "interview": true,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "codingLevel": "",
    "displayName": "coffee marmoset",
    "previousCodingProblems": [],
    "active": true,
    "skills": [
        "Python",
        "R",
        "Machine Learning",
        "Statistical Analysis"
    ],
    "timeZone": "America/New_York",
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ]
},
{
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "areaOfStudy": "Respiratory Therapy",
    "bootCampGraduate": false,
    "educationLevel": "Associate degree",
    "persona": true,
    "id": "ccd01a4d7f024cf79fff",
    "skills": [],
    "yoe": 2,
    "active": true,
    "interviewTypes": [
        "General Interview"
    ],
    "interview": false,
    "isEntryLevel": false,
    "school": "Community College of Philadelphia",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "currentJob": "Respiratory Therapy Technician",
    "timeZone": "America/New_York",
    "subCategory": "Medical Administration",
    "uid": "ccd01a4d7f024cf79fff",
    "university": "Community College of Philadelphia",
    "currentPosition": "Respiratory Therapy Technician",
    "previousCodingProblems": [],
    "codingLevel": "",
    "platform": "peerfect2023",
    "camera": true,
    "userId": "ccd01a4d7f024cf79fff",
    "codingDifficulty": 4,
    "targetRole": "Respiratory Therapist",
    "reviews": [],
    "profile": true,
    "interviews": 0,
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "currentCompany": "Boston Medical Center",
    "displayName": "coral blackbird"
},
{
    "platform": "peerfect2023",
    "targetRole": "Java and SQL Developer",
    "school": "Stanford University",
    "bootCampGraduate": false,
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "id": "08dda9f3573042d7b68d",
    "interviewTypes": [
        "General Interview"
    ],
    "displayName": "pink mink",
    "areaOfStudy": "Computer Science",
    "interview": true,
    "codingLevel": "",
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "currentPosition": "Software Engineer",
    "persona": true,
    "codingDifficulty": 4,
    "activeDuration": 2714743375622,
    "university": "Stanford University",
    "uid": "08dda9f3573042d7b68d",
    "interviews": 0,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Information Technology",
    "camera": true,
    "currentJob": "Software Engineer",
    "skills": [
        "Java programming",
        "SQL queries",
        "Database management"
    ],
    "profile": true,
    "reviews": [],
    "educationLevel": "Bachelor degree",
    "isEntryLevel": false,
    "active": true,
    "yoe": 3,
    "userId": "08dda9f3573042d7b68d",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "currentCompany": "Google"
},
{
    "camera": true,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "educationLevel": "Associate degree",
    "currentPosition": "Medical Assistant",
    "userId": "9c8bf13582c54a9d9ceb",
    "persona": true,
    "reviews": [],
    "targetRole": "Sterile Processing Technician",
    "category": "Healthcare and Medical Services",
    "isEntryLevel": false,
    "platform": "peerfect2023",
    "id": "9c8bf13582c54a9d9ceb",
    "displayName": "green magpie",
    "interview": false,
    "university": "ABC Technical Institute",
    "subCategory": "Occupational Therapy",
    "interviewTypes": [
        "General Interview"
    ],
    "uid": "9c8bf13582c54a9d9ceb",
    "currentCompany": "Mayo Clinic",
    "school": "ABC Technical Institute",
    "bootCampGraduate": false,
    "yoe": 2,
    "activeDuration": 2714743375622,
    "currentJob": "Medical Assistant",
    "codingLevel": "",
    "areaOfStudy": "Medical Assisting",
    "timeZone": "America/New_York",
    "interviews": 0,
    "previousCodingProblems": [],
    "codingDifficulty": 4,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "profile": true,
    "active": true,
    "skills": []
},
{
    "bootCampGraduate": false,
    "codingDifficulty": 4,
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "persona": true,
    "currentCompany": "General Electric",
    "currentPosition": "Safety Coordinator",
    "areaOfStudy": "Occupational Health and Safety",
    "category": "Environmental, Health, and Safety",
    "yoe": 3,
    "interviewTypes": [
        "General Interview"
    ],
    "camera": true,
    "codingLevel": "",
    "subCategory": "Health and Safety Management",
    "profile": true,
    "interviews": 0,
    "userId": "fd5b7debfda546248d09",
    "displayName": "salmon ladybug",
    "active": true,
    "isEntryLevel": false,
    "id": "fd5b7debfda546248d09",
    "reviews": [],
    "currentJob": "Safety Coordinator",
    "interview": true,
    "skills": [
        "Risk assessment",
        "OSHA regulations",
        "Emergency response planning"
    ],
    "activeDuration": 2714743375622,
    "university": "University of California, Los Angeles",
    "platform": "peerfect2023",
    "educationLevel": "Bachelor degree",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "uid": "fd5b7debfda546248d09",
    "school": "University of California, Los Angeles",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "targetRole": "HSE supervisor"
},
{
    "displayName": "indigo frog",
    "educationLevel": "Master degree",
    "platform": "peerfect2023",
    "persona": true,
    "isEntryLevel": false,
    "bootCampGraduate": false,
    "subCategory": "Artificial Intelligence and Machine Learning",
    "profile": true,
    "id": "687619b1118942ceacff",
    "school": "Stanford University",
    "codingLevel": "",
    "interviews": 0,
    "activeDuration": 2714743375622,
    "userId": "687619b1118942ceacff",
    "interviewTypes": [
        "General Interview"
    ],
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "previousCodingProblems": [],
    "codingDifficulty": 4,
    "timeZone": "America/New_York",
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "targetRole": "Machine Learning and AI Engineer",
    "skills": [
        "Python",
        "Machine Learning Algorithms",
        "Natural Language Processing",
        "Deep Learning"
    ],
    "currentCompany": "Microsoft",
    "active": true,
    "currentPosition": "Data Analyst",
    "university": "Stanford University",
    "areaOfStudy": "Computer Science",
    "camera": true,
    "yoe": 3,
    "interview": true,
    "reviews": [],
    "uid": "687619b1118942ceacff",
    "currentJob": "Data Analyst"
},
{
    "camera": true,
    "codingDifficulty": 4,
    "persona": true,
    "areaOfStudy": "Business Administration",
    "platform": "peerfect2023",
    "isEntryLevel": false,
    "category": "Business, Management, and Administration",
    "interviewTypes": [
        "General Interview"
    ],
    "school": "University of Illinois",
    "yoe": 2,
    "interviews": 0,
    "reviews": [],
    "university": "University of Illinois",
    "interview": false,
    "active": true,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "profile": true,
    "timeZone": "America/New_York",
    "codingLevel": "",
    "id": "56cd11e2e8e14fa8bd80",
    "previousCodingProblems": [],
    "targetRole": "Administrative Assistant",
    "skills": [],
    "currentPosition": "Office Assistant",
    "displayName": "silver chimpanzee",
    "currentJob": "Office Assistant",
    "subCategory": "Operations Management",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "currentCompany": "ABC Corporation",
    "educationLevel": "Bachelor degree",
    "bootCampGraduate": false,
    "activeDuration": 2714743375622,
    "userId": "56cd11e2e8e14fa8bd80",
    "uid": "56cd11e2e8e14fa8bd80"
},
{
    "currentCompany": "Google",
    "platform": "peerfect2023",
    "educationLevel": "Bachelor degree",
    "school": "University of California, Berkeley",
    "currentPosition": "Data Analyst",
    "codingDifficulty": 4,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "areaOfStudy": "Computer Science",
    "currentJob": "Data Analyst",
    "reviews": [],
    "activeDuration": 2714743375622,
    "skills": [
        "Tableau",
        "SQL",
        "Data Visualization",
        "ETL"
    ],
    "subCategory": "Data Science",
    "camera": true,
    "university": "University of California, Berkeley",
    "displayName": "moccasin crawdad",
    "timeZone": "America/New_York",
    "id": "5986aff509d64dd2ab7c",
    "previousCodingProblems": [],
    "yoe": 3,
    "isEntryLevel": false,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "codingLevel": "",
    "interview": true,
    "targetRole": "Tableau Developer",
    "uid": "5986aff509d64dd2ab7c",
    "bootCampGraduate": false,
    "active": true,
    "persona": true,
    "interviews": 0,
    "profile": true,
    "interviewTypes": [
        "General Interview"
    ],
    "userId": "5986aff509d64dd2ab7c"
},
{
    "targetRole": "College Interviewer",
    "areaOfStudy": "Education",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "userId": "58014d52b3fe422e8639",
    "codingDifficulty": 4,
    "id": "58014d52b3fe422e8639",
    "reviews": [],
    "codingLevel": "",
    "school": "University of ABC",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "category": "Education and Training",
    "university": "University of ABC",
    "displayName": "white hookworm",
    "timeZone": "America/New_York",
    "platform": "peerfect2023",
    "bootCampGraduate": false,
    "subCategory": "Higher Education",
    "profile": true,
    "camera": true,
    "yoe": 5,
    "interview": true,
    "educationLevel": "Master degree",
    "currentJob": "High School Guidance Counselor",
    "persona": true,
    "activeDuration": 2714743375622,
    "uid": "58014d52b3fe422e8639",
    "skills": [
        "Communication skills",
        "Problem-solving skills",
        "Empathy"
    ],
    "interviews": 0,
    "currentPosition": "High School Guidance Counselor",
    "isEntryLevel": false,
    "previousCodingProblems": [],
    "currentCompany": "XYZ High School",
    "active": true,
    "interviewTypes": [
        "General Interview"
    ]
},
{
    "educationLevel": "Bachelor degree",
    "activeDuration": 2714743375622,
    "id": "584fe3c3f2f8443b84c8",
    "skills": [
        "Network design",
        "RF engineering",
        "Telecommunication protocols"
    ],
    "school": "University of California, Los Angeles",
    "userId": "584fe3c3f2f8443b84c8",
    "areaOfStudy": "Electrical Engineering",
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "uid": "584fe3c3f2f8443b84c8",
    "reviews": [],
    "displayName": "olive carp",
    "interviews": 0,
    "targetRole": "Telecommunications Engineering",
    "interviewTypes": [
        "General Interview"
    ],
    "codingLevel": "",
    "isEntryLevel": false,
    "currentJob": "Telecommunications Technician",
    "timeZone": "America/New_York",
    "subCategory": "Telecommunications Engineering",
    "persona": true,
    "profile": true,
    "currentPosition": "Telecommunications Technician",
    "interview": true,
    "currentCompany": "Verizon",
    "university": "University of California, Los Angeles",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "platform": "peerfect2023",
    "yoe": 3,
    "camera": true,
    "active": true,
    "codingDifficulty": 4,
    "previousCodingProblems": [],
    "bootCampGraduate": false
},
{
    "codingLevel": "",
    "error": "Unexpected token a in JSON at position 4",
    "real": "real"
},
{
    "reviews": [],
    "bootCampGraduate": false,
    "areaOfStudy": "Finance",
    "persona": true,
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "interview": true,
    "educationLevel": "Master degree",
    "userId": "c3356671fe8c43669a87",
    "profile": true,
    "codingLevel": "",
    "yoe": 3,
    "uid": "c3356671fe8c43669a87",
    "currentPosition": "Financial Analyst",
    "currentCompany": "Wells Fargo",
    "platform": "peerfect2023",
    "displayName": "lime anglerfish",
    "active": true,
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "currentJob": "Financial Analyst",
    "category": "Business, Management, and Administration",
    "interviewTypes": [
        "General Interview"
    ],
    "codingDifficulty": 4,
    "university": "Harvard Business School",
    "school": "Harvard Business School",
    "camera": true,
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "isEntryLevel": false,
    "id": "c3356671fe8c43669a87",
    "skills": [
        "Financial modeling",
        "Data analysis",
        "Risk management"
    ],
    "subCategory": "Strategic Planning",
    "interviews": 0,
    "activeDuration": 2714743375622,
    "targetRole": "MBA"
},
{
    "uid": "d93b72e71e8e4772ac3f",
    "interview": true,
    "interviews": 0,
    "isEntryLevel": false,
    "active": true,
    "id": "d93b72e71e8e4772ac3f",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "displayName": "cyan python",
    "platform": "peerfect2023",
    "codingDifficulty": 4,
    "subCategory": "Robotics",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Mechatronics",
    "currentJob": "Automation Technician",
    "currentPosition": "Automation Technician",
    "currentCompany": "Rockwell Automation",
    "school": "Community College of Allegheny County",
    "university": "Community College of Allegheny County",
    "targetRole": "Mechatronic Technologist",
    "yoe": 2,
    "reviews": [],
    "persona": true,
    "bootCampGraduate": false,
    "camera": true,
    "profile": true,
    "previousCodingProblems": [],
    "activeDuration": 2714743375622,
    "codingLevel": "",
    "timeZone": "America/New_York",
    "interviewTypes": [
        "General Interview"
    ],
    "userId": "d93b72e71e8e4772ac3f",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "skills": [
        "PLC programming",
        "Robotics",
        "Electrical troubleshooting"
    ]
},
{
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "university": "Stanford University",
    "isEntryLevel": false,
    "codingDifficulty": 4,
    "active": true,
    "bootCampGraduate": false,
    "platform": "peerfect2023",
    "id": "012e4ab137a64f519a60",
    "persona": true,
    "reviews": [],
    "subCategory": "Data Science",
    "educationLevel": "Master degree",
    "skills": [
        "Python",
        "R",
        "SQL",
        "Machine Learning"
    ],
    "previousCodingProblems": [],
    "interviews": 0,
    "school": "Stanford University",
    "areaOfStudy": "Computer Science",
    "timeZone": "America/New_York",
    "camera": true,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "interview": true,
    "profile": true,
    "currentCompany": "Google",
    "currentJob": "Data Analyst",
    "yoe": 3,
    "uid": "012e4ab137a64f519a60",
    "targetRole": "Data Scientist",
    "displayName": "red centipede",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                },
                {
                    "HourFormated": " 05:30 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "codingLevel": "",
    "currentPosition": "Data Analyst",
    "userId": "012e4ab137a64f519a60",
    "interviewTypes": [
        "General Interview"
    ],
    "activeDuration": 2714743375622
},
{
    "currentCompany": "Google",
    "yoe": 2,
    "profile": true,
    "active": true,
    "timeZone": "America/New_York",
    "camera": true,
    "codingLevel": "",
    "targetRole": "Web Analyst",
    "reviews": [],
    "codingDifficulty": 4,
    "interview": true,
    "userId": "f05a92622c884b908329",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "subCategory": "IT Support",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "university": "University of California, Berkeley",
    "displayName": "ivory porpoise",
    "interviews": 0,
    "currentPosition": "Data Analyst",
    "interviewTypes": [
        "General Interview"
    ],
    "category": "Communications, Media, and Information Technology",
    "currentJob": "Data Analyst",
    "bootCampGraduate": false,
    "activeDuration": 2714743375622,
    "previousCodingProblems": [],
    "uid": "f05a92622c884b908329",
    "areaOfStudy": "Information Technology",
    "platform": "peerfect2023",
    "persona": true,
    "educationLevel": "Bachelor degree",
    "id": "f05a92622c884b908329",
    "skills": [
        "Google Analytics",
        "HTML",
        "CSS",
        "JavaScript"
    ],
    "school": "University of California, Berkeley",
    "isEntryLevel": false
},
{
    "currentCompany": "Google",
    "active": true,
    "school": "University of California, Berkeley",
    "interviewTypes": [
        "General Interview"
    ],
    "currentJob": "Research Analyst",
    "targetRole": "Data Analyst",
    "codingLevel": "",
    "currentPosition": "Research Analyst",
    "interviews": 0,
    "timeZone": "America/New_York",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "subCategory": "Analytics",
    "category": "Professional, Scientific, and Technical Services",
    "yoe": 3,
    "camera": true,
    "persona": true,
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "bootCampGraduate": false,
    "userId": "1e8aefb5cb334d2e94ba",
    "uid": "1e8aefb5cb334d2e94ba",
    "previousCodingProblems": [],
    "university": "University of California, Berkeley",
    "id": "1e8aefb5cb334d2e94ba",
    "reviews": [],
    "areaOfStudy": "Data Science",
    "displayName": "magenta crayfish",
    "platform": "peerfect2023",
    "activeDuration": 2714743375622,
    "interview": true,
    "skills": [
        "SQL",
        "Python",
        "Statistical Analysis",
        "Data Visualization"
    ],
    "profile": true,
    "codingDifficulty": 4,
    "educationLevel": "Bachelor degree",
    "isEntryLevel": false
},
{
    "codingLevel": "",
    "isEntryLevel": true,
    "displayName": "red swift",
    "bootCampGraduate": false,
    "educationLevel": "Bachelor degree",
    "previousCodingProblems": [],
    "currentCompany": "Microsoft",
    "subCategory": "Research, Development, and Innovation",
    "userId": "453aeeef4d8e41ba831a",
    "id": "453aeeef4d8e41ba831a",
    "uid": "453aeeef4d8e41ba831a",
    "profile": true,
    "currentJob": "Data Analyst",
    "currentPosition": "Data Analyst",
    "yoe": 1,
    "reviews": [],
    "activeDuration": 2714743375622,
    "areaOfStudy": "Data Science",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:30 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "skills": [
        "SQL",
        "Python",
        "Data Visualization",
        "Statistical Analysis"
    ],
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "platform": "peerfect2023",
    "camera": true,
    "persona": true,
    "interviews": 0,
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "interviewTypes": [
        "General Interview"
    ],
    "targetRole": "Data Intern",
    "school": "University of California, Berkeley",
    "active": true,
    "university": "University of California, Berkeley",
    "interview": true,
    "timeZone": "America/New_York",
    "codingDifficulty": 4
},
{
    "id": "fc7566fe92e641ee970a",
    "codingDifficulty": 4,
    "educationLevel": "Bachelor degree",
    "subCategory": "Operations Management",
    "targetRole": "Administrative Services Manager",
    "camera": true,
    "platform": "peerfect2023",
    "persona": true,
    "currentJob": "Administrative Assistant",
    "school": "University of XYZ",
    "displayName": "scarlet partridge",
    "university": "University of XYZ",
    "interview": false,
    "profile": true,
    "activeDuration": 2714743375622,
    "skills": [],
    "isEntryLevel": false,
    "reviews": [],
    "codingLevel": "",
    "category": "Business, Management, and Administration",
    "userId": "fc7566fe92e641ee970a",
    "timeZone": "America/New_York",
    "yoe": 3,
    "interviews": 0,
    "previousCodingProblems": [],
    "currentPosition": "Administrative Assistant",
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "areaOfStudy": "Business Administration",
    "uid": "fc7566fe92e641ee970a",
    "bootCampGraduate": false,
    "currentCompany": "ABC Corporation",
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [],
            "day": "Sat"
        }
    ],
    "active": true,
    "interviewTypes": [
        "General Interview"
    ]
},
{
    "currentPosition": "Janitor",
    "category": "Installation, Repair, and Maintenance",
    "currentJob": "Janitor",
    "bootCampGraduate": false,
    "areaOfStudy": "Cleaning and Maintenance",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "university": "Sunshine High School",
    "id": "09d17a0067c94cfdb07d",
    "isEntryLevel": true,
    "platform": "peerfect2023",
    "camera": true,
    "active": true,
    "interview": false,
    "timeZone": "America/New_York",
    "targetRole": "Cleaner",
    "codingLevel": "",
    "interviewTypes": [
        "General Interview"
    ],
    "school": "Sunshine High School",
    "displayName": "rose newt",
    "yoe": 3,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "subCategory": "Utility Maintenance",
    "profile": true,
    "codingDifficulty": 4,
    "persona": true,
    "previousCodingProblems": [],
    "reviews": [],
    "userId": "09d17a0067c94cfdb07d",
    "educationLevel": "High school diploma",
    "skills": [],
    "activeDuration": 2714743375622,
    "uid": "09d17a0067c94cfdb07d",
    "currentCompany": "ABC Cleaning Services",
    "interviews": 0
},
{
    "reviews": [],
    "platform": "peerfect2023",
    "userId": "313dd0c3a44544399227",
    "currentPosition": "HR Assistant",
    "areaOfStudy": "Human Resource Management",
    "persona": true,
    "camera": true,
    "bootCampGraduate": false,
    "timeZone": "America/New_York",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        }
    ],
    "active": true,
    "educationLevel": "Bachelor degree",
    "displayName": "violet cheetah",
    "yoe": 2,
    "uid": "313dd0c3a44544399227",
    "category": "Business, Management, and Administration",
    "university": "University of California, Los Angeles",
    "currentCompany": "Google",
    "id": "313dd0c3a44544399227",
    "activeDuration": 2714743375622,
    "interview": true,
    "profile": true,
    "codingLevel": "",
    "codingDifficulty": 4,
    "previousCodingProblems": [],
    "skills": [
        "Recruitment",
        "Employee Relations",
        "Performance Management"
    ],
    "interviewTypes": [
        "General Interview"
    ],
    "subCategory": "Human Resources",
    "isEntryLevel": false,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "interviews": 0,
    "currentJob": "HR Assistant",
    "targetRole": "Human Resource Specialist",
    "school": "University of California, Los Angeles"
},
{
    "school": "Central High School",
    "currentPosition": "Warehouse Associate",
    "profile": true,
    "platform": "peerfect2023",
    "currentCompany": "Walmart",
    "active": true,
    "subCategory": "Store Operations",
    "reviews": [],
    "category": "Retail, Wholesale, and E-commerce",
    "previousCodingProblems": [],
    "interviews": 0,
    "areaOfStudy": "Business Administration",
    "educationLevel": "High school diploma",
    "isEntryLevel": true,
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "bootCampGraduate": false,
    "displayName": "blue platypus",
    "codingLevel": "",
    "codingDifficulty": 4,
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "interview": false,
    "skills": [],
    "persona": true,
    "university": "Central High School",
    "userId": "1b1192852b2b4cd8ad22",
    "timeZone": "America/New_York",
    "uid": "1b1192852b2b4cd8ad22",
    "currentJob": "Warehouse Associate",
    "camera": true,
    "targetRole": "Stock Clerk",
    "id": "1b1192852b2b4cd8ad22",
    "interviewTypes": [
        "General Interview"
    ],
    "yoe": 2,
    "activeDuration": 2714743375622
},
{
    "activeDuration": 2714743375622,
    "userId": "f19db3fdfa6649ba8fd5",
    "subCategory": "Export Trade Analysis",
    "platform": "peerfect2023",
    "educationLevel": "Bachelor degree",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "active": true,
    "codingLevel": "",
    "uid": "f19db3fdfa6649ba8fd5",
    "bootCampGraduate": false,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "currentCompany": "Amazon",
    "codingDifficulty": 4,
    "skills": [],
    "camera": true,
    "reviews": [],
    "interviews": 0,
    "currentPosition": "Supply Chain Coordinator",
    "targetRole": "Export Trade Analyst",
    "profile": true,
    "yoe": 3,
    "timeZone": "America/New_York",
    "interviewTypes": [
        "General Interview"
    ],
    "category": "Finance, Insurance, and Real Estate",
    "currentJob": "Supply Chain Coordinator",
    "id": "f19db3fdfa6649ba8fd5",
    "interview": false,
    "displayName": "pink stork",
    "university": "University of Michigan",
    "school": "University of Michigan",
    "persona": true,
    "isEntryLevel": false,
    "previousCodingProblems": [],
    "areaOfStudy": "International Business"
},
{
    "previousCodingProblems": [],
    "interviews": 0,
    "codingLevel": "",
    "profile": true,
    "category": "Human Resources and Talent Management",
    "active": true,
    "currentCompany": "Google",
    "school": "University of Michigan",
    "educationLevel": "Bachelor degree",
    "activeDuration": 2714743375622,
    "bootCampGraduate": false,
    "currentJob": "HR Assistant",
    "areaOfStudy": "Human Resources",
    "currentPosition": "HR Assistant",
    "uid": "1f5f1327a2274a9baa69",
    "interviewTypes": [
        "General Interview"
    ],
    "subCategory": "HR Interview",
    "university": "University of Michigan",
    "id": "1f5f1327a2274a9baa69",
    "targetRole": "HR Interviewer",
    "yoe": 3,
    "codingDifficulty": 4,
    "userId": "1f5f1327a2274a9baa69",
    "skills": [],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        }
    ],
    "timeZone": "America/New_York",
    "camera": true,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "isEntryLevel": false,
    "interview": false,
    "reviews": [],
    "platform": "peerfect2023",
    "displayName": "orange urial",
    "persona": true
},
{
    "educationLevel": "Bachelor degree",
    "displayName": "blue worm",
    "interviews": 0,
    "bootCampGraduate": false,
    "id": "7c027afac9584b3a9b9e",
    "activeDuration": 2714743375622,
    "currentJob": "Software Engineer",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "timeZone": "America/New_York",
    "platform": "peerfect2023",
    "uid": "7c027afac9584b3a9b9e",
    "codingDifficulty": 4,
    "profile": true,
    "persona": true,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "userId": "7c027afac9584b3a9b9e",
    "camera": true,
    "yoe": 3,
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "active": true,
    "reviews": [],
    "interview": true,
    "skills": [
        "Java",
        "Python",
        "SQL"
    ],
    "areaOfStudy": "Computer Science",
    "isEntryLevel": false,
    "subCategory": "Software Development",
    "previousCodingProblems": [],
    "currentPosition": "Software Engineer",
    "codingLevel": "",
    "university": "Stanford University",
    "school": "Stanford University",
    "currentCompany": "Google",
    "targetRole": "Backend Developer",
    "interviewTypes": [
        "General Interview"
    ]
},
{
    "currentJob": "Auto Technician",
    "active": true,
    "codingLevel": "",
    "userId": "b75a8fa6492d423dbf5a",
    "educationLevel": "Associate degree",
    "areaOfStudy": "Automotive Technology",
    "interview": true,
    "currentPosition": "Auto Technician",
    "isEntryLevel": false,
    "camera": true,
    "profile": true,
    "id": "b75a8fa6492d423dbf5a",
    "displayName": "gold hamster",
    "activeDuration": 2714743375622,
    "interviewTypes": [
        "General Interview"
    ],
    "previousCodingProblems": [],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 08:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:30 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "interviews": 0,
    "skills": [
        "Engine Diagnostics",
        "Transmission Repair",
        "Brake Systems",
        "Electrical Systems"
    ],
    "yoe": 3,
    "subCategory": "Automotive Repair",
    "university": "Universal Technical Institute",
    "reviews": [],
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "uid": "b75a8fa6492d423dbf5a",
    "targetRole": "Automotive Mechanic",
    "codingDifficulty": 4,
    "category": "Installation, Repair, and Maintenance",
    "school": "Universal Technical Institute",
    "platform": "peerfect2023",
    "currentCompany": "Pep Boys",
    "persona": true,
    "timeZone": "America/New_York",
    "bootCampGraduate": false
},
{
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        }
    ],
    "subCategory": "Technical Writing",
    "profile": true,
    "areaOfStudy": "Business Administration",
    "camera": true,
    "id": "34b1388702d246d2abcc",
    "educationLevel": "Bachelor degree",
    "codingLevel": "",
    "timeZone": "America/New_York",
    "active": true,
    "codingDifficulty": 4,
    "school": "University of California, Los Angeles",
    "university": "University of California, Los Angeles",
    "activeDuration": 2714743375622,
    "category": "Professional, Scientific, and Technical Services",
    "isEntryLevel": false,
    "interview": false,
    "uid": "34b1388702d246d2abcc",
    "skills": [],
    "reviews": [],
    "platform": "peerfect2023",
    "persona": true,
    "currentJob": "Administrative Assistant",
    "userId": "34b1388702d246d2abcc",
    "previousCodingProblems": [],
    "targetRole": "admin",
    "currentCompany": "Apple Inc.",
    "interviews": 0,
    "currentPosition": "Administrative Assistant",
    "yoe": 3,
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "displayName": "black heron",
    "interviewTypes": [
        "General Interview"
    ],
    "bootCampGraduate": false
},
{
    "codingLevel": "",
    "category": "Marketing, Sales, and Customer Service",
    "interviewTypes": [
        "General Interview"
    ],
    "subCategory": "Customer Support",
    "reviews": [],
    "codingDifficulty": 4,
    "persona": true,
    "skills": [],
    "bootCampGraduate": false,
    "interviews": 0,
    "currentCompany": "Amazon",
    "active": true,
    "activeDuration": 2714743375622,
    "platform": "peerfect2023",
    "school": "University of Florida",
    "university": "University of Florida",
    "isEntryLevel": false,
    "yoe": 2,
    "id": "33104b1092e64aa699b8",
    "currentJob": "Customer Support Specialist",
    "displayName": "cyan marmot",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        }
    ],
    "interview": false,
    "uid": "33104b1092e64aa699b8",
    "camera": true,
    "userId": "33104b1092e64aa699b8",
    "educationLevel": "Bachelor degree",
    "timeZone": "America/New_York",
    "currentPosition": "Customer Support Specialist",
    "profile": true,
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    },
    "targetRole": "Customer Service Agent",
    "previousCodingProblems": [],
    "areaOfStudy": "Communication"
},
{
    "bootCampGraduate": false,
    "currentPosition": "Supply Chain Analyst",
    "currentCompany": "Walmart",
    "targetRole": "Supply Chain Planning Manager",
    "displayName": "azure guan",
    "yoe": 3,
    "id": "37b1b7f152ba41a7b5b4",
    "camera": true,
    "university": "University of Michigan",
    "currentJob": "Supply Chain Analyst",
    "activeDuration": 2714743375622,
    "school": "University of Michigan",
    "previousCodingProblems": [],
    "platform": "peerfect2023",
    "category": "Manufacturing and Production",
    "interviewTypes": [
        "General Interview"
    ],
    "persona": true,
    "areaOfStudy": "Supply Chain Management",
    "active": true,
    "educationLevel": "Bachelor degree",
    "codingLevel": "",
    "skills": [
        "Demand forecasting",
        "Inventory management",
        "S&OP process",
        "Supply chain optimization"
    ],
    "userId": "37b1b7f152ba41a7b5b4",
    "codingDifficulty": 4,
    "subCategory": "Supply Chain Management",
    "uid": "37b1b7f152ba41a7b5b4",
    "reviews": [],
    "interview": true,
    "timeZone": "America/New_York",
    "isEntryLevel": false,
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:45 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:15 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "HourFormated": " 02:45 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "interviews": 0,
    "profile": true,
    "rating": {
        "rating": 0,
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0
    }
},
{
    "codingLevel": "",
    "platform": "peerfect2023",
    "areaOfStudy": "Medical Laboratory Technology",
    "currentCompany": "Quest Diagnostics",
    "interviewTypes": [
        "General Interview"
    ],
    "interview": true,
    "university": "Midwest Technical Institute",
    "educationLevel": "Associate degree",
    "currentPosition": "Medical Laboratory Assistant",
    "uid": "5db2e6dbf686463c8bdb",
    "yoe": 2,
    "displayName": "tomato platypus",
    "school": "Midwest Technical Institute",
    "category": "Healthcare and Medical Services",
    "skills": [
        "Laboratory techniques",
        "Medical terminology knowledge",
        "Quality control",
        "Data analysis"
    ],
    "timeZone": "America/New_York",
    "camera": true,
    "previousCodingProblems": [],
    "codingDifficulty": 4,
    "bootCampGraduate": false,
    "id": "5db2e6dbf686463c8bdb",
    "subCategory": "Medical Services",
    "isEntryLevel": false,
    "active": true,
    "currentJob": "Medical Laboratory Assistant",
    "profile": true,
    "activeDuration": 2714743375622,
    "targetRole": "Clinical Laboratory Technician",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "reviews": [],
    "interviews": 0,
    "persona": true,
    "userId": "5db2e6dbf686463c8bdb"
},
{
    "camera": true,
    "userId": "45ad483dd95b44ccb857",
    "bootCampGraduate": false,
    "previousCodingProblems": [],
    "university": "University of Southern California",
    "interviews": 0,
    "active": true,
    "uid": "45ad483dd95b44ccb857",
    "codingDifficulty": 4,
    "interview": false,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "persona": true,
    "category": "Marketing, Sales, and Customer Service",
    "areaOfStudy": "Business Administration",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "isEntryLevel": false,
    "school": "University of Southern California",
    "currentPosition": "Customer Support Specialist",
    "targetRole": "Customer Success",
    "yoe": 3,
    "activeDuration": 2714743375622,
    "codingLevel": "",
    "id": "45ad483dd95b44ccb857",
    "platform": "peerfect2023",
    "profile": true,
    "skills": [],
    "interviewTypes": [
        "General Interview"
    ],
    "displayName": "black krill",
    "subCategory": "Customer Support",
    "reviews": [],
    "timeZone": "America/New_York",
    "educationLevel": "Bachelor degree",
    "currentCompany": "Amazon",
    "currentJob": "Customer Support Specialist"
},
{
    "interview": true,
    "subCategory": "Data Science",
    "school": "Stanford University",
    "previousCodingProblems": [],
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "bootCampGraduate": false,
    "uid": "0402868523c741e988d0",
    "timeZone": "America/New_York",
    "university": "Stanford University",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "userId": "0402868523c741e988d0",
    "educationLevel": "Master degree",
    "active": true,
    "isEntryLevel": false,
    "areaOfStudy": "Data Science",
    "skills": [
        "Machine Learning",
        "Python",
        "SQL",
        "Statistical Analysis"
    ],
    "interviewTypes": [
        "General Interview"
    ],
    "currentJob": "Data Analyst",
    "codingDifficulty": 4,
    "id": "0402868523c741e988d0",
    "platform": "peerfect2023",
    "yoe": 3,
    "reviews": [],
    "displayName": "brown damselfly",
    "interviews": 0,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "targetRole": "Data Scientist",
    "currentPosition": "Data Analyst",
    "codingLevel": "",
    "currentCompany": "Google",
    "persona": true,
    "camera": true,
    "profile": true,
    "activeDuration": 2714743375622
},
{
    "university": "Community College",
    "currentJob": "Administrative Assistant",
    "camera": true,
    "bootCampGraduate": false,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        }
    ],
    "areaOfStudy": "Business Administration",
    "targetRole": "Receptionist",
    "isEntryLevel": true,
    "interviewTypes": [
        "General Interview"
    ],
    "activeDuration": 2714743375622,
    "reviews": [],
    "subCategory": "Administration",
    "profile": true,
    "userId": "641c3cc79c2a4f47918a",
    "currentCompany": "ABC Corporation",
    "codingDifficulty": 4,
    "category": "Administration & Office Support",
    "skills": [],
    "previousCodingProblems": [],
    "codingLevel": "",
    "currentPosition": "Administrative Assistant",
    "school": "Community College",
    "displayName": "plum otter",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "yoe": 2,
    "timeZone": "America/New_York",
    "platform": "peerfect2023",
    "id": "641c3cc79c2a4f47918a",
    "educationLevel": "Associate degree",
    "uid": "641c3cc79c2a4f47918a",
    "active": true,
    "persona": true,
    "interviews": 0
},
{
    "displayName": "amaranth ostrich",
    "subCategory": "Mechanical Engineering",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "university": "University of Michigan",
    "camera": true,
    "uid": "b006721861a740f78443",
    "active": true,
    "platform": "peerfect2023",
    "interviews": 0,
    "persona": true,
    "codingLevel": "",
    "id": "b006721861a740f78443",
    "areaOfStudy": "Mechanical Engineering",
    "skills": [
        "SolidWorks",
        "AutoCAD",
        "Thermodynamics",
        "Fluid Mechanics"
    ],
    "timeZone": "America/New_York",
    "currentPosition": "Intern",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "userId": "b006721861a740f78443",
    "previousCodingProblems": [],
    "currentCompany": "General Motors",
    "reviews": [],
    "school": "University of Michigan",
    "bootCampGraduate": false,
    "codingDifficulty": 4,
    "isEntryLevel": true,
    "currentJob": "Intern",
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "activeDuration": 2714743375622,
    "educationLevel": "Bachelor degree",
    "profile": true,
    "targetRole": "Mechanical Engineer Fresher",
    "yoe": 0,
    "interview": true
},
{
    "id": "ecc3dfe7fe944f9da156",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "interviewTypes": [
        "General Interview"
    ],
    "displayName": "aquamarine weasel",
    "active": true,
    "interviews": 0,
    "profile": true,
    "platform": "peerfect2023",
    "currentCompany": "Google",
    "reviews": [],
    "skills": [
        "Java",
        "Python",
        "OOP",
        "Algorithms",
        "Data Structures"
    ],
    "codingLevel": "",
    "university": "University of California, Berkeley",
    "educationLevel": "Bachelor degree",
    "yoe": 2,
    "previousCodingProblems": [],
    "camera": true,
    "uid": "ecc3dfe7fe944f9da156",
    "areaOfStudy": "Computer Science",
    "codingDifficulty": 4,
    "interview": true,
    "isEntryLevel": false,
    "bootCampGraduate": false,
    "school": "University of California, Berkeley",
    "activeDuration": 2714743375622,
    "subCategory": "Software Development",
    "targetRole": "Software Development Engineer",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "timeZone": "America/New_York",
    "currentPosition": "Junior Software Engineer",
    "userId": "ecc3dfe7fe944f9da156",
    "persona": true,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "currentJob": "Junior Software Engineer"
},
{
    "bootCampGraduate": false,
    "codingDifficulty": 4,
    "currentCompany": "J.P. Morgan Chase",
    "displayName": "beige landfowl",
    "interviews": 0,
    "active": true,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "subCategory": "Investment Management",
    "profile": true,
    "timeZone": "America/New_York",
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "areaOfStudy": "Finance",
    "persona": true,
    "skills": [],
    "id": "e65190b9302c41e18011",
    "codingLevel": "",
    "previousCodingProblems": [],
    "category": "Finance, Insurance, and Real Estate",
    "camera": true,
    "yoe": 3,
    "interviewTypes": [
        "General Interview"
    ],
    "currentJob": "Financial Analyst",
    "userId": "e65190b9302c41e18011",
    "interview": false,
    "reviews": [],
    "school": "University of California, Berkeley",
    "university": "University of California, Berkeley",
    "targetRole": "SBL middle office specialist",
    "activeDuration": 2714743375622,
    "platform": "peerfect2023",
    "uid": "e65190b9302c41e18011",
    "educationLevel": "Bachelor degree",
    "isEntryLevel": false,
    "currentPosition": "Financial Analyst"
},
{
    "areaOfStudy": "Theatre Arts",
    "previousCodingProblems": [],
    "currentPosition": "Theatre Performer",
    "isEntryLevel": false,
    "id": "39624216f8b94d68830f",
    "bootCampGraduate": false,
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "uid": "39624216f8b94d68830f",
    "targetRole": "Actor",
    "displayName": "sapphire parakeet",
    "currentJob": "Theatre Performer",
    "profile": true,
    "university": "Julliard School",
    "active": true,
    "subCategory": "Acting",
    "interview": true,
    "persona": true,
    "platform": "peerfect2023",
    "educationLevel": "Bachelor degree",
    "timeZone": "America/New_York",
    "category": "Arts, Culture, and Entertainment",
    "yoe": 4,
    "activeDuration": 2714743375622,
    "interviews": 0,
    "codingDifficulty": 4,
    "camera": true,
    "codingLevel": "",
    "interviewTypes": [
        "General Interview"
    ],
    "skills": [
        "Acting",
        "Improvisation",
        "Character Development",
        "Script Analysis"
    ],
    "reviews": [],
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 11:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "currentCompany": "Broadway Theatre Company",
    "school": "Julliard School",
    "userId": "39624216f8b94d68830f"
},
{
    "codingLevel": "",
    "codingDifficulty": 4,
    "school": "ABC University",
    "persona": true,
    "currentCompany": "TechSolutions Inc.",
    "userId": "e79abece666d4b67a2e5",
    "profile": true,
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "educationLevel": "Bachelor degree",
    "active": true,
    "activeDuration": 2714743375622,
    "subCategory": "Software Development",
    "timeZone": "America/New_York",
    "interview": true,
    "interviews": 0,
    "targetRole": "Frontend Developer",
    "areaOfStudy": "Computer Science",
    "id": "e79abece666d4b67a2e5",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "displayName": "tan ostrich",
    "platform": "peerfect2023",
    "currentJob": "Junior Web Developer",
    "reviews": [],
    "university": "ABC University",
    "currentPosition": "Junior Web Developer",
    "interviewTypes": [
        "General Interview"
    ],
    "uid": "e79abece666d4b67a2e5",
    "camera": true,
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "isEntryLevel": false,
    "bootCampGraduate": false,
    "yoe": 2,
    "skills": [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Responsive Design"
    ],
    "previousCodingProblems": []
},
{
    "interview": true,
    "id": "425f59b9c9c74dbaba90",
    "currentCompany": "Amazon",
    "interviewTypes": [
        "General Interview"
    ],
    "areaOfStudy": "Computer Science",
    "platform": "peerfect2023",
    "subCategory": "Data Science",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "activeDuration": 2714743375622,
    "codingDifficulty": 4,
    "yoe": 3,
    "displayName": "aquamarine swallow",
    "skills": [
        "Python",
        "Machine Learning Algorithms",
        "Deep Learning",
        "Natural Language Processing"
    ],
    "active": true,
    "currentPosition": "Data Scientist",
    "university": "Stanford University",
    "userId": "425f59b9c9c74dbaba90",
    "targetRole": "Machine Learning Engineer",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "previousCodingProblems": [],
    "interviews": 0,
    "educationLevel": "Master degree",
    "school": "Stanford University",
    "profile": true,
    "uid": "425f59b9c9c74dbaba90",
    "isEntryLevel": false,
    "codingLevel": "",
    "camera": true,
    "currentJob": "Data Scientist",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        }
    ],
    "reviews": [],
    "persona": true,
    "bootCampGraduate": false,
    "timeZone": "America/New_York"
},
{
    "educationLevel": "Bachelor degree",
    "profile": true,
    "persona": true,
    "timeZone": "America/New_York",
    "interview": true,
    "currentPosition": "Sales Associate",
    "currentCompany": "Salesforce",
    "university": "University of California, Berkeley",
    "category": "Marketing, Sales, and Customer Service",
    "targetRole": "Business Development Representative",
    "isEntryLevel": false,
    "uid": "d936d6768ecc46199c97",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "active": true,
    "activeDuration": 2714743375622,
    "platform": "peerfect2023",
    "previousCodingProblems": [],
    "camera": true,
    "subCategory": "Sales",
    "currentJob": "Sales Associate",
    "codingLevel": "",
    "bootCampGraduate": false,
    "school": "University of California, Berkeley",
    "userId": "d936d6768ecc46199c97",
    "skills": [
        "Sales prospecting",
        "Lead generation",
        "Customer relationship management"
    ],
    "yoe": 2,
    "id": "d936d6768ecc46199c97",
    "interviews": 0,
    "areaOfStudy": "Business Administration",
    "interviewTypes": [
        "General Interview"
    ],
    "reviews": [],
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "codingDifficulty": 4,
    "displayName": "amber antelope"
},
{
    "id": "ad41eff7ced44205b9a4",
    "bootCampGraduate": false,
    "areaOfStudy": "Pharmacy",
    "interview": true,
    "activeDuration": 2714743375622,
    "previousCodingProblems": [],
    "targetRole": "Pharmacy Fellowship",
    "educationLevel": "Bachelor degree",
    "interviews": 0,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "currentPosition": "Pharmacy Technician",
    "profile": true,
    "userId": "ad41eff7ced44205b9a4",
    "currentJob": "Pharmacy Technician",
    "camera": true,
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:30 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "uid": "ad41eff7ced44205b9a4",
    "codingLevel": "",
    "yoe": 3,
    "university": "University of California, San Francisco",
    "timeZone": "America/New_York",
    "active": true,
    "skills": [
        "Medication dispensing",
        "Patient interaction",
        "Inventory management",
        "Pharmacy regulations"
    ],
    "reviews": [],
    "category": "Healthcare and Medical Services",
    "platform": "peerfect2023",
    "subCategory": "Pharmacy",
    "school": "University of California, San Francisco",
    "displayName": "sapphire xerinae",
    "currentCompany": "CVS Health",
    "codingDifficulty": 4,
    "isEntryLevel": false,
    "persona": true
},
{
    "timeZone": "America/New_York",
    "school": "Culinary Institute of America",
    "bootCampGraduate": false,
    "camera": true,
    "activeDuration": 2714743375622,
    "currentCompany": "Red Lobster",
    "reviews": [],
    "currentJob": "Line Cook",
    "skills": [
        "Knife skills",
        "Food safety knowledge",
        "Menu planning",
        "Cooking techniques"
    ],
    "subCategory": "Food and Beverage Management",
    "userId": "cb3c6ab6f9f345b6a746",
    "interviewTypes": [
        "General Interview"
    ],
    "id": "cb3c6ab6f9f345b6a746",
    "previousCodingProblems": [],
    "interview": true,
    "university": "Culinary Institute of America",
    "persona": true,
    "yoe": 3,
    "targetRole": "Cook",
    "areaOfStudy": "Culinary Arts",
    "isEntryLevel": false,
    "currentPosition": "Line Cook",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "codingLevel": "",
    "profile": true,
    "displayName": "lime roadrunner",
    "platform": "peerfect2023",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 08:00 AM"
                }
            ],
            "day": "Sun"
        }
    ],
    "codingDifficulty": 4,
    "category": "Hospitality, Tourism, and Food Services",
    "active": true,
    "educationLevel": "High school diploma",
    "uid": "cb3c6ab6f9f345b6a746",
    "interviews": 0
},
{
    "university": "University of Michigan",
    "isEntryLevel": false,
    "interviews": 0,
    "bootCampGraduate": false,
    "codingDifficulty": 4,
    "skills": [
        "Lean Manufacturing",
        "Six Sigma",
        "Quality Control",
        "Production Planning"
    ],
    "activeDuration": 2714743375622,
    "uid": "bc37c7eb29114898ae85",
    "platform": "peerfect2023",
    "userId": "bc37c7eb29114898ae85",
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "interview": true,
    "camera": true,
    "areaOfStudy": "Mechanical Engineering",
    "currentCompany": "General Electric",
    "category": "Manufacturing",
    "persona": true,
    "targetRole": "Manufacturing",
    "school": "University of Michigan",
    "codingLevel": "",
    "yoe": 4,
    "id": "bc37c7eb29114898ae85",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 07:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "reviews": [],
    "educationLevel": "Bachelor degree",
    "profile": true,
    "currentPosition": "Production Technician",
    "currentJob": "Production Technician",
    "interviewTypes": [
        "General Interview"
    ],
    "active": true,
    "displayName": "tan clownfish",
    "subCategory": "Manufacturing and Production"
},
{
    "userId": "50f2e39e9cf346b79bd4",
    "educationLevel": "Bachelor degree",
    "currentCompany": "CrowdStrike",
    "currentJob": "Cybersecurity Analyst",
    "previousCodingProblems": [],
    "category": "Communications, Media, and Information Technology",
    "areaOfStudy": "Cybersecurity",
    "skills": [
        "Threat intelligence analysis",
        "Malware analysis",
        "Incident response",
        "Cyber threat hunting"
    ],
    "bootCampGraduate": false,
    "subCategory": "Information Security",
    "codingDifficulty": 4,
    "currentPosition": "Cybersecurity Analyst",
    "reviews": [],
    "school": "University of California, Berkeley",
    "active": true,
    "targetRole": "Threat Intel Analyst",
    "uid": "50f2e39e9cf346b79bd4",
    "camera": true,
    "interview": true,
    "profile": true,
    "university": "University of California, Berkeley",
    "persona": true,
    "platform": "peerfect2023",
    "interviews": 0,
    "codingLevel": "",
    "interviewTypes": [
        "General Interview"
    ],
    "id": "50f2e39e9cf346b79bd4",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "displayName": "magenta ptarmigan",
    "isEntryLevel": false,
    "timeZone": "America/New_York",
    "yoe": 3,
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "HourFormated": " 01:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "activeDuration": 2714743375622
},
{
    "yoe": 3,
    "subCategory": "IT Support",
    "codingLevel": "",
    "uid": "f94d469884cf474ea438",
    "isEntryLevel": false,
    "previousCodingProblems": [],
    "userId": "f94d469884cf474ea438",
    "camera": true,
    "profile": true,
    "platform": "peerfect2023",
    "id": "f94d469884cf474ea438",
    "skills": [],
    "currentPosition": "Help Desk Technician",
    "activeDuration": 2714743375622,
    "persona": true,
    "displayName": "violet haddock",
    "codingDifficulty": 4,
    "active": true,
    "interview": false,
    "reviews": [],
    "targetRole": "IT Support",
    "university": "Tech University",
    "currentJob": "Help Desk Technician",
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "category": "Communications, Media, and Information Technology",
    "interviewTypes": [
        "General Interview"
    ],
    "timeZone": "America/New_York",
    "school": "Tech University",
    "educationLevel": "Bachelor degree",
    "areaOfStudy": "Information Technology",
    "bootCampGraduate": false,
    "currentCompany": "ABC Tech Solutions",
    "interviews": 0,
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ]
},
{
    "userId": "8e2d1c84642440ec99bd",
    "timeZone": "America/New_York",
    "university": "University of California, Berkeley",
    "platform": "peerfect2023",
    "educationLevel": "Master degree",
    "profile": true,
    "subCategory": "Product Management",
    "school": "University of California, Berkeley",
    "codingLevel": "",
    "active": true,
    "targetRole": "Product Manager",
    "codingDifficulty": 4,
    "areaOfStudy": "Business Administration",
    "previousCodingProblems": [],
    "interviews": 0,
    "currentJob": "Product Analyst",
    "category": "Business, Management, and Administration",
    "reviews": [],
    "id": "8e2d1c84642440ec99bd",
    "currentPosition": "Product Analyst",
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "hour": [],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ],
            "day": "Sun"
        }
    ],
    "persona": true,
    "uid": "8e2d1c84642440ec99bd",
    "activeDuration": 2714743375622,
    "skills": [],
    "displayName": "pink ox",
    "yoe": 3,
    "bootCampGraduate": false,
    "currentCompany": "Amazon",
    "isEntryLevel": false,
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "interview": false,
    "camera": true
},
{
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                },
                {
                    "HourFormated": " 04:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        }
    ],
    "uid": "641e055bf0a04d71b929",
    "codingDifficulty": 4,
    "id": "641e055bf0a04d71b929",
    "interviews": 0,
    "interviewTypes": [
        "General Interview"
    ],
    "skills": [
        "Market Research",
        "Product Strategy",
        "Data Analysis"
    ],
    "reviews": [],
    "userId": "641e055bf0a04d71b929",
    "currentJob": "Product Analyst",
    "profile": true,
    "targetRole": "Product Management",
    "yoe": 4,
    "subCategory": "Product Management",
    "persona": true,
    "currentPosition": "Product Analyst",
    "camera": true,
    "school": "University of California, Berkeley",
    "areaOfStudy": "Business Administration",
    "activeDuration": 2714743375622,
    "bootCampGraduate": false,
    "university": "University of California, Berkeley",
    "codingLevel": "",
    "educationLevel": "Bachelor degree",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "interview": true,
    "currentCompany": "Google",
    "platform": "peerfect2023",
    "displayName": "red heron",
    "timeZone": "America/New_York",
    "previousCodingProblems": [],
    "isEntryLevel": false,
    "category": "Business, Management, and Administration",
    "active": true
},
{
    "skills": [
        "Anatomy",
        "Medical Terminology",
        "Patient Care"
    ],
    "id": "1cc92c5eefab4d9fac91",
    "yoe": 2,
    "timeZone": "America/New_York",
    "displayName": "tan gecko",
    "uid": "1cc92c5eefab4d9fac91",
    "codingDifficulty": 4,
    "educationLevel": "Bachelor degree",
    "persona": true,
    "bootCampGraduate": false,
    "activeDuration": 2714743375622,
    "currentCompany": "Mayo Clinic",
    "camera": true,
    "currentPosition": "Medical Assistant",
    "interviews": 0,
    "subCategory": "Medical Administration",
    "reviews": [],
    "profile": true,
    "interview": true,
    "category": "Healthcare and Medical Services",
    "platform": "peerfect2023",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "school": "University of California, Berkeley",
    "university": "University of California, Berkeley",
    "previousCodingProblems": [],
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "userId": "1cc92c5eefab4d9fac91",
    "isEntryLevel": true,
    "codingLevel": "",
    "areaOfStudy": "Biology",
    "targetRole": "Medical School Student",
    "currentJob": "Medical Assistant",
    "active": true
},
{
    "codingLevel": "",
    "educationLevel": "Bachelor degree",
    "skills": [],
    "platform": "peerfect2023",
    "bootCampGraduate": false,
    "interviews": 0,
    "category": "Community, Social Services, and Human Services",
    "currentJob": "Social Media Coordinator",
    "active": true,
    "userId": "a94afb97e16e4b3f91ac",
    "subCategory": "Community Outreach",
    "isEntryLevel": false,
    "areaOfStudy": "Communication",
    "university": "University of Southern California",
    "reviews": [],
    "persona": true,
    "id": "a94afb97e16e4b3f91ac",
    "activeDuration": 2714743375622,
    "codingDifficulty": 4,
    "school": "University of Southern California",
    "interviewTypes": [
        "General Interview"
    ],
    "displayName": "gray clownfish",
    "currentPosition": "Social Media Coordinator",
    "camera": true,
    "currentCompany": "Twitter",
    "previousCodingProblems": [],
    "yoe": 2,
    "interview": false,
    "profile": true,
    "timeZone": "America/New_York",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 07:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:30 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "targetRole": "Community Outreach Specialist",
    "uid": "a94afb97e16e4b3f91ac",
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    }
},
{
    "currentPosition": "Community Organizer",
    "active": true,
    "interviewTypes": [
        "General Interview"
    ],
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "camera": true,
    "previousCodingProblems": [],
    "reviews": [],
    "codingDifficulty": 4,
    "category": "Nonprofit, Philanthropy, and Fundraising",
    "yoe": 4,
    "university": "University of California, Berkeley",
    "isEntryLevel": false,
    "school": "University of California, Berkeley",
    "timeZone": "America/New_York",
    "profile": true,
    "areaOfStudy": "Social Work",
    "bootCampGraduate": false,
    "codingLevel": "",
    "platform": "peerfect2023",
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "interview": false,
    "targetRole": "Community Outreach",
    "id": "45cb376e62f44ffcbf5b",
    "skills": [],
    "educationLevel": "Bachelor degree",
    "persona": true,
    "interviews": 0,
    "uid": "45cb376e62f44ffcbf5b",
    "currentJob": "Community Organizer",
    "activeDuration": 2714743375622,
    "subCategory": "Community Outreach",
    "currentCompany": "ACLU",
    "displayName": "gray mastodon",
    "userId": "45cb376e62f44ffcbf5b"
},
{
    "isEntryLevel": false,
    "displayName": "scarlet mockingbird",
    "previousCodingProblems": [],
    "profile": true,
    "currentPosition": "Software Engineer",
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "yoe": 3,
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 07:00 PM"
                }
            ]
        }
    ],
    "camera": true,
    "interview": true,
    "areaOfStudy": "Computer Science",
    "school": "University of California, Berkeley",
    "reviews": [],
    "bootCampGraduate": false,
    "currentCompany": "Google",
    "targetRole": "Tester",
    "activeDuration": 2714743375622,
    "persona": true,
    "currentJob": "Software Engineer",
    "subCategory": "Technology",
    "userId": "b2ce0d8717ed4479a7ee",
    "skills": [
        "Test automation",
        "Regression testing",
        "QA methodologies"
    ],
    "university": "University of California, Berkeley",
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "interviews": 0,
    "id": "b2ce0d8717ed4479a7ee",
    "codingDifficulty": 4,
    "platform": "peerfect2023",
    "timeZone": "America/New_York",
    "uid": "b2ce0d8717ed4479a7ee",
    "interviewTypes": [
        "General Interview"
    ],
    "codingLevel": "",
    "active": true,
    "educationLevel": "Bachelor degree"
},
{
    "school": "ABC Technical Institute",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "activeDuration": 2714743375622,
    "category": "Healthcare and Medical Services",
    "subCategory": "Medical Administration",
    "camera": true,
    "timeZone": "America/New_York",
    "reviews": [],
    "currentPosition": "Medical Office Assistant",
    "interviews": 0,
    "uid": "e49e3276bec44014bef1",
    "areaOfStudy": "Medical Assisting",
    "targetRole": "Medical Assistant",
    "codingDifficulty": 4,
    "bootCampGraduate": false,
    "isEntryLevel": false,
    "codingLevel": "",
    "id": "e49e3276bec44014bef1",
    "university": "ABC Technical Institute",
    "previousCodingProblems": [],
    "skills": [],
    "persona": true,
    "profile": true,
    "interviewTypes": [
        "General Interview"
    ],
    "yoe": 2,
    "active": true,
    "displayName": "white coral",
    "currentJob": "Medical Office Assistant",
    "platform": "peerfect2023",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "educationLevel": "Associate degree",
    "interview": false,
    "userId": "e49e3276bec44014bef1",
    "currentCompany": "Cleveland Clinic"
},
{
    "previousCodingProblems": [],
    "active": true,
    "university": "Stanford University",
    "currentCompany": "Google",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "targetRole": "Product Manager AI ML",
    "bootCampGraduate": false,
    "camera": true,
    "platform": "peerfect2023",
    "currentJob": "Data Scientist",
    "interview": true,
    "areaOfStudy": "Computer Science",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 06:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "skills": [
        "Machine Learning",
        "Data Analysis",
        "Product Management"
    ],
    "profile": true,
    "activeDuration": 2714743375622,
    "interviews": 0,
    "yoe": 3,
    "displayName": "tan crocodile",
    "school": "Stanford University",
    "interviewTypes": [
        "General Interview"
    ],
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Data Science",
    "id": "d41facd241814e269a7f",
    "educationLevel": "Master degree",
    "codingDifficulty": 4,
    "currentPosition": "Data Scientist",
    "isEntryLevel": false,
    "codingLevel": "",
    "persona": true,
    "reviews": [],
    "uid": "d41facd241814e269a7f",
    "timeZone": "America/New_York",
    "userId": "d41facd241814e269a7f"
},
{
    "codingLevel": "",
    "isEntryLevel": true,
    "currentJob": "Junior Interior Designer",
    "platform": "peerfect2023",
    "skills": [
        "AutoCAD",
        "SketchUp",
        "Revit",
        "Space planning",
        "Color theory"
    ],
    "activeDuration": 2714743375622,
    "category": "Architecture, Engineering, and Construction",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "displayName": "gold bobolink",
    "userId": "341f9ec5b3524a3d8762",
    "interviews": 0,
    "reviews": [],
    "persona": true,
    "camera": true,
    "previousCodingProblems": [],
    "bootCampGraduate": false,
    "currentCompany": "ABC Design Group",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        }
    ],
    "subCategory": "Interior Design",
    "university": "Design Institute of America",
    "school": "Design Institute of America",
    "interviewTypes": [
        "General Interview"
    ],
    "codingDifficulty": 4,
    "id": "341f9ec5b3524a3d8762",
    "timeZone": "America/New_York",
    "targetRole": "Interior Design Co-op Placement",
    "profile": true,
    "active": true,
    "areaOfStudy": "Interior Design",
    "interview": true,
    "uid": "341f9ec5b3524a3d8762",
    "educationLevel": "Bachelor degree",
    "currentPosition": "Junior Interior Designer",
    "yoe": 2
},
{
    "activeDuration": 2714743375622,
    "currentJob": "HR Specialist",
    "displayName": "harlequin marten",
    "interview": true,
    "timeZone": "America/New_York",
    "id": "c41ca7eb708f42da9ff8",
    "targetRole": "Human Resources Manager",
    "isEntryLevel": false,
    "interviewTypes": [
        "General Interview"
    ],
    "university": "University of California, Berkeley",
    "camera": true,
    "currentCompany": "Google",
    "interviews": 0,
    "platform": "peerfect2023",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sun"
        }
    ],
    "subCategory": "Human Resources",
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "skills": [
        "Employee Relations",
        "Recruitment",
        "Training and Development",
        "Performance Management"
    ],
    "educationLevel": "Bachelor degree",
    "codingDifficulty": 4,
    "previousCodingProblems": [],
    "currentPosition": "HR Specialist",
    "codingLevel": "",
    "yoe": 4,
    "userId": "c41ca7eb708f42da9ff8",
    "active": true,
    "reviews": [],
    "school": "University of California, Berkeley",
    "profile": true,
    "category": "Business, Management, and Administration",
    "uid": "c41ca7eb708f42da9ff8",
    "bootCampGraduate": false,
    "areaOfStudy": "Human Resources Management",
    "persona": true
},
{
    "educationLevel": "Bachelor degree",
    "previousCodingProblems": [],
    "interviews": 0,
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "codingLevel": "",
    "userId": "0b94289129a04532b4ea",
    "yoe": 2,
    "bootCampGraduate": false,
    "activeDuration": 2714743375622,
    "currentPosition": "Sales Associate",
    "targetRole": "Account Representative",
    "availability": [
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:30 AM"
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:30 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:30 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:30 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "university": "University of California, Berkeley",
    "active": true,
    "currentJob": "Sales Associate",
    "id": "0b94289129a04532b4ea",
    "subCategory": "Sales",
    "reviews": [],
    "displayName": "indigo leopon",
    "uid": "0b94289129a04532b4ea",
    "platform": "peerfect2023",
    "interviewTypes": [
        "General Interview"
    ],
    "profile": true,
    "isEntryLevel": false,
    "school": "University of California, Berkeley",
    "timeZone": "America/New_York",
    "skills": [],
    "interview": false,
    "persona": true,
    "camera": true,
    "currentCompany": "Best Buy",
    "codingDifficulty": 4,
    "category": "Marketing, Sales, and Customer Service",
    "areaOfStudy": "Business Administration"
},
{
    "university": "Art Institute of California",
    "reviews": [],
    "interviews": 0,
    "platform": "peerfect2023",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    },
    "skills": [
        "Adobe Creative Suite",
        "User Interface Design",
        "Typography",
        "Color Theory"
    ],
    "school": "Art Institute of California",
    "codingLevel": "",
    "active": true,
    "codingDifficulty": 4,
    "areaOfStudy": "Graphic Design",
    "id": "a9678a85f85f45cbb4a3",
    "interviewTypes": [
        "General Interview"
    ],
    "targetRole": "UI Designer",
    "uid": "a9678a85f85f45cbb4a3",
    "currentJob": "Graphic Designer",
    "userId": "a9678a85f85f45cbb4a3",
    "bootCampGraduate": false,
    "previousCodingProblems": [],
    "timeZone": "America/New_York",
    "activeDuration": 2714743375622,
    "subCategory": "User Interface Design",
    "camera": true,
    "yoe": 4,
    "isEntryLevel": false,
    "category": "Communications, Media, and Information Technology",
    "displayName": "orange nightingale",
    "educationLevel": "Bachelor degree",
    "interview": true,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:30 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ],
            "day": "Sat"
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "currentPosition": "Graphic Designer",
    "persona": true,
    "currentCompany": "Adobe Systems",
    "profile": true
},
{
    "currentCompany": "Target",
    "persona": true,
    "displayName": "aquamarine caterpillar",
    "skills": [],
    "previousCodingProblems": [],
    "interview": false,
    "subCategory": "Retail Sales",
    "university": "Westview High School",
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "hour": [
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        }
    ],
    "id": "ec79a755ce7646fcbc71",
    "platform": "peerfect2023",
    "school": "Westview High School",
    "camera": true,
    "category": "Retail, Wholesale, and E-commerce",
    "active": true,
    "codingDifficulty": 4,
    "timeZone": "America/New_York",
    "userId": "ec79a755ce7646fcbc71",
    "yoe": 2,
    "targetRole": "Retail Salesperson",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "profile": true,
    "currentJob": "Sales Associate",
    "codingLevel": "",
    "isEntryLevel": true,
    "interviewTypes": [
        "General Interview"
    ],
    "bootCampGraduate": false,
    "currentPosition": "Sales Associate",
    "reviews": [],
    "interviews": 0,
    "educationLevel": "High school diploma",
    "uid": "ec79a755ce7646fcbc71",
    "areaOfStudy": "Sales and Marketing",
    "activeDuration": 2714743375622
},
{
    "error": "Cannot destructure property 'interview' of 'category' as it is undefined.",
    "codingLevel": "",
    "real": "real"
},
{
    "interviewTypes": [
        "Behavioral Interview",
        "Technical Interview",
        "Coding Interview",
        "System Design"
    ],
    "userId": "f51850ccaa0347579d65",
    "currentJob": "Software Developer",
    "reviews": [],
    "camera": true,
    "persona": true,
    "timeZone": "America/New_York",
    "yoe": 3,
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "hour": [],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ],
            "day": "Sun"
        }
    ],
    "displayName": "peach wren",
    "educationLevel": "Bachelor degree",
    "currentCompany": "Google",
    "interview": true,
    "platform": "peerfect2023",
    "areaOfStudy": "Computer Science",
    "currentPosition": "Software Developer",
    "uid": "f51850ccaa0347579d65",
    "rating": {
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0,
        "rating": 0
    },
    "active": true,
    "targetRole": "Software Engineer",
    "isEntryLevel": false,
    "university": "University of California, Berkeley",
    "codingDifficulty": 4,
    "skills": [
        "Java",
        "Python",
        "SQL",
        "JavaScript"
    ],
    "codingLevel": "",
    "id": "f51850ccaa0347579d65",
    "activeDuration": 2714743375622,
    "profile": true,
    "previousCodingProblems": [],
    "school": "University of California, Berkeley",
    "interviews": 0,
    "category": "Professional, Scientific, and Technical Services",
    "bootCampGraduate": false,
    "subCategory": "Research and Development"
},
{
    "bootCampGraduate": false,
    "platform": "peerfect2023",
    "availability": [
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Mon"
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:30 PM"
                },
                {
                    "HourFormated": " 04:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:30 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 05:00 PM",
                    "clicked": true
                }
            ]
        }
    ],
    "uid": "2f09a0f2d27242c6bbe8",
    "interviews": 0,
    "yoe": 3,
    "active": true,
    "targetRole": "Product Manager",
    "educationLevel": "Master degree",
    "displayName": "coffee ox",
    "timeZone": "America/New_York",
    "currentJob": "Product Analyst",
    "id": "2f09a0f2d27242c6bbe8",
    "camera": true,
    "subCategory": "Product Management",
    "areaOfStudy": "Business Administration",
    "reviews": [],
    "interview": true,
    "codingDifficulty": 4,
    "skills": [
        "Product development",
        "Market research",
        "Project management"
    ],
    "currentCompany": "Google",
    "interviewTypes": [
        "General Interview"
    ],
    "category": "Marketing, Sales, and Customer Service",
    "userId": "2f09a0f2d27242c6bbe8",
    "university": "Stanford University",
    "codingLevel": "",
    "rating": {
        "totalRatingNotDevided": 0,
        "rating": 0,
        "numberOfReviews": 0
    },
    "activeDuration": 2714743375622,
    "school": "Stanford University",
    "persona": true,
    "profile": true,
    "isEntryLevel": false,
    "previousCodingProblems": [],
    "currentPosition": "Product Analyst"
},
{
    "currentPosition": "Data Scientist",
    "currentJob": "Data Scientist",
    "reviews": [],
    "isEntryLevel": false,
    "areaOfStudy": "Computer Science",
    "previousCodingProblems": [],
    "activeDuration": 2714743375622,
    "bootCampGraduate": false,
    "yoe": 3,
    "displayName": "cyan moose",
    "educationLevel": "Master degree",
    "profile": true,
    "school": "Stanford University",
    "userId": "996eb37c9ad8427e82be",
    "id": "996eb37c9ad8427e82be",
    "timeZone": "America/New_York",
    "currentCompany": "Google",
    "codingLevel": "",
    "persona": true,
    "uid": "996eb37c9ad8427e82be",
    "subCategory": "Data Science",
    "skills": [
        "Machine Learning",
        "Python",
        "Deep Learning"
    ],
    "targetRole": "ML Engineer",
    "active": true,
    "interviewTypes": [
        "General Interview"
    ],
    "camera": true,
    "interviews": 0,
    "university": "Stanford University",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                }
            ],
            "day": "Fri"
        },
        {
            "day": "Sat",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 07:00 PM"
                }
            ],
            "day": "Sun"
        }
    ],
    "codingDifficulty": 4,
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "interview": true,
    "platform": "peerfect2023"
},
{
    "timeZone": "America/New_York",
    "skills": [
        "Troubleshooting technical issues",
        "Customer service",
        "Knowledge of gaming systems",
        "Communication skills"
    ],
    "availability": [
        {
            "day": "Mon",
            "hour": []
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Wed"
        },
        {
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 02:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 04:00 PM"
                }
            ],
            "day": "Thu"
        },
        {
            "day": "Fri",
            "hour": []
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "HourFormated": " 10:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Sun",
            "hour": []
        }
    ],
    "interview": true,
    "category": "Communications, Media, and Information Technology",
    "areaOfStudy": "Computer Science",
    "interviews": 0,
    "bootCampGraduate": false,
    "targetRole": "Helpdesk Game Support",
    "codingDifficulty": 4,
    "subCategory": "IT Support",
    "active": true,
    "userId": "163942f78bb84dd387d1",
    "codingLevel": "",
    "interviewTypes": [
        "General Interview"
    ],
    "school": "University of California, Berkeley",
    "currentJob": "Technical Support Specialist",
    "persona": true,
    "currentPosition": "Technical Support Specialist",
    "educationLevel": "Bachelor degree",
    "id": "163942f78bb84dd387d1",
    "currentCompany": "EA Sports",
    "university": "University of California, Berkeley",
    "displayName": "green scallop",
    "uid": "163942f78bb84dd387d1",
    "platform": "peerfect2023",
    "profile": true,
    "isEntryLevel": false,
    "activeDuration": 2714743375622,
    "reviews": [],
    "camera": true,
    "yoe": 3,
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    },
    "previousCodingProblems": []
},
{
    "currentPosition": "Retail Associate",
    "category": "Marketing, Sales, and Customer Service",
    "active": true,
    "areaOfStudy": "Marketing",
    "displayName": "peach rat",
    "targetRole": "Sales Representative",
    "skills": [],
    "yoe": 2,
    "persona": true,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": []
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 09:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 12:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:30 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Fri",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ]
        },
        {
            "day": "Sat",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Sun"
        }
    ],
    "currentJob": "Retail Associate",
    "timeZone": "America/New_York",
    "currentCompany": "Walmart",
    "userId": "5d5c4b2c317246e39c58",
    "platform": "peerfect2023",
    "codingDifficulty": 4,
    "profile": true,
    "bootCampGraduate": false,
    "university": "University of Texas at Austin",
    "activeDuration": 2714743375622,
    "educationLevel": "Bachelor degree",
    "interview": false,
    "interviews": 0,
    "previousCodingProblems": [],
    "codingLevel": "",
    "reviews": [],
    "uid": "5d5c4b2c317246e39c58",
    "interviewTypes": [
        "General Interview"
    ],
    "rating": {
        "numberOfReviews": 0,
        "rating": 0,
        "totalRatingNotDevided": 0
    },
    "subCategory": "Sales",
    "isEntryLevel": false,
    "id": "5d5c4b2c317246e39c58",
    "camera": true,
    "school": "University of Texas at Austin"
},
{
    "platform": "peerfect2023",
    "currentJob": "Structural Engineer",
    "id": "d414db7f9d42491784a9",
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "HourFormated": " 09:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 11:30 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Tue",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 01:30 PM"
                },
                {
                    "HourFormated": " 03:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "day": "Wed",
            "hour": [
                {
                    "HourFormated": " 10:30 AM",
                    "clicked": true
                },
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                }
            ]
        },
        {
            "day": "Thu",
            "hour": []
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 11:00 AM"
                },
                {
                    "HourFormated": " 01:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ],
            "day": "Fri"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 10:30 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:30 PM"
                }
            ],
            "day": "Sat"
        }
    ],
    "persona": true,
    "previousCodingProblems": [],
    "codingLevel": "",
    "category": "Architecture, Engineering, and Construction",
    "timeZone": "America/New_York",
    "currentPosition": "Structural Engineer",
    "reviews": [],
    "educationLevel": "Bachelor degree",
    "codingDifficulty": 4,
    "uid": "d414db7f9d42491784a9",
    "bootCampGraduate": false,
    "userId": "d414db7f9d42491784a9",
    "targetRole": "Civil Engineer",
    "areaOfStudy": "Civil Engineering",
    "skills": [
        "AutoCAD",
        "Structural Analysis",
        "Construction Management"
    ],
    "isEntryLevel": false,
    "displayName": "black unicorn",
    "currentCompany": "Jacobs Engineering Group Inc.",
    "interviewTypes": [
        "General Interview"
    ],
    "subCategory": "Civil Engineering",
    "profile": true,
    "interviews": 0,
    "interview": true,
    "camera": true,
    "school": "University of California, Berkeley",
    "yoe": 5,
    "university": "University of California, Berkeley",
    "activeDuration": 2714743375622,
    "active": true,
    "rating": {
        "rating": 0,
        "numberOfReviews": 0,
        "totalRatingNotDevided": 0
    }
},
{
    "displayName": "magenta reptile",
    "codingLevel": "",
    "subCategory": "Mechanical Engineering",
    "uid": "1c6194ef7cc242a3bc78",
    "interviews": 0,
    "areaOfStudy": "Mechanical Engineering",
    "id": "1c6194ef7cc242a3bc78",
    "yoe": 3,
    "platform": "peerfect2023",
    "profile": true,
    "skills": [
        "CAD software proficiency",
        "Thermodynamics knowledge",
        "Problem-solving skills"
    ],
    "persona": true,
    "previousCodingProblems": [],
    "activeDuration": 2714743375622,
    "availability": [
        {
            "day": "Mon",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 09:00 AM"
                },
                {
                    "HourFormated": " 02:00 PM",
                    "clicked": true
                },
                {
                    "HourFormated": " 07:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 10:00 AM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 03:00 PM"
                }
            ],
            "day": "Tue"
        },
        {
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 01:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 06:00 PM"
                }
            ],
            "day": "Wed"
        },
        {
            "day": "Thu",
            "hour": [
                {
                    "HourFormated": " 11:00 AM",
                    "clicked": true
                },
                {
                    "HourFormated": " 04:00 PM",
                    "clicked": true
                }
            ]
        },
        {
            "hour": [],
            "day": "Fri"
        },
        {
            "hour": [],
            "day": "Sat"
        },
        {
            "day": "Sun",
            "hour": [
                {
                    "clicked": true,
                    "HourFormated": " 12:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 05:00 PM"
                },
                {
                    "clicked": true,
                    "HourFormated": " 08:00 PM"
                }
            ]
        }
    ],
    "currentPosition": "Mechanical Technician",
    "interviewTypes": [
        "General Interview"
    ],
    "category": "Architecture, Engineering, and Construction",
    "bootCampGraduate": false,
    "currentCompany": "General Electric",
    "camera": true,
    "timeZone": "America/New_York",
    "interview": true,
    "active": true,
    "educationLevel": "Bachelor degree",
    "isEntryLevel": false,
    "currentJob": "Mechanical Technician",
    "targetRole": "Mechanical Engineer",
    "reviews": [],
    "userId": "1c6194ef7cc242a3bc78",
    "university": "University of California, Berkeley",
    "codingDifficulty": 4,
    "school": "University of California, Berkeley",
    "rating": {
        "totalRatingNotDevided": 0,
        "numberOfReviews": 0,
        "rating": 0
    }
}

  ]


export default users