import React,{useState,useEffect} from 'react';
import "./footer.css"
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const [showFooter, setshowFooter] = useState(true);

  const checkIfuserIsIn = () => {
    if (location.pathname === "/login" || location.pathname === "/signup"|| location.pathname.substring(0,5) === "/meet") {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
   
    setshowFooter(checkIfuserIsIn);
  }, [location]);
    return (
      <>
      {showFooter &&(
        <div className='footer'>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <rect width="1440" height="320" fill="#f5f6fa"></rect>
  <path fill="#4960a7"  fillOpacity="1" d="M0,96L48,133.3C96,171,192,245,288,266.7C384,288,480,256,576,224C672,192,768,160,864,138.7C960,117,1056,107,1152,128C1248,149,1344,203,1392,229.3L1440,256L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg>

<footer className="footer-main">
    {/* <ul className="social-icon">
      <li className="social-icon__item"><a className="social-icon__link" href="#">
          <ion-icon name="logo-facebook"></ion-icon>
        </a></li>
      <li className="social-icon__item"><a className="social-icon__link" href="#">
          <ion-icon name="logo-twitter"></ion-icon>
        </a></li>
      <li className="social-icon__item"><a className="social-icon__link" href="#">
          <ion-icon name="logo-linkedin"></ion-icon>
        </a></li>
      <li className="social-icon__item"><a className="social-icon__link" href="#">
          <ion-icon name="logo-instagram"></ion-icon>
        </a></li>
    </ul> */}
    <ul className="menu">
 
<br/>
      <li className="menu__item"><Link className="menu__link" to="/">Home</Link></li>
      <li className="menu__item"><Link className="menu__link" to="/interviews">interviews</Link></li>
      <li className="menu__item"><Link className="menu__link" to="/coverletter">coverLetter Generator</Link></li>
      <li className="menu__item"><Link className="menu__link" to="/questions">Question List</Link></li>
      <li className="menu__item"> <a className="menu__link" href="mailto:hello@peerfect.net">Email Us</a>
</li>
      {/* <h1 className="menu__item">hello@peerfect.net</h1> */}
                    
                    {/* <h2>Contact</h2> */}
                    
                   

    </ul>
  <p>
  If you have any suggestions, bugs, or any questions please reach us by email: <a href="mailto:hello@peerfect.net" style={{color:'white'}}>hello@peerfect.net</a><br/>    </p>
    <p>&copy; {new Date().getFullYear()} Peerfect. All rights reserved. </p>
  </footer>
        </div>
      ) }
      
      </>

    );
}

export default Footer;
