import { createContext, useEffect, useReducer } from "react";
import { auth } from "../firebase/config";

export const AuthContext = createContext();

export const authReducer = (state, action)=>{
    switch (action.type) {
        case 'LOGIN':
            return {...state, user:action.payload,}
        case 'LOGOUT':
            return {...state, user:null,userData:null}
        case 'AUTH_IS_READY':
            return {...state, user:action.payload, authIsReady:true}
          case 'profile':
        //  console.log(action.payload)
          return {...state,userData:action.payload.userData,privateData:action.payload.privateData}    
          case 'update':
            const {dataType,property,value} =action.payload
            let obj = {...state[dataType] ,
              [property]:[...state[dataType][property],value]
            }
            return {...state,[dataType]:obj}
        default:
            return state
    }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady:false
  });


  useEffect(()=>{
    const unsub = auth.onAuthStateChanged((user)=>{
      dispatch({type: "AUTH_IS_READY", payload: user })
      unsub()
    })
  },[])

  console.log('AuthContext state:', state)


  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
