import React, { useEffect, useState } from "react";

// import { collection, serverTimestamp,doc, updateDoc ,arrayUnion} from "firebase/firestore";
// import { db } from '../firebase/config';
import Tooltip from "@mui/material/Tooltip";
import NotificationList from "./NotificationList";

import { useAuthContext } from "../../hooks/useAuthContext";

import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

//snack
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./notification.css"


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Notification = ({document}) => {
  console.log("🚀 ~ Notification ~ document:", document)
  const { user } = useAuthContext();

  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };


  return (
    <div>
      {!document && (
        <Tooltip title="Notifications">
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="error"
          >
            <Stack spacing={10} direction="row" sx={{ color: "action.active" }}>
              <Badge color="error" badgeContent={0}>
                <NotificationsNoneIcon />
              </Badge>
            </Stack>
          </IconButton>
        </Tooltip>
      )}
  <div className="notifcation-conatiner">

      {document && <NotificationList documents={document} setOpenSnack={setOpenSnack} />}
  </div>

      <Snackbar
        // onClick={handleClick}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right' }}
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity="info"
          sx={{ width: "100%" }}
        >
          You have received a new notification
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Notification;
