import mixpanel from 'mixpanel-browser';
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

const MIXPANEL_CUSTOM_LIB_URL = "https://mixpanel-api-proxy-8viwi.ondigitalocean.app/";


mixpanel.init(window.location.hostname === 'localhost'?"6e361898acb09a969237007c5bda14f8":'aa3a9ba11981fb81be5d707aba7cc5e3', {debug: window.location.hostname === 'localhost',track_pageview: "url-with-path",api_host: MIXPANEL_CUSTOM_LIB_URL}); 
// mixpanel.init('aa3a9ba11981fb81be5d707aba7cc5e3', {debug: window.location.hostname === 'localhost',track_pageview: "url-with-path"}); 
mixpanel.track('Page View', {
    'url': window.location.pathname,
    'referrer': document.referrer
  });
  

export {mixpanel}