import React,{useState,useEffect} from 'react';
import { Button, Container, TextField, Typography,Box } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Slider from '@mui/material/Slider';

import jobList from "assets/jobList"
const CurrentJob = ({user,setUser,handleNext,loading,handleSubmit,setIsStudent,isStudent}) => {
    let text = "software developer"
    let delay = 100
    let infinite  = true
    const [placeHolder,setPlaceHolder] =useState('')
    const [currentText, setCurrentText] = useState('');
    const filter = createFilterOptions();
    const  [words,setWords] = useState([ "Software developer     ",
    "Data scientist     ",
    "UI designer     ",
    "Product Manager     ",
    "Doctor     ",
    "Lawyer     ",
    "Engineer     ",
    "Teacher     ",
    "Nurse     ",
    "Pilot    "])
     
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex < words[currentWordIndex].length) {
        setCurrentWord(prevWord => prevWord + words[currentWordIndex][currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      } else {
        setCurrentIndex(0);
        setCurrentWord("");
        setCurrentWordIndex(prevIndex => (prevIndex === words.length - 1 ? 0 : prevIndex + 1));
      }
    }, delay);

    return () => clearInterval(timer);
  }, [currentIndex, currentWordIndex, delay, words]);


  function valuetext(value) {
    return `${value}`;
}

const marks = [
  { value: 1, label: '1' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
];

    return (
        <>
                    
                    <Typography variant="h3" gutterBottom sx={{ fontSize: "40px", textAlign: "center", marginBottom: "20px" }}>
      What is your current Job   
      </Typography>

            <Autocomplete
            
            sx={{ mx: 'auto', width: '100%', maxWidth: 450 }}
        
      value={user.currentPosition}
      
      onInputChange={(event, newValue) => {
       setUser({ ...user, currentPosition: newValue })
       if(isStudent)setIsStudent(false)

      }}
      onChange={(event, newValue) => {
       setUser({ ...user, currentPosition: newValue.inputValue || newValue.title })
       if(isStudent)setIsStudent(false)
       handleNext()
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        // console.log("🚀isExisting:", isExisting)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      freeSolo
      handleHomeEndKeys
      id="target-role"
      options={jobList}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      variant="standard" 
      
      
      renderInput={(params) => (
        <TextField   {...params} value={user.role}  placeholder={currentWord} label="" variant="standard"   helperText="Type your most recent job title"
        sx={{
          '.MuiInputBase-input': { fontSize: 24 },
          '& .MuiAutocomplete-inputRoot': {
              minWidth: 375,
              borderColor: '#ccc',
              borderRadius: '5px',
          },
      }}
        />
        
      )}
    />
    
            <Typography variant="h3" gutterBottom sx={{ fontSize: "40px", mt: 5 }}>
                Years of Experience
            </Typography>

            <Box sx={{ width: '100%' }}>
                <Slider
                    aria-label="Years of Experience"
                    // defaultValue={user.yoe}
                    value={user.yoe}
                    getAriaValueText={valuetext}
                    step={1}
                    marks={marks}
                    min={1}
                    max={20}
                    valueLabelDisplay="auto"
                    onChange={(e,n)=>setUser({ ...user,yoe:n})}
                />
            </Box>
        </>
    );
}

export default CurrentJob;
