import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Steps } from 'intro.js-react';
import { useAuthContext } from "hooks/useAuthContext";
import { useUpdate } from "hooks/useUpdate";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

const InterviewCard = ({ row, index, documents, cancelInterview ,startInterview,StyledTableCell})=> {
    const [activeButton, setActiveButton] = useState(Date.now() / 1000);
    const { authIsReady, user,dispatch,userData,privateData } = useAuthContext();
    const {update,setloading} = useUpdate("privateUsers")

    useEffect(() => {
      setInterval(() => {
        const time = Date.now() ;
        // console.log(row)
        // console.log(time)
        // if(row.time.timestamp<= time){
        setActiveButton(time);
        // }
      }, 10000);
    }, []);
  
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
    const navigate = useNavigate();
  
        
    const [enabled,setEnabled] = useState(!privateData.interviewPageTutorial);
    const [initialStep,setInitialStep] = useState(0);
  
    const onExit = () => {
        if(enabled){
          setEnabled(false)
          update(user.uid,{interviewPageTutorial:true})
          dispatch({ type: "profile", payload: {privateData:{...privateData,interviewPageTutorial:true},userData} });
        }
    }

    const steps = [
        {
            element: '#interviewContainer',
            intro: 'Congratulation for getting your first interview Scheduled, Use this table to track future interviews',
            position: 'right',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
          element: '#questionInterview',
          intro: 'We recommand to practice the questions you are going to ask your peer by clicking here',
      },
        {
          element: '.interviewStartTime',
          intro: 'Interview start button will appear 5 minutes before the interview time',
      },
    ];
    return (
      <>
        <Steps
              enabled={enabled}
              steps={steps}
              initialStep={initialStep}
              onExit={onExit}
              options={{ hideNext: false }} 
            />
        <StyledTableRow >
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
         <Link to={`/profile/${row.userLink}`}> {row.peerName}</Link>  
          </StyledTableCell>
          <StyledTableCell component="th" scope="row" align="center">
            <p >{row.role}</p>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row" align="center">
            <p >{row.when}</p>
          </StyledTableCell>
  
          <StyledTableCell align="center">{row.InterviewType}</StyledTableCell>
          <StyledTableCell align="center" id={index === 0?"interview-questions":""}>

            {row.InterviewType === "coding Interview" ? (
              <>
             { row.codingProblem ?<Typography variant="body2" gutterBottom>
            <Link to={`/practice/${row.roomId}`} id="questionInterview">  {row.codingProblem.title}</Link> 
             </Typography>:<Typography variant="body2" gutterBottom id="questionInterview">Question is been generated...</Typography>}
              </>
    
            ) : (
              <div
                onClick={() => setOpen(!open)}
                style={{ cursor: "pointer", color: "#4960A7" }}
                id="questionInterview"
              >
                Question List
              </div>
            )}
          </StyledTableCell>
          <StyledTableCell align="center">
            {row.topic}
          </StyledTableCell>
          <StyledTableCell align="right" >
            <div id={index === 0?"interview-start":""} className="interviewStartTime">
              <div >
                {/* <a href={documents[index].meetingPassword} target="_blank" rel="noreferrer" > */}
                {(activeButton >= row.time || import.meta.env.DEV) ? (
                  <Button
                    variant="contained"
                    onClick={()=>startInterview(row)}
                  >
                    Start
                  </Button>
                ) : (
                  <Tooltip title="The button will became available when it is the time of interview">
                    <button
                      style={{
                        width: 87,
                        height: 36,
                        fontSize: 17,
                        cursor: "pointer",
                        color: "gray",
                      }}
                      variant="contained"
                    >
                      Start
                    </button>
                  </Tooltip>
                )}
  
                {/* </a> */}
              </div>
  
              <Button variant="outlined" onClick={(e) => cancelInterview(index)}>
                Cancel
              </Button>
            </div>
          </StyledTableCell>
        </StyledTableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {row.InterviewType === "coding Interview" ? (
                  <div>
                      <Typography variant="body2" gutterBottom>
                    {row.interviewDetails}
                      </Typography>
                  </div>
                ) : (
                  <div>
  
                  {/* {console.log(row)} */}
                  <ul>
                  {row.questions.length ===0 &&(
                    <>
                    questions are being generated...
                    </>
                  )}
                  {row.questions?.map((question) => (
                      <li key={question.question}> 
                         <Typography variant="body2" gutterBottom>
                        {question.question}
                      </Typography>
                      </li>
                      
                    ))}
                    </ul>
                </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
}

export default InterviewCard;
