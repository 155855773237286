import React from 'react';
import styles from "./pastInterview.module.css";
import { Link } from 'react-router-dom';

const PastInterview = ({ interview, user }) => {
    const { time } = interview.users.find((element) => element.id === user.uid);
    const { id, name } = interview.users.find((element) => element.id !== user.uid);

    const feedbackExists = interview[id];
    const feeBackWritten = !interview[user.uid]
    return (
        <div className={styles.interviewCard}>
            <div className={styles.interviewHeader}>
                <div className={styles.role}>{interview.role.toUpperCase()}</div>
                <div className={styles.type}>{interview.interviewType}</div>
                <div className={styles.time}>{time}</div>
            </div>
            <div className={styles.peerInfo}>
              { !interview[id] && <div className={styles.peerDetails}>
                    <div className={styles.name}><Link to={`/profile/${id}`}> {name}</Link>
                       </div>
                </div>}
            </div>
            {feedbackExists && <h3 className={styles.title}>Peer Feedback</h3>}
            {feedbackExists && (
                <div className={styles.feedback}>
                     <div className={styles.name}>Peer: <Link to={`/profile/${id}`}> {name}</Link></div>
                    <div className={styles.rating}>Rating: {interview[id].rating} out of 5</div>
                    <div className={styles.text}>Feedback: <br/>{interview[id].text}</div>
                </div>
            ) }
        {feeBackWritten &&   <Link  to={`/review/${interview.id}`}><button className={styles.leaveFeedbackButton}>Leave Feedback</button></Link>}
        </div>
    );
}

export default PastInterview;
