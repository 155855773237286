import React,{useEffect,useState} from 'react'
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText'; 
import jobList from 'assets/jobList' 
import skillList from 'assets/skillsList.js' 
import Autocomplete from '@mui/material/Autocomplete';
export default function UserInterviewDetails({profile,setrequestInfo,requestInfo}) {
  console.log("🚀 ~ file: UserInterviewDetails.js:11 ~ jobList:", jobList)

  const [topicList,setTopicList]=useState([])


    console.log(profile,'inside interview details')

   
    
  return (
    <div>
         <Box sx={{display:"flex",flexDirection: 'column'}}>

      



     { requestInfo.interviewType === 'Technical Interview' &&(
      <>
  
              <Autocomplete
              clearOnBlur={false}
         sx={{ m: 1,mb:1,mt:2 }}
        id="free-solo-demo"
        freeSolo
        value={requestInfo.topic}
        onChange={(e,value) => setrequestInfo({ ...requestInfo, topic: value })}
        options={skillList}
        renderInput={(params) => <TextField {...params} label="Choose a topic for the interview (optional)" placeholder='frontend, backend, react ...'/>}
      />
        
            <FormHelperText sx={{ml:1}}>
            If you chose a topic the whole interview will be about that specif skill, and the questions generated will be about it, else it will be general technical mock interview
                  </FormHelperText>
      </>
     )}

          { requestInfo.interviewType === 'coding Interview' &&(
      //         <Autocomplete
      //    sx={{ m: 1,mb:1,mt:2 }}
      //   id="free-solo-demo"
      //   freeSolo
      //   value={requestInfo.topic}
      //   onChange={(e,value) => setrequestInfo({ ...requestInfo, topic: value })}
      //   options={skillList}
      //   renderInput={(params) => <TextField {...params } label="choose coding language" helperText="At this time, our platform support only two languages python and javascript"/>}
      // />
      <>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>

       <InputLabel id="demo-simple-select-helper-label">coding Language</InputLabel>
      <Select
                onChange={(e) => setrequestInfo({ ...requestInfo, codingLanguage: e.target.value })}
                labelId="coding Language"
                id="coding Language"
                value={requestInfo.framework}
                autoWidth
                label="coding Language"
              
                >
              <MenuItem value={"Javascript"}>Javascript</MenuItem>
          <MenuItem value={"Python"}>Python</MenuItem>
              </Select>
            <FormHelperText sx={{ml:1}}>
        At this time, our platform support only two languages python and javascript
                  </FormHelperText>
                  </FormControl> */}

      </>
     )}
     
                  <TextField sx={{ml:1,mt:1.5}}
        // value={user.targetCompany}
          id="Interview Details (optional)" 
          onChange={(e) => setrequestInfo({ ...requestInfo, interviewDetails: e.target.value })}
          label="Interview Details (optional)"
          //   defaultValue="Default Value"   
          />
           <FormHelperText sx={{ml:1}}>
            Add extra info about the interview that you think it is important for the other user to know
                  </FormHelperText>
     {/* Add Extra information about interview */}
                </Box>
    </div>
  )
}
