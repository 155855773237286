import { initializeApp } from "firebase/app";
import { useReducer, useEffect, useState } from "react";
import { collection, addDoc,serverTimestamp, deleteDoc,doc, setDoc  } from "firebase/firestore";
import { db } from "../firebase/config";
let initialState = {
  document: null,
  isPending: null,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "Is_Pending":
      return {  isPending: true, document:null, success:false, error:null };
    case "ADDED_DOCUMENT":
        return {isPending:false, document:action.payload, success:true, error:action.payload}
        case "DELETED_document":
            return {isPending:false, document:action.payload, success:true, error:null}
    case "ERROR":
        return {isPending:false, document:null, success:false, error:true}
    default:
      return state;
  }
};
export const useFireStore = (collectioNname) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const ref = collection(db, collectioNname);

    const dispatchIfNotCancelled = (action)=>{
        if (!isCancelled){
            dispatch(action)
        }
    }

  //add document
  const addDocument = async (documentToBeAdded,costumId) => {
    dispatch({ type: "Is_Pending" });
console.log('run')
    if(costumId){

      try{
        const addedDocument = await setDoc(doc(db, collectioNname,costumId), {...documentToBeAdded,createdAt:serverTimestamp()});
        dispatchIfNotCancelled({type:'ADDED_DOCUMENT',payload: addedDocument})
        console.log(addedDocument)
        console.log('worked')
      }
      catch (err) {
        console.log('something went wrong')
        dispatchIfNotCancelled({type:'ERROR',payload: err.message})
        console.log('not worked')
        console.log(err)
    }
    console.log('exit')
      return 
    }
    console.log('try')
    try {
      const refCostum = collection(db, collectioNname);

      const addedDocument = await addDoc(refCostum, {...documentToBeAdded,createdAt:serverTimestamp()});
      dispatchIfNotCancelled({type:'ADDED_DOCUMENT',payload: addedDocument})
      // console.log("success")
      // console.log(addedDocument)
      return true
    } 
    catch (err) {
        dispatchIfNotCancelled({type:'ERROR',payload: err.message})
        console.log(err)
      return false
    }
  };
  
  const deleteDocument = async (id) => {
    dispatch({type:'IS_PENDING'})

    try{
      const ref = doc(db,collectioNname,id)
     const deletedDocument = await deleteDoc(ref)
    //  console.log('document deleted')
     dispatchIfNotCancelled({type:'DELETED_document',payload: deletedDocument})
    }
    catch(err){
      // console.log('document not deleted')
        dispatchIfNotCancelled({type:"ERROR",payload:err.message})
    }
  };

  useEffect(() => {
    return () => {
      setIsCancelled(true);
    };
  }, []);

  return { addDocument, deleteDocument, response };
};
