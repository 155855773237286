let names =  [
  'orange parrotfish', 'amethyst starfish', 'turquoise llama', 'bronze giraffe',
  'salmon snake', 'ivory zebra', 'violet orangutan', 'purple hornet', 'harlequin llama',
  'salmon tapir', 'tan antelope', 'purple leech', 'purple lobster', 'aqua wombat',
  'scarlet squid', 'moccasin anglerfish', 'violet iguana', 'lavender amphibian',
  'azure bass', 'jade stork', 'coral chimpanzee', 'jade penguin', 'lavender seahorse',
  'white stork', 'sapphire hyena', 'indigo crayfish', 'amethyst viper', 'turquoise kiwi',
  'plum cicada', 'maroon peafowl', 'beige smelt', 'black wren', 'rose kiwi', 'gray porpoise',
  'ivory booby', 'aquamarine piranha', 'moccasin felidae', 'coffee haddock', 'rose hippopotamus',
  'amaranth macaw', 'maroon worm', 'blue sawfish', 'coffee catfish', 'maroon harrier',
  'tomato warbler', 'silver guanaco', 'bronze lamprey', 'teal owl', 'amethyst bat',
  'purple dormouse', 'indigo pigeon', 'aqua meadowlark', 'fuchsia asp', 'lime elephant',
  'pink hippopotamus', 'maroon ladybug', 'lime puffin', 'chocolate weasel', 'olive marlin',
  'olive raccoon', 'lime cattle', 'green tuna', 'blush panther', 'gray owl', 'lime falcon',
  'lavender rhinoceros', 'yellow gerbil', 'apricot goose', 'turquoise mastodon',
  'cyan peafowl', 'lavender crayfish', 'emerald chinchilla', 'copper wren', 'beige porpoise',
  'chocolate puma', 'aqua pike', 'crimson quail', 'emerald donkey', 'purple frog',
  'teal skunk', 'fuchsia baboon', 'turquoise sloth', 'coffee roundworm', 'yellow bison',
  'turquoise bird', 'silver gull', 'magenta horse', 'olive parakeet', 'jade vicuna',
  'chocolate beetle', 'magenta ox', 'jade dingo', 'azure wolverine', 'olive alpaca',
  'teal rooster', 'magenta skunk', 'peach cod', 'fuchsia sheep', 'plum seahorse',
  'gray hare', 'salmon amphibian', 'amber tern', 'aquamarine crab', 'crimson tiger',
  'lime damselfly', 'lavender hookworm', 'teal flyingfish', 'maroon whale', 'jade panda',
  'tan urial', 'pink scallop', 'jade koala', 'lavender vole', 'coral pheasant', 'beige felidae',
  'amethyst gayal', 'orange orangutan', 'scarlet swallow', 'aquamarine whippet',
  'coffee wolf', 'indigo krill', 'cyan hummingbird', 'magenta salmon', 'orange porcupine',
  'purple reindeer', 'aqua finch', 'beige parrotfish', 'aqua sloth', 'peach puma',
  'aqua mink', 'chocolate hare', 'jade urial', 'chocolate cricket', 'chocolate roadrunner',
  'amber wildebeest', 'apricot mandrill', 'amaranth jellyfish', 'aquamarine raccoon',
  'jade whippet', 'bronze mackerel', 'red damselfly', 'gray planarian', 'lavender hornet',
  'pink ocelot', 'coffee squirrel', 'purple rodent', 'ivory planarian', 'coffee chicken',
  'white guan', 'red marsupial', 'rose alligator', 'scarlet dragon', 'magenta crow',
  'black damselfly', 'beige ant', 'crimson elephant', 'cyan cod', 'beige coral',
  'emerald egret', 'magenta monkey', 'tan mockingbird', 'fuchsia squid', 'olive finch',
  'teal hummingbird', 'red mockingbird', 'gold hamster', 'blue weasel', 'fuchsia ape',
  'cyan pelican', 'apricot felidae', 'bronze panda', 'bronze mite', 'jade whale',
  'harlequin amphibian', 'apricot scallop', 'cyan perch', 'coffee cow', 'cyan duck',
  'amber catfish', 'yellow possum', 'orange llama', 'aqua shrimp', 'orange marmoset',
  'emerald donkey', 'chocolate planarian', 'scarlet crawdad', 'cyan booby', 'purple goat',
  'yellow meerkat', 'ivory thrush', 'gold ferret', 'blush chickadee', 'pink fowl',
  'orange puma', 'silver mosquito', 'sapphire hawk', 'magenta echidna', 'purple carp',
  'aqua catfish', 'gold wolf', 'silver halibut', 'violet booby', 'coffee bonobo',
  'maroon squid', 'scarlet whippet', 'gold mink', 'amaranth roadrunner', 'maroon marten',
  'magenta hyena', 'copper marmoset', 'turquoise mouse', 'aqua walrus', 'pink angelfish',
  'emerald felidae', 'gray fox', 'cyan tarsier', 'ivory warbler', 'violet rhinoceros',
  'plum mastodon', 'chocolate puffin', 'salmon lungfish', 'maroon tiger', 'gray magpie',
  'tan gorilla', 'gold shrimp', 'scarlet aardwolf', 'teal sailfish', 'copper flyingfish',
  'violet beetle', 'lavender bobcat', 'peach hummingbird', 'fuchsia cuckoo', 'cyan snake',
  'orange catfish', 'indigo fly', 'sapphire stingray', 'silver bandicoot', 'orange parakeet',
  'indigo wallaby', 'tan sloth', 'amethyst canidae', 'crimson guanaco', 'coffee squirrel',
  'sapphire constrictor', 'amaranth raven', 'blue kingfisher', 'purple silverfish',
  'aquamarine quelea', 'amethyst guan', 'pink spoonbill', 'bronze coyote', 'tomato tortoise',
  'coffee cod', 'beige octopus', 'orange marlin', 'indigo cicada', 'red capybara',
  'crimson orangutan', 'beige jaguar', 'amaranth stingray', 'sapphire barnacle',
  'moccasin anaconda', 'beige anglerfish', 'amethyst lungfish', 'gray stingray',
  'moccasin urial', 'coral tern', 'ivory crab', 'coral tarantula', 'olive dog', 'brown penguin',
  'chocolate vulture', 'turquoise caribou', 'lime xerinae', 'lime impala', 'scarlet duck',
  'harlequin whale', 'crimson wasp', 'indigo bass', 'turquoise orca', 'tan cicada',
  'amaranth rook', 'amber kingfisher', 'rose xerinae', 'beige caterpillar', 'brown leopon',
  'jade reindeer', 'green coyote', 'tan trout', 'beige pike', 'aquamarine hare',
  'emerald elephant', 'harlequin elephant', 'beige ocelot', 'black worm', 'tomato spider',
  'magenta fowl', 'beige porcupine', 'aquamarine dinosaur', 'yellow giraffe', 'apricot monkey',
  'aqua perch', 'azure frog', 'turquoise weasel', 'moccasin damselfly', 'copper giraffe',
  'amaranth gazelle', 'aquamarine ptarmigan', 'coral shrimp', 'coral lion', 'purple ladybug',
  'amber beetle', 'gray sturgeon', 'azure beetle', 'maroon macaw', 'sapphire cow',
  'lime hawk', 'pink mink', 'scarlet hedgehog', 'green planarian', 'crimson chipmunk',
  'copper reindeer', 'white catshark', 'lime aphid', 'bronze fish', 'fuchsia rhinoceros',
  'scarlet vicuna', 'indigo bedbug', 'silver narwhal', 'copper chameleon', 'chocolate wolverine',
  'plum salamander', 'brown reindeer', 'amber earwig', 'blush mollusk', 'red lion',
  'ivory shark', 'brown scallop', 'indigo amphibian', 'chocolate cow', 'scarlet limpet',
  'moccasin finch', 'tan rattlesnake', 'coral roadrunner', 'gold ferret', 'indigo termite',
  'bronze puffin', 'harlequin cow', 'indigo reptile', 'rose gorilla', 'amber mite',
  'fuchsia catshark', 'azure nightingale', 'maroon turkey', 'blush hummingbird',
  'coral cod', 'beige gull', 'silver catshark', 'gold camel', 'black swordtail',
  'scarlet earthworm', 'lavender chameleon', 'apricot ermine', 'peach horse', 'gray pheasant',
  'maroon prawn', 'brown tiglon', 'gold antelope', 'chocolate starfish', 'teal piranha',
  'chocolate dragon', 'moccasin reindeer', 'apricot gamefowl', 'olive chameleon',
  'cyan peafowl', 'teal wildfowl', 'violet booby', 'apricot quokka', 'plum partridge',
  'gold gull', 'silver bird', 'cyan bat', 'amaranth mongoose', 'plum swordfish',
  'olive elephant', 'red ant', 'aquamarine mule', 'fuchsia raccoon', 'emerald slug',
  'aquamarine eagle', 'pink starfish', 'scarlet canidae', 'indigo chinchilla', 'amber lizard',
  'brown grouse', 'moccasin viper', 'ivory lobster', 'magenta toucan', 'gold cricket',
  'azure nightingale', 'red lemming', 'pink possum', 'moccasin anglerfish', 'purple boa',
  'indigo squid', 'rose bass', 'fuchsia lobster', 'teal dog', 'jade caribou', 'olive deer',
  'maroon damselfly', 'crimson roundworm', 'aqua reptile', 'olive earwig', 'lime dingo',
  'ivory hippopotamus', 'bronze marsupial', 'magenta scallop', 'amaranth mole', 'scarlet haddock',
  'azure mite', 'gold lion', 'bronze coral', 'aquamarine beaver', 'jade bass', 'orange goldfish',
  'green toucan', 'harlequin pigeon', 'salmon egret', 'tan crocodile', 'lime silverfish',
  'teal snake', 'pink clam', 'amber bear', 'moccasin ptarmigan', 'aquamarine lamprey',
  'apricot bonobo', 'harlequin cattle', 'moccasin lungfish', 'pink leopard', 'ivory bobolink',
  'emerald bird', 'aqua aardvark', 'harlequin toad', 'aqua seahorse', 'jade aardwolf',
  'fuchsia bee', 'copper constrictor', 'peach wasp', 'teal quail', 'amethyst wildcat',
  'purple dolphin', 'yellow caribou', 'red sloth', 'tomato mink', 'olive gibbon',
  'teal crocodile', 'azure bass', 'rose cockroach', 'amaranth deer', 'sapphire tick',
  'indigo lemming', 'violet silkworm', 'gold chickadee', 'bronze marmoset', 'scarlet leech',
  'moccasin tiglon', 'blue bee', 'green krill', 'indigo skink', 'pink crab', 'salmon guan',
  'aqua galliform', 'crimson roundworm', 'gold mosquito', 'white hedgehog', 'tan meadowlark',
  'bronze kingfisher', 'black junglefowl', 'coffee pike', 'purple wildebeest', 'coral swordtail',
  'lavender hummingbird', 'olive rat', 'blue cockroach', 'violet marmot', 'azure owl',
  'amethyst wolf', 'peach marsupial', 'azure sailfish', 'coffee bug', 'aqua jellyfish',
  'bronze moth', 'amethyst louse', 'beige chickadee', 'teal piranha', 'black stingray',
  'blush beaver', 'ivory mite', 'peach mole', 'gold slug', 'yellow frog', 'blush fish',
  'black mastodon', 'crimson alligator', 'cyan pinniped', 'amaranth clam', 'aqua deer',
  'pink chicken', 'pink wren', 'lavender penguin', 'salmon cat', 'amber rattlesnake',
  'amber gazelle', 'plum goat', 'black frog', 'indigo parakeet', 'brown jay', 'fuchsia mosquito',
  'pink gayal', 'coral slug', 'beige meadowlark', 'apricot beaver', 'coral bobolink',
  'lavender clam', 'peach moth', 'copper stingray', 'azure condor', 'orange nightingale',
  'coral sole', 'salmon deer', 'amethyst whitefish', 'olive krill', 'jade alligator',
  'peach wildcat', 'lavender heron', 'magenta hamster', 'cyan thrush', 'beige platypus',
  'orange anteater', 'beige ermine', 'moccasin vicuna', 'blue viper', 'silver badger',
  'amethyst dog', 'peach hedgehog', 'silver cockroach', 'olive hoverfly', 'emerald chameleon',
  'yellow amphibian', 'amber cod', 'moccasin sturgeon', 'fuchsia chimpanzee', 'scarlet nightingale',
  'sapphire sloth', 'orange newt', 'gold wildfowl', 'moccasin peacock', 'blue galliform',
  'pink peafowl', 'red fish', 'amethyst lark', 'moccasin lamprey', 'apricot catfish',
  'copper dog', 'olive primate', 'white gayal', 'copper fly', 'crimson angelfish',
  'coffee crayfish', 'orange ant', 'black hyena', 'yellow tahr', 'copper stoat',
  'coffee bat', 'teal centipede', 'turquoise emu', 'scarlet rook', 'apricot moth',
  'copper cow', 'apricot donkey', 'indigo pheasant', 'emerald rat', 'salmon mammal',
  'aquamarine prawn', 'ivory mollusk', 'lavender prawn', 'violet duck', 'cyan pheasant',
  'ivory perch', 'chocolate ape', 'aqua chinchilla', 'amber porcupine', 'scarlet hornet',
  'indigo mole', 'scarlet salmon', 'beige crow', 'ivory aardwolf', 'tan spoonbill',
  'beige salamander', 'violet anglerfish', 'lavender grasshopper', 'teal beetle',
  'rose clownfish', 'violet cuckoo', 'purple turkey', 'lavender bedbug', 'rose yak',
  'sapphire iguana', 'emerald ladybug', 'amethyst rhinoceros', 'red koala', 'harlequin mule',
  'white goldfish', 'violet porcupine', 'scarlet marmoset', 'chocolate mosquito',
  'teal llama', 'moccasin sawfish', 'pink egret', 'beige constrictor', 'azure elephant',
  'amethyst armadillo', 'emerald slug', 'moccasin tyrannosaurus', 'gold lamprey',
  'cyan haddock', 'purple alpaca', 'turquoise shrimp', 'purple snipe', 'sapphire mackerel',
  'ivory lark', 'tomato baboon', 'ivory quokka', 'white felidae', 'red mule', 'green orangutan',
  'harlequin walrus', 'moccasin marten', 'lime tiger', 'jade hornet', 'tomato mandrill',
  'sapphire prawn', 'moccasin duck', 'harlequin guppy', 'apricot bedbug', 'lime wren',
  'turquoise guppy', 'azure aardvark', 'turquoise silkworm', 'amber skunk', 'sapphire peacock',
  'amaranth marmoset', 'lime swan', 'harlequin junglefowl', 'olive orangutan', 'rose flamingo',
  'crimson beetle', 'teal armadillo', 'amethyst hoverfly', 'moccasin stoat', 'salmon salmon',
  'coral baboon', 'lavender piranha', 'violet tuna', 'jade xerinae', 'purple gibbon',
  'coffee cougar', 'amethyst prawn', 'sapphire guineafowl', 'bronze turkey', 'blue turkey',
  'lime wildfowl', 'bronze bobolink', 'emerald leopard', 'copper ladybug', 'fuchsia pike',
  'scarlet rhinoceros', 'crimson cod', 'apricot wolverine', 'jade toad', 'beige marlin',
  'violet dolphin', 'black worm', 'red catfish', 'fuchsia baboon', 'lavender perch',
  'gold fowl', 'maroon tuna', 'beige skink', 'aquamarine cat', 'lavender gorilla',
  'crimson tarantula', 'yellow walrus', 'harlequin halibut', 'copper zebra', 'amethyst trout',
  'pink dinosaur', 'black guineafowl', 'salmon tuna', 'amaranth lungfish', 'bronze viper',
  'brown chicken', 'pink swift', 'ivory octopus', 'violet eagle', 'gold sheep', 'blue locust',
  'ivory cougar', 'harlequin sturgeon', 'sapphire iguana', 'maroon donkey', 'scarlet opossum',
  'gold carp', 'coral dog', 'blue ostrich', 'cyan porcupine', 'amber muskox', 'brown grasshopper',
  'salmon elephant', 'plum pheasant', 'purple canid', 'violet goldfish', 'rose hoverfly',
  'olive pig', 'jade mandrill', 'turquoise chicken', 'green skunk', 'magenta grouse',
  'lavender tapir', 'amaranth gazelle', 'scarlet snake', 'red mouse', 'aqua slug',
  'salmon puffin', 'magenta tiglon', 'lime kingfisher', 'pink salamander', 'emerald swordtail',
  'ivory bat', 'red guppy', 'orange iguana', 'azure boa', 'aqua tiger', 'magenta wren',
  'sapphire bobcat', 'blue anteater', 'white barracuda', 'brown orangutan', 'yellow lynx',
  'violet impala', 'pink hare', 'aquamarine ermine', 'gold bear', 'emerald macaw',
  'aqua panther', 'beige bedbug', 'teal cockroach', 'white fly', 'pink tuna', 'blush squid',
  'violet lizard', 'gray lemur', 'lavender mockingbird', 'aquamarine impala', 'aqua sturgeon',
  'teal chipmunk', 'plum mastodon', 'orange marsupial', 'emerald scorpion', 'copper galliform',
  'sapphire dolphin', 'emerald damselfly', 'gray meerkat', 'ivory krill', 'moccasin cattle',
  'beige tyrannosaurus', 'beige echidna', 'salmon angelfish', 'olive chimpanzee',
  'green meadowlark', 'bronze falcon', 'salmon hoverfly', 'harlequin leopard', 'beige kingfisher',
  'indigo crane', 'bronze roundworm', 'pink wildcat', 'salmon catshark', 'lime tuna',
  'yellow mosquito', 'amaranth mule', 'cyan loon', 'chocolate mackerel', 'amethyst raven',
  'indigo gull', 'teal wolf', 'magenta lemur', 'violet wallaby', 'tomato guineafowl',
  'lime pony', 'amethyst bandicoot', 'emerald silkworm', 'yellow barracuda', 'blue damselfly',
  'green spoonbill', 'aquamarine urial', 'beige fowl', 'blush snail', 'white falcon',
  'scarlet harrier', 'brown koala', 'coffee rooster', 'copper dormouse', 'peach sole',
  'coral monkey', 'pink possum', 'coral leech', 'amber cat', 'green meerkat', 'salmon dove',
  'blush planarian', 'azure skink', 'emerald crayfish', 'orange narwhal', 'fuchsia booby',
  'amber falcon', 'fuchsia butterfly', 'blush leech', 'teal wallaby', 'brown capybara',
  'orange bear', 'harlequin sturgeon', 'magenta cicada', 'aquamarine ostrich', 'apricot lobster',
  'teal marmot', 'purple peacock', 'harlequin jaguar', 'sapphire python', 'white pigeon',
  'gold guan', 'salmon gamefowl', 'scarlet galliform', 'scarlet dragon', 'aquamarine tiglon',
  'lavender guan', 'amber beaver', 'maroon bass', 'beige halibut', 'lavender fly',
  'turquoise bobcat', 'coral anglerfish', 'yellow pigeon', 'moccasin barnacle', 'blue swordtail',
  'plum tarsier', 'tan armadillo', 'gold hummingbird', 'aquamarine rattlesnake',
  'fuchsia shark', 'amaranth quokka', 'coral koi', 'coral parakeet', 'apricot mandrill',
  'beige felidae', 'tan tarantula', 'beige roadrunner', 'blue flamingo', 'violet canid',
  'red crawdad', 'yellow rat', 'salmon python', 'amethyst chickadee', 'beige beetle',
  'magenta lark', 'ivory duck', 'pink antelope', 'blush mole', 'tomato wildcat',
  'aquamarine roadrunner', 'lavender whitefish', 'amber bandicoot', 'crimson bison',
  'gray jay', 'orange emu', 'plum hedgehog', 'plum sailfish', 'scarlet swordfish',
  'jade rodent', 'blue chimpanzee', 'teal leopon', 'moccasin bat', 'gray newt', 'emerald boar',
  'tan crane', 'moccasin whitefish', 'coral heron', 'bronze monkey', 'jade mackerel',
  'ivory eel', 'gray kangaroo', 'maroon goose', 'salmon stingray', 'tan raccoon',
  'gray thrush', 'apricot wombat', 'gold marlin', 'yellow rabbit', 'salmon crane',
  'coffee quelea', 'maroon dove', 'magenta gull', 'coral whippet', 'orange amphibian',
  'teal basilisk', 'silver canidae', 'yellow narwhal', 'tan gamefowl', 'blue angelfish',
  'apricot mole', 'yellow yak', 'aquamarine mastodon', 'plum amphibian', 'moccasin sheep',
  'peach impala', 'rose rhinoceros', 'scarlet ape', 'lavender coral', 'green wolverine',
  'emerald scorpion', 'purple jay', 'crimson squid', 'maroon prawn', 'lime grasshopper',
  'peach hippopotamus', 'beige pinniped', 'turquoise swordfish', 'blush halibut',
  'ivory smelt', 'lavender ocelot', 'gold mandrill', 'copper swan', 'aquamarine termite',
  'teal asp', 'turquoise salamander', 'coral mole', 'yellow kite', 'blue fox', 'silver mouse',
  'sapphire silkworm', 'salmon kiwi', 'olive peafowl', 'olive centipede', 'brown parrotfish',
  'olive weasel', 'white cheetah', 'indigo pheasant', 'azure parakeet', 'brown dormouse',
  'crimson horse', 'tomato herring', 'amber grouse', 'violet stingray', 'blue louse',
  'tan junglefowl', 'gold yak', 'cyan vulture', 'silver echidna', 'green mackerel',
  'yellow leopard', 'scarlet dinosaur', 'salmon rabbit', 'sapphire guppy', 'brown marten',
  'red marmot', 'amethyst snake', 'ivory cricket', 'rose zebra', 'emerald porpoise',
  'amaranth partridge', 'lavender blackbird', 'salmon bonobo', 'gold pigeon', 'coffee moth',
  'olive mollusk', 'violet tiglon', 'chocolate smelt', 'olive eagle', 'silver marmoset',
  'crimson anglerfish', 'silver cat', 'ivory jackal', 'brown elephant', 'lavender falcon',
  'gray woodpecker', 'purple monkey', 'magenta snake', 'coffee dolphin', 'azure jay',
  'apricot marmot', 'scarlet rhinoceros', 'amethyst orca', 'cyan amphibian', 'cyan cheetah',
  'ivory wallaby', 'gray cardinal', 'ivory zebra', 'olive basilisk', 'violet ermine',
  'azure basilisk', 'tan amphibian', 'jade piranha', 'maroon parrotfish', 'coffee badger',
  'jade guan', 'azure spoonbill', 'amethyst damselfly', 'harlequin wren', 'azure yak',
  'blue xerinae', 'apricot leopon', 'amber chicken', 'amaranth damselfly', 'plum iguana',
  'silver orca', 'gray porcupine', 'lavender lungfish', 'salmon carp', 'maroon goose',
  'azure grasshopper', 'amethyst hedgehog', 'purple gayal', 'blush tarsier', 'amaranth roundworm',
  'gold canidae', 'amethyst cuckoo', 'magenta termite', 'ivory buzzard', 'white rooster',
  'ivory toucan', 'turquoise earthworm', 'harlequin wildcat', 'silver coral', 'black platypus',
  'copper partridge', 'salmon flea', 'bronze bird', 'cyan aardvark', 'gray ape',
  'cyan pig', 'white hawk', 'crimson galliform', 'coffee ocelot', 'indigo dinosaur',
  'teal dolphin', 'moccasin camel', 'orange firefly', 'black heron', 'beige pigeon',
  'silver dragonfly', 'emerald ocelot', 'bronze ocelot', 'azure gamefowl', 'ivory mandrill',
  'amaranth scallop', 'amethyst flea', 'amber bobcat', 'moccasin sturgeon', 'beige lungfish',
  'purple quokka', 'emerald carp', 'violet deer', 'salmon marsupial', 'plum mollusk',
  'coffee giraffe', 'coffee bobcat', 'aqua pig', 'peach silkworm', 'teal dove', 'ivory bison',
  'coffee roadrunner', 'azure gecko', 'beige coral', 'tan canidae', 'sapphire guan',
  'cyan ladybug', 'beige gerbil', 'gold whitefish', 'brown hookworm', 'red silkworm',
  'amaranth marten', 'brown crab', 'moccasin cow', 'olive aardwolf', 'indigo tiger',
  'blush puma', 'red quelea', 'black aphid', 'brown anteater', 'emerald squirrel',
  'green beetle', 'amber bug', 'tomato fowl', 'violet raven', 'fuchsia dog', 'red stingray',
  'sapphire pheasant', 'lavender guineafowl', 'teal junglefowl', 'yellow macaw',
  'green wolverine', 'bronze kite', 'peach ocelot', 'brown elk', 'scarlet boa', 'plum swordfish',
  'copper eagle', 'plum penguin', 'chocolate jellyfish', 'amaranth badger', 'yellow kingfisher',
  'blush antelope', 'gray duck', 'aquamarine parrot', 'rose fowl', 'amber silverfish',
  'amber rat', 'aquamarine sparrow', 'beige shrimp', 'jade marmot', 'peach smelt',
  'cyan squid', 'crimson chimpanzee', 'emerald partridge', 'aqua lungfish', 'brown vole',
  'teal starfish', 'gold mule', 'sapphire goose', 'pink mouse', 'bronze scallop',
  'copper rook', 'scarlet sparrow', 'amethyst chickadee', 'silver tapir', 'ivory parrotfish',
  'gold landfowl', 'maroon piranha', 'copper leopard', 'crimson orca', 'cyan badger',
  'emerald pinniped', 'moccasin slug', 'crimson cod', 'jade camel', 'crimson termite',
  'plum otter', 'harlequin trout', 'plum otter', 'amethyst mite', 'amethyst elephant',
  'ivory vole', 'red bandicoot', 'yellow jay', 'amethyst muskox', 'harlequin mandrill',
  'green panda', 'emerald butterfly', 'purple nightingale', 'tan basilisk', 'white goat',
  'aqua jaguar', 'red cuckoo', 'aquamarine grouse', 'teal donkey', 'coffee pelican',
  'plum mockingbird', 'salmon whippet', 'ivory loon', 'gray frog', 'silver chameleon',
  'olive cougar', 'amaranth gopher', 'blush rat', 'turquoise dog', 'bronze octopus',
  'silver mackerel', 'blush fowl', 'amber llama', 'plum vole', 'beige termite', 'emerald opossum',
  'gray sheep', 'chocolate ladybug', 'bronze xerinae', 'brown wildcat', 'aqua snake',
  'lavender fox', 'red jackal', 'coffee stoat', 'blue perch', 'coral otter', 'brown cuckoo',
  'copper falcon', 'maroon chimpanzee', 'magenta crow', 'fuchsia squirrel', 'plum nightingale',
  'cyan finch', 'tomato panther', 'aquamarine grouse', 'coffee fox', 'plum zebra',
  'jade flea', 'green tern', 'brown beetle', 'coffee amphibian', 'turquoise llama',
  'coffee mink', 'blush nightingale', 'coral bug', 'scarlet mollusk', 'brown albatross',
  'bronze basilisk', 'silver pheasant', 'lavender magpie', 'tomato wasp', 'amethyst centipede',
  'tomato fowl', 'gold swan', 'crimson chameleon', 'violet grasshopper', 'violet wolverine',
  'amber marten', 'aquamarine porcupine', 'harlequin whippet', 'jade crow', 'cyan whale',
  'harlequin barracuda', 'blush silverfish', 'maroon gerbil', 'amethyst newt', 'salmon butterfly',
  'fuchsia hare', 'blush impala', 'lime elk', 'pink heron', 'black gayal', 'chocolate capybara',
  'magenta dingo', 'plum toad', 'emerald hyena', 'tomato giraffe', 'salmon octopus',
  'tan porpoise', 'coffee nightingale', 'coffee mandrill', 'orange emu', 'harlequin mink',
  'amber koala', 'chocolate pelican', 'blush unicorn', 'emerald opossum', 'coral muskox',
  'amethyst roundworm', 'turquoise llama', 'beige cockroach', 'black leopard', 'coffee chinchilla',
  'sapphire damselfly', 'rose goat', 'blush anaconda', 'bronze hummingbird', 'cyan koi',
  'copper roundworm', 'aqua gayal', 'sapphire mockingbird', 'jade bison', 'black mandrill',
  'beige stingray', 'orange ermine', 'purple hawk', 'beige horse', 'chocolate tiglon',
  'amber alpaca', 'crimson jellyfish', 'turquoise porpoise', 'apricot grouse', 'gold caterpillar',
  'green cicada', 'amaranth scallop', 'harlequin ladybug', 'beige vulture', 'jade jellyfish',
  'silver hornet', 'apricot marmot', 'apricot scorpion', 'tomato cat', 'magenta warbler',
  'green cobra', 'beige wildebeest', 'amethyst raccoon', 'green bat', 'gold bug',
  'teal turkey', 'aquamarine pig', 'lavender dolphin', 'crimson antlion', 'amethyst urial',
  'orange condor', 'indigo mammal', 'yellow rooster', 'aquamarine hare', 'amber worm',
  'apricot roundworm', 'blush barracuda', 'magenta antlion', 'rose cow', 'sapphire mosquito',
  'maroon opossum', 'silver skunk', 'gray bug', 'maroon chickadee', 'sapphire jay',
  'fuchsia bovid', 'tan constrictor', 'amaranth rat', 'coral ape', 'red vole', 'yellow kangaroo',
  'chocolate baboon', 'moccasin bandicoot', 'chocolate hippopotamus', 'yellow shark',
  'lime lungfish', 'purple panda', 'amethyst jay', 'aqua bovid', 'cyan camel', 'indigo frog',
  'aqua mule', 'coral ocelot', 'peach echidna', 'blush herring', 'purple meadowlark',
  'jade wolverine', 'azure scallop', 'teal harrier', 'amber snail', 'purple mandrill',
  'orange toucan', 'blush canidae', 'silver takin', 'amaranth bison', 'purple pheasant',
  'apricot gecko', 'scarlet chimpanzee', 'purple gayal', 'pink bee', 'cyan vole',
  'brown boa', 'aquamarine thrush', 'magenta chickadee', 'turquoise lemming', 'teal junglefowl',
  'sapphire hare', 'salmon sole', 'bronze guan', 'red bat', 'brown pike', 'silver kangaroo',
  'lavender cuckoo', 'cyan marten', 'apricot sole', 'violet bass', 'aquamarine badger',
  'violet mastodon', 'lime crab', 'scarlet muskox', 'brown cod', 'tomato earwig',
  'peach reindeer', 'purple damselfly', 'sapphire aphid', 'lavender earwig', 'indigo marmot',
  'gold xerinae', 'lime armadillo', 'brown llama', 'salmon snake', 'chocolate baboon',
  'beige squirrel', 'yellow octopus', 'blush cardinal', 'coffee lion', 'blush grasshopper',
  'rose swan', 'gray prawn', 'white kangaroo', 'emerald squirrel', 'beige raccoon',
  'magenta thrush', 'ivory toad', 'rose leopon', 'copper piranha', 'bronze crane',
  'violet urial', 'olive flamingo', 'aqua gopher', 'white sailfish', 'aquamarine gecko',
  'maroon swordtail', 'rose reindeer', 'azure vole', 'lime hoverfly', 'aqua turtle',
  'cyan jackal', 'cyan swordfish', 'azure planarian', 'coffee raven', 'tan wallaby',
  'cyan yak', 'gray woodpecker', 'peach rodent', 'brown lemur', 'crimson anglerfish',
  'green python', 'indigo buzzard', 'gold planarian', 'green blackbird', 'crimson lungfish',
  'blush chicken', 'harlequin anaconda', 'amaranth camel', 'maroon bear', 'blue angelfish',
  'ivory chipmunk', 'violet ape', 'blue porcupine', 'pink mink', 'brown flea', 'coral kite',
  'copper pinniped', 'lime chameleon', 'olive cheetah', 'teal whitefish', 'jade ant',
  'apricot hornet', 'harlequin gecko', 'amber peafowl', 'plum tuna', 'copper jay',
  'lavender cod', 'coral gamefowl', 'amber clam', 'white vole', 'gray dragon', 'lime whale',
  'harlequin chickadee', 'amethyst quelea', 'purple unicorn', 'olive tick', 'orange monkey',
  'beige ape', 'sapphire rook', 'magenta cuckoo', 'apricot lobster', 'purple rat',
  'crimson butterfly', 'orange cardinal', 'tomato mink', 'blush gerbil', 'amaranth bird',
  'white horse', 'peach orca', 'rose raven', 'rose tick', 'gray junglefowl', 'azure pheasant',
  'azure shrew', 'beige canidae', 'white lemming', 'copper lemming', 'yellow warbler',
  'fuchsia gerbil', 'yellow wildcat', 'tomato narwhal', 'beige aphid', 'sapphire quail',
  'chocolate pony', 'scarlet wren', 'tomato fish', 'blush owl', 'orange stoat', 'amber chickadee',
  'copper whippet', 'scarlet mosquito', 'apricot ocelot', 'crimson monkey', 'scarlet lamprey',
  'aqua crayfish', 'bronze swallow', 'green stoat', 'green jellyfish', 'tan squirrel',
  'fuchsia leech', 'teal grasshopper', 'green damselfly', 'white lamprey', 'beige raccoon',
  'rose fox', 'silver tern', 'turquoise dolphin', 'plum silverfish', 'blush catfish',
  'blush tick', 'crimson harrier', 'aquamarine shrimp', 'plum bat', 'crimson guan',
  'amber chicken', 'chocolate marlin', 'amber tarsier', 'olive porpoise', 'maroon galliform',
  'harlequin pony', 'olive mockingbird', 'harlequin guanaco', 'chocolate ptarmigan',
  'jade meadowlark', 'coffee manatee', 'magenta tiglon', 'scarlet bee', 'salmon cephalopod',
  'tan albatross', 'emerald chipmunk', 'beige dragon', 'green cougar', 'blush bass',
  'silver quelea', 'gold parrotfish', 'sapphire otter', 'violet eel', 'yellow pelican',
  'lavender fox', 'amber guppy', 'olive silverfish', 'ivory badger', 'fuchsia lemming',
  'black tapir', 'purple tern', 'beige wolverine', 'plum armadillo', 'apricot skunk',
  'green squid', 'blush marmoset', 'moccasin salamander', 'chocolate starfish', 'jade scallop',
  'coffee puffin', 'green salamander', 'amethyst zebra', 'silver mongoose', 'harlequin marlin',
  'scarlet amphibian', 'teal vicuna', 'rose beetle', 'peach eel', 'crimson gull',
  'silver wolf', 'lime gopher', 'crimson tern', 'maroon snipe', 'plum roundworm',
  'gray tahr', 'brown rabbit', 'scarlet stork', 'magenta angelfish', 'tomato flamingo',
  'blue cougar', 'turquoise reindeer', 'lavender skunk', 'scarlet bovid', 'emerald flamingo',
  'magenta falcon', 'copper takin', 'violet koi', 'blush swift', 'turquoise tortoise',
  'silver manatee', 'amethyst flyingfish', 'ivory lizard', 'rose owl', 'bronze alpaca',
  'peach prawn', 'salmon sawfish', 'tan haddock', 'salmon pheasant', 'ivory haddock',
  'blue piranha', 'bronze orangutan', 'blush pinniped', 'amber jaguar', 'cyan angelfish',
  'lime whippet', 'tomato bobolink', 'cyan mole', 'amber parrotfish', 'purple slug',
  'lime bass', 'coffee stingray', 'pink jellyfish', 'gray sloth', 'white capybara',
  'magenta minnow', 'cyan owl', 'magenta tarsier', 'violet weasel', 'cyan jellyfish',
  'chocolate wolverine', 'silver primate', 'tan amphibian', 'amber moose', 'crimson bird',
  'amaranth urial', 'olive raven', 'black macaw', 'amethyst armadillo', 'amethyst anaconda',
  'black frog', 'maroon python', 'white pinniped', 'magenta starfish', 'black cougar',
  'olive shrew', 'gray lungfish', 'green rabbit', 'magenta piranha', 'amaranth buzzard',
  'ivory goldfish', 'amber leopard', 'cyan silverfish', 'bronze cat', 'blue ferret',
  'gold goose', 'pink bee', 'lime primate', 'azure mink', 'pink crow', 'ivory rat',
  'copper peacock', 'tan limpet', 'emerald basilisk', 'amber shark', 'chocolate chipmunk',
  'aquamarine alligator', 'coral cheetah', 'red scallop', 'rose kangaroo', 'plum rattlesnake',
  'olive flyingfish', 'apricot moose', 'moccasin cockroach', 'red krill', 'blue scorpion',
  'amaranth snail', 'blue butterfly', 'maroon deer', 'coffee aardvark', 'pink warbler',
  'crimson finch', 'salmon lungfish', 'aqua magpie', 'purple tick', 'violet lemur',
  'green snake', 'fuchsia urial', 'orange tortoise', 'bronze orangutan', 'silver newt',
  'purple hamster', 'tan badger', 'orange mite', 'rose baboon', 'amethyst sheep',
  'apricot quelea', 'amethyst piranha', 'amethyst aardvark', 'chocolate tapir', 'sapphire firefly',
  'blue vole', 'tan wallaby', 'teal donkey', 'orange cow', 'purple goose', 'silver bedbug',
  'white bobolink', 'emerald quelea', 'rose kangaroo', 'copper krill', 'blush loon',
  'sapphire smelt', 'aquamarine limpet', 'bronze termite', 'orange ant', 'tan antelope',
  'emerald gibbon', 'fuchsia caterpillar', 'yellow panther', 'yellow goat', 'plum wasp',
  'teal louse', 'white mastodon', 'beige crab', 'brown mockingbird', 'chocolate eel',
  'tomato nightingale', 'amber bat', 'red hyena', 'crimson lynx', 'harlequin gayal',
  'brown vulture', 'yellow mammal', 'pink jellyfish', 'apricot porcupine', 'tan raven',
  'indigo mandrill', 'orange prawn', 'fuchsia booby', 'beige koala', 'crimson earthworm',
  'peach fowl', 'coral hare', 'indigo haddock', 'gray leopard', 'moccasin squid',
  'jade toad', 'magenta pony', 'olive marmoset', 'amaranth marsupial', 'copper herring',
  'brown bobolink', 'amaranth goat', 'blush impala', 'olive gayal', 'aqua frog',
  'violet gorilla', 'olive newt', 'white fox', 'gold slug', 'sapphire alpaca', 'emerald hornet',
  'amethyst parrotfish', 'green aphid', 'maroon cattle', 'lime koi', 'salmon chimpanzee',
  'green mink', 'peach wolverine', 'scarlet cockroach', 'aqua slug', 'apricot smelt',
  'jade meadowlark', 'red echidna', 'scarlet bird', 'lavender butterfly', 'harlequin meadowlark',
  'orange chimpanzee', 'bronze possum', 'purple cicada', 'rose marten', 'brown cattle',
  'aqua stoat', 'lavender catfish', 'red goat', 'lime felidae', 'coffee panther',
  'azure fowl', 'sapphire turkey', 'bronze leech', 'bronze parakeet', 'harlequin crow',
  'red flea', 'white boar', 'coral marlin', 'lime fox', 'ivory parrot', 'moccasin dragonfly',
  'ivory swordfish', 'sapphire smelt', 'fuchsia amphibian', 'violet condor', 'purple bison',
  'red chameleon', 'blush elk', 'scarlet gorilla', 'red skunk', 'yellow mite', 'lavender alligator',
  'purple mockingbird', 'chocolate puma', 'coral moose', 'teal worm', 'maroon squirrel',
  'crimson salamander', 'blue aardvark', 'emerald kingfisher', 'azure buzzard', 'tomato goose',
  'green wren', 'azure guineafowl', 'harlequin hamster', 'amethyst whitefish', 'crimson cardinal',
  'silver worm', 'emerald rat', 'copper bear', 'blush giraffe', 'silver rat', 'beige boa',
  'gray snipe', 'green tortoise', 'amber jaguar', 'apricot falcon', 'apricot barnacle',
  'red planarian', 'emerald worm', 'scarlet viper', 'silver peafowl', 'lavender muskox',
  'tan mouse', 'aquamarine opossum', 'bronze quail', 'plum camel', 'magenta cephalopod',
  'silver ptarmigan', 'amethyst elk', 'peach lizard', 'green wombat', 'tomato albatross',
  'harlequin pony', 'tomato kingfisher', 'plum bug', 'lime whippet', 'harlequin tapir',
  'amaranth shrimp', 'indigo tuna', 'cyan chickadee', 'tan bobolink', 'amber pigeon',
  'apricot felidae', 'fuchsia gopher', 'aquamarine puffin', 'red cat', 'maroon pony',
  'cyan shrimp', 'coffee swallow', 'chocolate yak', 'apricot centipede', 'turquoise pelican',
  'red cheetah', 'apricot llama', 'purple condor', 'blush cod', 'cyan wasp', 'brown beaver',
  'purple rodent', 'fuchsia silverfish', 'silver gibbon', 'apricot vulture', 'coffee urial',
  'fuchsia swift', 'fuchsia egret', 'lime python', 'blue yak', 'amber dog', 'sapphire mosquito',
  'olive jellyfish', 'copper quokka', 'moccasin parrot', 'fuchsia llama', 'yellow xerinae',
  'orange dinosaur', 'salmon jackal', 'coffee eel', 'violet anaconda', 'olive louse',
  'tomato chipmunk', 'amaranth cat', 'sapphire grasshopper', 'magenta eel', 'magenta bee',
  'cyan orangutan', 'lavender crane', 'maroon echidna', 'teal minnow', 'silver skink',
  'white snipe', 'cyan vicuna', 'yellow mouse', 'chocolate urial', 'plum pony', 'coffee peafowl',
  'crimson loon', 'lavender gopher', 'indigo ocelot', 'tomato salmon', 'blue blackbird',
  'orange toucan', 'olive slug', 'gray sloth', 'tomato marlin', 'apricot newt', 'rose porpoise',
  'gold wildebeest', 'apricot hamster', 'gold cephalopod', 'cyan angelfish', 'lavender bandicoot',
  'azure spider', 'chocolate tortoise', 'cyan grasshopper', 'gold rat', 'silver platypus',
  'harlequin xerinae', 'green clownfish', 'pink orangutan', 'chocolate felidae',
  'cyan guineafowl', 'gray piranha', 'rose krill', 'black koi', 'aqua hyena', 'rose finch',
  'white hookworm', 'magenta quokka', 'coral flyingfish', 'violet anteater', 'tomato lamprey',
  'plum whale', 'bronze echidna', 'bronze penguin', 'teal ape', 'coffee tortoise',
  'azure mockingbird', 'amaranth shrew', 'moccasin scorpion', 'black canidae', 'green rooster',
  'sapphire crayfish', 'purple gull', 'aquamarine damselfly', 'orange sawfish', 'indigo porcupine',
  'sapphire vulture', 'fuchsia impala', 'lavender grouse', 'maroon scallop', 'pink hare',
  'bronze llama', 'tomato blackbird', 'peach amphibian', 'lime krill', 'tomato chickadee',
  'lime turtle', 'cyan worm', 'lavender sole', 'fuchsia harrier', 'gold crane', 'gray halibut',
  'aquamarine tern', 'fuchsia alligator', 'coral tortoise', 'azure capybara', 'red bug',
  'amethyst lizard', 'fuchsia takin', 'scarlet snipe', 'violet krill', 'apricot parrotfish',
  'amber sole', 'lime angelfish', 'sapphire viper', 'bronze pony', 'amber crawdad',
  'chocolate constrictor', 'ivory crab', 'blue mule', 'green iguana', 'beige anglerfish',
  'aqua swift', 'orange earwig', 'indigo tuna', 'white loon', 'crimson herring',
  'yellow mongoose', 'white takin', 'peach dingo', 'blush elk', 'sapphire tyrannosaurus',
  'red eel', 'brown sheep', 'lavender ant', 'black mandrill', 'ivory koi', 'gray parrotfish',
  'white dragon', 'turquoise swallow', 'turquoise manatee', 'maroon squid', 'yellow cockroach',
  'yellow jellyfish', 'coffee horse', 'pink ladybug', 'aqua chicken', 'bronze yak',
  'plum quokka', 'harlequin pinniped', 'blue scorpion', 'amaranth emu', 'moccasin swallow',
  'black spoonbill', 'blue weasel', 'moccasin muskox', 'moccasin bat', 'tan warbler',
  'salmon wombat', 'copper bear', 'violet wren', 'green mockingbird', 'copper vicuna',
  'plum snake', 'moccasin felidae', 'ivory marmot', 'brown flyingfish', 'aquamarine crawdad',
  'beige pig', 'lime rat', 'peach armadillo', 'fuchsia newt', 'yellow sloth', 'salmon wallaby',
  'turquoise reptile', 'pink hornet', 'aqua horse', 'magenta prawn', 'chocolate junglefowl',
  'magenta gorilla', 'silver mockingbird', 'magenta ant', 'purple leopard', 'harlequin flamingo',
  'tomato firefly', 'scarlet moose', 'peach baboon', 'indigo guineafowl', 'tomato cuckoo',
  'white jackal', 'harlequin jellyfish', 'gold mule', 'teal capybara', 'beige ox',
  'copper gazelle', 'copper turtle', 'turquoise crow', 'azure ocelot', 'copper mite',
  'silver lynx', 'violet woodpecker', 'sapphire emu', 'gray otter', 'scarlet lemur',
  'white pig', 'copper pheasant', 'pink cricket', 'coffee hoverfly', 'fuchsia gerbil',
  'salmon leopon', 'amethyst crab', 'salmon deer', 'turquoise orca', 'aquamarine stoat',
  'blue rabbit', 'silver hedgehog', 'coral baboon', 'rose owl', 'gold chipmunk',
  'magenta panther', 'black octopus', 'copper sawfish', 'aquamarine rabbit', 'cyan cobra',
  'emerald tarantula', 'fuchsia badger', 'gold constrictor', 'ivory fly', 'brown duck',
  'chocolate perch', 'emerald anaconda', 'plum baboon', 'orange earwig', 'tan elephant',
  'chocolate locust', 'copper clam', 'black horse', 'apricot fish', 'sapphire dingo',
  'blue opossum', 'sapphire python', 'moccasin rat', 'chocolate crayfish', 'coral rattlesnake',
  'amaranth whitefish', 'amaranth parakeet', 'coral coral', 'tomato kingfisher',
  'green tarantula', 'lime wasp', 'lime smelt', 'moccasin landfowl', 'red minnow',
  'blue gull', 'magenta orca', 'green dingo', 'magenta crane', 'scarlet penguin',
  'yellow boar', 'turquoise hawk', 'aquamarine cod', 'lavender rhinoceros', 'blue fish',
  'lime snipe', 'teal gayal', 'crimson chameleon', 'silver swift', 'ivory haddock',
  'brown marsupial', 'aqua bass', 'sapphire wildcat', 'teal mockingbird', 'lavender halibut',
  'salmon primate', 'turquoise lizard', 'brown jaguar', 'aquamarine hare', 'peach bonobo',
  'orange earthworm', 'lime wildebeest', 'ivory mite', 'turquoise flamingo', 'teal cuckoo',
  'copper bat', 'white gopher', 'crimson perch', 'rose stoat', 'azure firefly', 'bronze termite',
  'purple gamefowl', 'lavender mackerel', 'sapphire gayal', 'green crane', 'yellow dolphin',
  'gray parrotfish', 'apricot caterpillar', 'tan tortoise', 'coffee goat', 'scarlet turtle',
  'jade marlin', 'apricot canid', 'emerald badger', 'blush cattle', 'tan leopard',
  'maroon mastodon', 'azure chipmunk', 'tan ermine', 'blue cricket', 'crimson mite',
  'maroon toad', 'plum cephalopod', 'chocolate chicken', 'magenta zebra', 'turquoise lamprey',
  'silver bonobo', 'silver landfowl', 'black carp', 'jade koi', 'jade planarian',
  'crimson angelfish', 'amber herring', 'beige heron', 'emerald raven', 'lavender worm',
  'crimson spoonbill', 'gold armadillo', 'orange stork', 'brown coyote', 'rose grouse',
  'white catshark', 'lime ostrich', 'fuchsia tiger', 'coffee toucan', 'black marmot',
  'salmon spoonbill', 'apricot puffin', 'moccasin sparrow', 'tan raccoon', 'tomato badger',
  'rose finch', 'plum wasp', 'apricot python', 'indigo gamefowl', 'violet chimpanzee',
  'crimson panda', 'sapphire locust', 'olive bear', 'amethyst aardwolf', 'harlequin bison',
  'beige spider', 'scarlet cattle', 'chocolate marmot', 'harlequin mollusk', 'maroon moth',
  'olive condor', 'green gayal', 'yellow toucan', 'pink newt', 'moccasin bandicoot',
  'fuchsia constrictor', 'chocolate panther', 'amber swordfish', 'emerald firefly',
  'gray tarantula', 'black marten', 'coffee canid', 'rose aphid', 'red reptile',
  'blue ptarmigan', 'amaranth chipmunk', 'gold bee', 'maroon mite', 'coffee guanaco',
  'amber sturgeon', 'green skink', 'brown basilisk', 'blush locust', 'turquoise impala',
  'tomato dolphin', 'jade antelope', 'black goat', 'teal cobra', 'coffee planarian',
  'gray bear', 'magenta chicken', 'cyan narwhal', 'ivory chickadee', 'sapphire cheetah',
  'lime peafowl', 'amethyst sloth', 'olive crane', 'rose koi', 'black prawn', 'white louse',
  'copper anglerfish', 'amethyst harrier', 'orange cougar', 'silver squirrel', 'purple stoat',
  'silver primate', 'emerald thrush', 'coffee ostrich', 'jade parrot', 'violet rattlesnake',
  'aqua duck', 'brown crayfish', 'scarlet galliform', 'cyan urial', 'lime woodpecker',
  'indigo turkey', 'amaranth unicorn', 'amber rat', 'crimson crayfish', 'aquamarine sheep',
  'chocolate barnacle', 'olive wildcat', 'silver mastodon', 'bronze ocelot', 'magenta toucan',
  'white gopher', 'teal tarsier', 'blush takin', 'purple silkworm', 'salmon yak',
  'green koi', 'yellow boar', 'rose dove', 'sapphire gerbil', 'teal leopon', 'azure albatross',
  'black roadrunner', 'violet newt', 'tan loon', 'apricot narwhal', 'lavender shrimp',
  'aqua bovid', 'brown barracuda', 'cyan cephalopod', 'fuchsia aphid', 'blush zebra',
  'orange wildebeest', 'cyan elephant', 'maroon spoonbill', 'coral frog', 'cyan orca',
  'azure quelea', 'teal goat', 'gold anglerfish', 'orange alligator', 'pink otter',
  'jade orangutan', 'lavender bee', 'maroon koala', 'amaranth pony', 'aquamarine orangutan',
  'amethyst wasp', 'black crocodile', 'tomato termite', 'orange elk', 'rose damselfly',
  'salmon wombat', 'blush quokka', 'coffee emu', 'moccasin tyrannosaurus', 'maroon hyena',
  'amber marmot', 'pink hyena', 'jade stoat', 'coral bison', 'moccasin chinchilla',
  'amaranth ox', 'purple crayfish', 'turquoise landfowl', 'cyan kingfisher', 'amethyst parakeet',
  'blue tick', 'gold bonobo', 'lime felidae', 'yellow meadowlark', 'blush goat',
  'olive wombat', 'plum bison', 'indigo rodent', 'coral starfish', 'yellow thrush',
  'beige gull', 'scarlet dog', 'sapphire bobolink', 'amethyst muskox', 'teal deer',
  'blush owl', 'lavender salamander', 'peach cat', 'azure gorilla', 'orange rabbit',
  'turquoise dragonfly', 'aquamarine alpaca', 'aqua scallop', 'maroon pony', 'amaranth tapir',
  'green rhinoceros', 'emerald bear', 'bronze cow', 'amaranth ladybug', 'chocolate condor',
  'amaranth badger', 'brown wolf', 'blush roundworm', 'cyan moth', 'amber parrot',
  'beige hamster', 'blue warbler', 'blush turtle', 'azure perch', 'yellow toad',
  'black gecko', 'amber horse', 'blush fowl', 'jade guppy', 'gray prawn', 'pink guineafowl',
  'blue junglefowl', 'sapphire kangaroo', 'blue badger', 'harlequin asp', 'blush bison',
  'orange condor', 'green tiger', 'coffee leopon', 'sapphire lemur', 'amaranth kite',
  'aqua lark', 'cyan snake', 'yellow panda', 'fuchsia haddock', 'plum cardinal',
  'harlequin viper', 'apricot earthworm', 'jade puma', 'green jackal', 'aqua woodpecker',
  'amaranth horse', 'amaranth thrush', 'lavender gibbon', 'teal walrus', 'brown pinniped',
  'copper ape', 'tan guineafowl', 'blush trout', 'silver quokka', 'indigo cow', 'indigo slug',
  'coral fowl', 'bronze deer', 'harlequin shrew', 'scarlet aardvark', 'teal mockingbird',
  'olive opossum', 'silver koi', 'peach donkey', 'copper mouse', 'lavender halibut',
  'blush starfish', 'olive swift', 'fuchsia smelt', 'plum fly', 'turquoise wolverine',
  'tomato whale', 'blue seahorse', 'tan aardvark', 'beige dormouse', 'tomato haddock',
  'tomato primate', 'ivory scallop', 'peach sloth', 'aquamarine falcon', 'salmon fly',
  'purple rooster', 'peach trout', 'lime parakeet', 'teal cardinal', 'white moose',
  'gold trout', 'salmon raven', 'coral gamefowl', 'green giraffe', 'tan toad', 'amber trout',
  'green cicada', 'maroon basilisk', 'silver falcon', 'tan hippopotamus', 'azure toucan',
  'jade prawn', 'plum whippet', 'scarlet halibut', 'peach mammal', 'fuchsia ladybug',
  'indigo clownfish', 'coral manatee', 'moccasin chameleon', 'red cat', 'rose mackerel',
  'salmon reptile', 'aqua cow', 'sapphire wolf', 'azure hippopotamus', 'pink silverfish',
  'chocolate cobra', 'aquamarine goldfish', 'tomato octopus', 'crimson mockingbird',
  'orange mouse', 'maroon barnacle', 'yellow ocelot', 'olive toucan', 'chocolate earthworm',
  'cyan xerinae', 'sapphire platypus', 'teal hookworm', 'jade aardwolf', 'rose goat',
  'amber mockingbird', 'rose asp', 'bronze opossum', 'amaranth krill', 'crimson tick',
  'peach otter', 'salmon dinosaur', 'azure flamingo', 'black turtle', 'peach felidae',
  'plum alpaca', 'copper deer', 'white antlion', 'cyan landfowl', 'gray koi', 'indigo catshark',
  'coffee leopard', 'white worm', 'violet dormouse', 'gray finch', 'cyan beetle',
  'red seahorse', 'peach raccoon', 'aquamarine vulture', 'magenta shark', 'harlequin swordfish',
  'maroon gopher', 'purple gull', 'olive mole', 'indigo mouse', 'pink wasp', 'coffee gibbon',
  'aqua bass', 'jade parrotfish', 'sapphire swordfish', 'black bird', 'coffee bee',
  'crimson condor', 'amber goat', 'amaranth wolverine', 'orange heron', 'orange slug',
  'purple harrier', 'amaranth perch', 'amber cattle', 'tomato falcon', 'crimson tapir',
  'aquamarine sole', 'indigo rodent', 'tan parakeet', 'olive wolf', 'rose eagle',
  'red silkworm', 'apricot raccoon', 'black bobcat', 'aqua mammal', 'turquoise platypus',
  'red hookworm', 'white peafowl', 'tan loon', 'green centipede', 'teal finch', 'salmon turtle',
  'orange rat', 'orange macaw', 'indigo mastodon', 'crimson bison', 'aquamarine narwhal',
  'copper squirrel', 'pink gopher', 'black crocodile', 'lime owl', 'maroon kangaroo',
  'lavender chicken', 'violet spoonbill', 'blush snake', 'aquamarine emu', 'turquoise cow',
  'blue giraffe', 'bronze roundworm', 'blush tick', 'scarlet turtle', 'blue tortoise',
  'ivory tarsier', 'aqua lion', 'amethyst panda', 'copper toad', 'emerald dragon',
  'red trout', 'indigo catshark', 'cyan whippet', 'amaranth wolverine', 'green mink',
  'plum wolverine', 'tomato panther', 'salmon wallaby', 'silver herring', 'fuchsia panda',
  'chocolate owl', 'aquamarine zebra', 'crimson lion', 'silver gorilla', 'teal cattle',
  'orange lamprey', 'salmon harrier', 'rose canidae', 'apricot leech', 'brown mouse',
  'salmon magpie', 'indigo ostrich', 'aquamarine gayal', 'coral planarian', 'silver landfowl',
  'ivory bass', 'peach dragon', 'teal mockingbird', 'maroon mandrill', 'amaranth loon',
  'blush mastodon', 'green peacock', 'crimson wallaby', 'chocolate cuckoo', 'black gibbon',
  'peach crab', 'bronze cricket', 'silver jaguar', 'olive albatross', 'crimson turtle',
  'peach partridge', 'lavender vole', 'blush woodpecker', 'azure cardinal', 'coffee horse',
  'gray jay', 'olive amphibian', 'teal beetle', 'harlequin asp', 'olive deer', 'violet ox',
  'magenta possum', 'coral vulture', 'olive viper', 'jade wasp', 'plum narwhal',
  'amaranth damselfly', 'coral elk', 'teal worm', 'yellow fox', 'salmon toad', 'emerald tern',
  'maroon jackal', 'black reindeer', 'peach silverfish', 'harlequin elephant', 'tomato stoat',
  'moccasin impala', 'cyan gazelle', 'pink jackal', 'lavender fly', 'emerald tiger',
  'violet sole', 'scarlet scorpion', 'gray bass', 'aquamarine hawk', 'scarlet spoonbill',
  'red snipe', 'indigo puffin', 'aqua halibut', 'rose vole', 'fuchsia grouse', 'red crane',
  'aquamarine mink', 'coffee kiwi', 'crimson kiwi', 'lavender tick', 'coffee iguana',
  'coffee turkey', 'copper louse', 'peach amphibian', 'green sloth', 'cyan mammal',
  'blue cuckoo', 'silver flyingfish', 'teal cod', 'orange salamander', 'harlequin otter',
  'aqua flea', 'chocolate tortoise', 'red guineafowl', 'scarlet angelfish', 'green chickadee',
  'teal planarian', 'gold roadrunner', 'green badger', 'blush marsupial', 'aquamarine armadillo',
  'salmon viper', 'pink ostrich', 'aquamarine possum', 'scarlet chimpanzee', 'ivory aardvark',
  'sapphire pelican', 'silver sloth', 'plum mule', 'black parrotfish', 'coral limpet',
  'blush gecko', 'orange barnacle', 'aquamarine goldfish', 'lavender emu', 'red earthworm',
  'violet raccoon', 'emerald porcupine', 'teal yak', 'apricot firefly', 'harlequin guppy',
  'lavender warbler', 'magenta wallaby', 'sapphire silverfish', 'coffee goat', 'yellow orca',
  'orange magpie', 'orange herring', 'brown pigeon', 'aquamarine iguana', 'copper koala',
  'turquoise mite', 'white amphibian', 'coffee aardwolf', 'bronze sheep', 'harlequin damselfly',
  'moccasin crow', 'rose pigeon', 'gold gamefowl', 'amaranth marmoset', 'copper tiger',
  'lavender lynx', 'violet tick', 'olive limpet', 'blue locust', 'harlequin haddock',
  'lime basilisk', 'indigo kiwi', 'amethyst goose', 'crimson anglerfish', 'salmon minnow',
  'amber panther', 'blue shrew', 'cyan tuna', 'harlequin flamingo', 'violet squid',
  'white coyote', 'ivory rhinoceros', 'amber squirrel', 'fuchsia puffin', 'salmon chickadee',
  'brown quail', 'harlequin parrotfish', 'beige lark', 'orange cockroach', 'purple mandrill',
  'indigo reindeer', 'coffee hookworm', 'blue mastodon', 'cyan marsupial', 'black quelea',
  'tan sole', 'olive barracuda', 'orange xerinae', 'fuchsia eagle', 'lavender salamander',
  'ivory clownfish', 'teal pike', 'rose skunk', 'coffee snipe', 'fuchsia prawn',
  'amethyst cougar', 'amethyst cow', 'fuchsia bovid', 'amaranth pig', 'fuchsia booby',
  'gray dove', 'red canid', 'indigo tyrannosaurus', 'rose hornet', 'brown pigeon',
  'purple parrot', 'blush sloth', 'aquamarine ant', 'coral dragonfly', 'amethyst hummingbird',
  'scarlet caterpillar', 'scarlet pike', 'yellow cicada', 'amber hyena', 'turquoise koi',
  'magenta wildcat', 'fuchsia marten', 'emerald grouse', 'teal moose', 'black cobra',
  'fuchsia panther', 'moccasin nightingale', 'teal hummingbird', 'crimson ferret',
  'blue limpet', 'amaranth chickadee', 'aquamarine sole', 'bronze dolphin', 'aquamarine trout',
  'red angelfish', 'amber planarian', 'peach cow', 'indigo leech', 'amaranth thrush',
  'amaranth possum', 'magenta earwig', 'chocolate sloth', 'blush sawfish', 'brown koala',
  'maroon caterpillar', 'plum mite', 'orange kangaroo', 'lime bird', 'amethyst bear',
  'yellow cheetah', 'purple coyote', 'moccasin tahr', 'aqua parrot', 'yellow impala',
  'gray pigeon', 'emerald flea', 'copper termite', 'crimson damselfly', 'pink swordfish',
  'harlequin centipede', 'beige cuckoo', 'amethyst mite', 'peach dolphin', 'red catshark',
  'red salmon', 'yellow hedgehog', 'amethyst barracuda', 'beige spider', 'emerald wren',
  'lavender snake', 'turquoise lizard', 'blush canid', 'emerald cow', 'tan snake',
  'brown mongoose', 'coffee ermine', 'plum puma', 'red beetle', 'turquoise mandrill',
  'teal cephalopod', 'coral marten', 'orange parakeet', 'amethyst salamander', 'plum gorilla',
  'scarlet hummingbird', 'jade cricket', 'salmon aardvark', 'black landfowl', 'aqua krill',
  'silver skunk', 'blue lizard', 'coral manatee', 'lavender constrictor', 'coral cockroach',
  'bronze wasp', 'harlequin spider', 'white krill', 'azure magpie', 'amethyst parakeet',
  'emerald koala', 'gray stoat', 'moccasin ferret', 'magenta snail', 'coffee bandicoot',
  'cyan landfowl', 'apricot finch', 'bronze puma', 'salmon shark', 'beige louse',
  'blush monkey', 'chocolate takin', 'cyan bat', 'indigo ermine', 'aqua wallaby',
  'brown hummingbird', 'blush gazelle', 'peach slug', 'bronze crayfish', 'crimson cricket',
  'coral duck', 'emerald booby', 'harlequin minnow', 'emerald monkey', 'copper otter',
  'salmon gayal', 'sapphire stork', 'yellow egret', 'aquamarine antlion', 'harlequin canidae',
  'teal dove', 'ivory armadillo', 'blush pinniped', 'red bandicoot', 'emerald cow',
  'amaranth chameleon', 'tan pelican', 'gray goat', 'amaranth hyena', 'black silverfish',
  'tan butterfly', 'cyan trout', 'jade swallow', 'gray koi', 'amethyst skink', 'aquamarine crocodile',
  'tomato giraffe', 'blush goldfish', 'bronze cricket', 'beige hoverfly', 'yellow unicorn',
  'turquoise planarian', 'ivory pinniped', 'lavender shrew', 'green aardwolf', 'azure bison',
  'moccasin locust', 'white hookworm', 'brown shark', 'peach cicada', 'aqua piranha',
  'gold hoverfly', 'moccasin eel', 'jade marsupial', 'apricot bison', 'chocolate sawfish',
  'blue grouse', 'copper wildcat', 'indigo reindeer', 'coral tuna', 'yellow moose',
  'brown toucan', 'tomato dinosaur', 'yellow mastodon', 'plum swift', 'coral hornet',
  'yellow lungfish', 'bronze narwhal', 'aquamarine dormouse', 'maroon hawk', 'copper aphid',
  'turquoise chimpanzee', 'pink piranha', 'black mouse', 'rose cheetah', 'silver spoonbill',
  'ivory puma', 'bronze bedbug', 'coral silkworm', 'aquamarine grouse', 'apricot peacock',
  'black orca', 'ivory lemur', 'coral coral', 'coral parrot', 'scarlet bear', 'amethyst badger',
  'olive dog', 'red parakeet', 'emerald hare', 'purple iguana', 'gray quail', 'silver quokka',
  'copper guppy', 'purple lizard', 'gold anaconda', 'maroon silverfish', 'ivory bear',
  'amethyst bat', 'silver ferret', 'blush piranha', 'blue scallop', 'tan mandrill',
  'lavender jaguar', 'beige lamprey', 'azure rattlesnake', 'turquoise armadillo',
  'moccasin ant', 'apricot bird', 'jade primate', 'cyan centipede', 'rose chinchilla',
  'beige piranha', 'peach hoverfly', 'bronze bear', 'indigo swordtail', 'red starfish',
  'yellow kiwi', 'rose nightingale', 'coral butterfly', 'chocolate galliform', 'scarlet booby',
  'crimson bandicoot', 'amber vole'
]
let name  = names[Math.floor(Math.random()*3000)]
// console.log("🚀 ~ name:", name)


export default name