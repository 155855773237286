import Banner from "./components/Banner";
import TimeLine from "./components/TimeLine";
import InterviewTypes from "./components/InterviewTypes";
import MeetUsers from "./components/Tools";
import {Helmet} from 'react-helmet'


const Index = () => {
    return (
        <div>
                <title>Peerfect: Practice mock interviews</title>
    <meta name="description" content="Ace Your Interviews, Secure Your Future: Free online mock interview Practice for Your Dream Job with the perfect peer" />

                  <Banner/>
<InterviewTypes/>
<TimeLine/>
<MeetUsers/>
        </div>
    );
}

export default Index;
