import React from "react";
import "./TimeLine.css"; 
import account from "/account.webp";
import interview from "/interview.webp";
import find from "/find.png";
import success from "/success.webp";

import Typography from "@mui/material/Typography";
export default function TimeLine() {
  return (
    //
    <div className="flex-container">
      <Typography
        variant="h2"
        gutterBottom
        sx={{ fontWeight: "regular", mb: 3, fontSize: 45 }}
      >
        How It Works?
      </Typography>
      <div className="flex-item">
        <div className="timeline-text">
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: "regular", fontSize: 35, color: "#4960A7" }}
          >
            Create Your Profile
          </Typography>
          {/* <h2 class="timeline__title">Create Your Profile</h2> */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 350, fontSize: 21 }}
          >
            Complete your profile by adding your information, so that other
            users can view your profile and send you interview requests
          </Typography>
        </div>
        <div className="time-img">
          <img loading="lazy" src={account} alt="Create profile image" />
        </div>
      </div>
      <div className="flex-item">
        <div className="time-img">
          <img loading="lazy" src={find} alt="Find interview partner image" />
        </div>

        <div className="timeline-text">
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: "regular", fontSize: 35, color: "#4960A7" }}
          >
            Find the perfect Interview partner
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 350, fontSize: 21 }}
          >
            Search for a suitable user to practice a mock interview with, and
            initiate the request by providing the details of the interview or
            wait for other users to send you interview requests
          </Typography>
        </div>
      </div>

      <div className="flex-item">
        <div className="timeline-text">
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: "regular", fontSize: 35, color: "#4960A7" }}
          >
            The Mock Interview
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 350, fontSize: 21 }}
          >
            Participate in a 45-60 minutes mock interview with your partner.{" "}
            <br />
            After the interview, provide constructive feedback to each other to
            enhance your skills, and leave a review for other users to see
          </Typography>
        </div>
        <div className="time-img1">
          <img loading="lazy" src={interview} alt="Mock interview image" />
        </div>
      </div>

      <div className="flex-item">
        <div className="time-img1">
          <img loading="lazy" src={success} alt="Success image" />
        </div>
        <div className="timeline-text">
          <Typography
            variant="h2"
            gutterBottom
            sx={{ fontWeight: "regular", fontSize: 35, color: "#4960A7" }}
          >
            From Good to Great Becoming a Master
          </Typography>

          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: 350, fontSize: 21 }}
          >
            Improve your interviewing skills through peer feedback, build
            confidence and master the art of interviewing. Keep honing your
            skills until you shine in every interview. Impress recruiters and
            secure job offers with your polished interviewing abilities
          </Typography>
        </div>
      </div>
    </div>
  );
}
