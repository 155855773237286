import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  
  getAdditionalUserInfo,
} from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import useAnalytics from "src/assets/useAnalytics"

export const UseSignByGoogle = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const {logEvent,identify} = useAnalytics()

  const signByGoogle = async (path,setNewUser) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const { isNewUser } = getAdditionalUserInfo(result);
      console.log("isNewUser", isNewUser);
      const user = result.user;
      //   const updateResult = await updateProfile(user, { displayName });

      if (isNewUser) {
        identify(user.uid,{email:user.email,name:user.displayName})
      logEvent('Sign up', {
        'google':true,
        referee: document.referrer 
      });
    } else {
     
      logEvent('Log in',{
        'google':true
      });
   
    }
  
      dispatch({ type: "LOGIN", payload: user });
let pathTo ='/'
      if (isNewUser) {
        setNewUser(true)
        pathTo = "/setup"
        console.log('updating profile')
        dispatch({ type: "profile", payload: {privateData:{acceptedUsers:[],ignoredUsers:[]},userData:{previousCodingProblems:[]}} });
      }else{
        console.log('why didnot update')
      }
      console.log(path)
      if(path.includes('meet')){
         console.log('meet')

          navigate(path);
        }else{
          console.log('main page')
          navigate(pathTo, {routerState:{
            newUser:true
          }});
        }
    } catch (error) {
      console.log("error", error);
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.customData.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
    }
  };

  return { signByGoogle };
};
