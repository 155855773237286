import React from "react";
import { useState, useEffect,useRef  } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//steper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

//file imports
import ModalForUserInfo from "./Modal1Background";
import ModalForInterviewsInfo from "./Modal2YourSelf";
import ModalSessions from "./Modal4Availability";
import ModalUserGoals from "./Modal3Interview";

//loading button for submit
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalContainer = ({ user, setUser, open,submitData ,handleClose,document,loading,success,setActiveStep,
  activeStep}) => {

  

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  

  

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  
  const [modalSteps, setModalSteps] = useState([
    "Your Background",
    "Your Goals",
    "Interview Info",
    "Your Availability ",
  ])

 


  ///loading buutton


  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };



  
  return (
    <div>

      <BootstrapDialog
        maxWidth={activeStep === 3? 'md' :'sm'}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        <form onSubmit={submitData} >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {modalSteps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              //
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </BootstrapDialogTitle>

        {/* modal main stuff */}
        <DialogContent dividers sx={{bgcolor:activeStep === 3?'#f5f5f5':'white'}}>
            {activeStep === 0 &&(

                <ModalForUserInfo user={user} setUser={setUser} document={document}/>
            )}
            {activeStep === 2 &&(

                <ModalUserGoals user={user} setUser={setUser} />
            )}
            {activeStep === 1 &&(

                <ModalForInterviewsInfo user={user} setUser={setUser} />
            )}
            {activeStep === 3 &&(

                <ModalSessions user={user} setUser={setUser} />
            )}
        </DialogContent>

        {/* modal footer */}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
         
          >
            Back
          </Button>

            {activeStep === modalSteps.length - 1 ?
            //  <Button onClick={submitData}>  Submit </Button>  
            <Box sx={{ m: 1, position: 'relative' }}>
          { !success && <Button
              variant="contained"
              sx={buttonSx}
              type="submit"
              disabled={loading}
             
            >
              Submit
            </Button>}
          { success && <Button
              variant="contained"
              sx={{bgcolor: "#4caf50"}}
              disabled={loading}
              type="submit"
            
            >
              Success
            </Button>}
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
    
    :<Button type="submit" > Next</Button> }
          
        </DialogActions>
    </form>
      </BootstrapDialog>

    </div>
  );
};

export default ModalContainer;
