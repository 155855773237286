const firebaseConfig = {
    apiKey: "AIzaSyCFMjBhgrbklXt2A1QhVGNp3cDnayh0k_0",
    authDomain: "peerfect.net",
    projectId: "jobready-fce4e",
    storageBucket: "jobready-fce4e.appspot.com",
    messagingSenderId: "306506681285",
    appId: "1:306506681285:web:748e626e3065787e5a59ac",
    measurementId: "G-403L3GB1RH",
 
  };

export default firebaseConfig