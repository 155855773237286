import { doc, updateDoc,arrayUnion,arrayRemove} from "firebase/firestore";
import { useState   } from "react";

import { db } from "../firebase/config";

export function useUpdate(collection){

    const [success, setsuccess] = useState(false)
    const [loading, setloading] = useState(null)
    const [updateError, setupdateError] = useState(null)
    const update= async (docId,updatedContent)=>{

        console.log('running',docId,updatedContent)
        setloading(true)
        const docRef = doc(db, collection, docId);
        try{
           const res= await updateDoc(docRef,updatedContent)
            console.log(res)
            setsuccess(true)
            console.log("success")
        }
        catch(err){
            console.log(err)
            console.log('not working')
            setsuccess(false)
        }
        
    }
    const documentArrayPush = async (docId, property,update)=>{
        const docRefs = doc(db, collection, docId);
        try{
    
          const response = await updateDoc(docRefs,   { 
             [property]: arrayUnion(update)
            })
        }catch(err){
          console.log(err)
        }
       
      }
    const ArrayDelete = async (docId, property,update)=>{
        const docRefs = doc(db, collection, docId);
        try{
    
          const response = await updateDoc(docRefs,   { 
             [property]: arrayRemove(update)
            })

        }catch(err){
          // console.log(err)
        }
       
      }
    return {update,success,loading,setloading,documentArrayPush,ArrayDelete}

}