import React,{useState} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styles from "./Reviews.module.css"

import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import ReviewCard from '../../../Components/reviews/ReviewCard';
import ReviewItem from './ReviewItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
export default function Reviews({handleClose,open,user,setmodal}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
         <Dialog
         fullScreen={fullScreen}
         maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose}>
        
          {user.rating.rating&& `Average user rating ${user.rating.rating.toFixed(1)} out of 5`}
          <Typography variant="overline" display="block" gutterBottom>
        {user.rating.numberOfReviews} ratings

      </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        {console.log(user.reviews.length)}
        {user.reviews.length === 0&&(

      <div style={{ paddingLeft:50}}>No reviews Yet</div>
        )}

        <DialogContent>
            <div className={styles.Reviews}>
            <div className={styles.ReviewsList}>
          {/* loop through users reviews */}
          {user.reviews.length> 0 && user.reviews.map((review)=>(
            <div>
              {review.review &&(

              <ReviewItem review={review}/>
              )}

            </div>
          ))}
            </div>
            </div>
       
        </DialogContent>


        <DialogActions>
          <Button onClick={handleClose}>close</Button>
          {/* <Button onClick={() => {
            handleClose()
            setmodal(true)}
            
            } >
            Choose
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
    
  )
}
