import React from "react";
import { useState, useEffect } from "react";
import { nextDay } from "date-fns";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

//select
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

//radio
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

//auto complete
import Chip from "@mui/material/Chip";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { languages } from "humanize-duration";

import jobList from "../../../assets/jobList";
import companyList from "../../../assets/companyList";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalForInterviewsInfo = ({ user, setUser }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [mixWidth, setMinWidth] = useState("sm");

  const filter = createFilterOptions();

  let list = companyList.map((item) => {
    return { title: item };
  });

  useEffect(()=>{
    // const filteredField = field.filter((item)=>item.name === user.field) 
    // setcurrentField(filteredField[0])
    // console.log(filteredField,'field')
  },[])
  return (
    <div>
      <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div className="modal-modal">
 
   
             
       
                  <Autocomplete
          sx={{ ml: 1, mt: 2 }}
          required
          value={user.targetRole}
          onInputChange={(event, newValue) => {
            setUser({ ...user, targetRole: newValue })
     
           }}
           
          onChange={(event, newValue) => {
            setUser({
              ...user,
              targetRole: newValue.inputValue || newValue.title,
            });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="target-role"
          options={jobList}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField required {...params} label="What is your target role" />
          )}
        />
        <Autocomplete
          sx={{ ml: 1, mt: 2, mb: 1 }}
          value={user.targetCompany}
          onInputChange={(event, newValue) => {
            setUser({ ...user, targetCompany: newValue })
           }}
           
          onChange={(event, newValue) => {
            setUser({
              ...user,
              targetCompany: newValue.inputValue || newValue.title,
            });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            console.log("🚀=filtered:", filtered);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="target-role"
          options={list}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="What is your target company"
              helperText="if you are not applying to specfic companies leave it empty "
            />
          )}
        />



                   
          <TextField sx={{ml:1,mt:1.5}}
        value={user.aboutMe}
          id="About me" 
        onChange={(e) => setUser({...user,aboutMe:e.target.value})}
          label="About me"
          multiline
          //   defaultValue="Default Value"   
          />
 
            <FormHelperText sx={{ml:1}}>
                    Add introduction about yourself, your goals and any other information you would like the other users to know
                  </FormHelperText>
     
          </div>
        </Box>
     
      </div>
    </div>
  );
};

export default ModalForInterviewsInfo;
