import React from 'react'
import  {useAuthContext}  from "../../hooks/useAuthContext";
import { useState, useEffect } from "react";



import "./components/Interviews.css"
import InterviewsPage from './InterviewsPage';
import 'intro.js/introjs.css';


export default function Index() {

  return (
    <div className='interview-page'>
        <div className='container' style={{backgroundColor:'#f5f6fa'}}>
        <header>

        <h1>My Interviews</h1>
        </header>
        < InterviewsPage/>
        </div>
    </div>
  )
}
