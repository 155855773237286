import React,{useState,useEffect} from 'react';
import { Button, Container, TextField, Typography,FormHelperText } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import jobList from "assets/jobList"
const Aboutme = ({user,setUser,handleNext,loading,handleSubmit,setIsStudent,isStudent}) => {
 

    return (
        <>
                    
                    <Typography variant="h3" gutterBottom sx={{ fontSize: "40px", textAlign: "center", marginBottom: "20px" }}>
        Add a short bio     
      </Typography>

                         
      <TextField 
    //   sx={{ml:1,mt:1.5}}
    sx={{ mx: 'auto', width: '100%', maxWidth: 1200 }}
    value={user.aboutMe}
    id="About me" 
    onChange={(e) => setUser({...user, aboutMe: e.target.value})}
    // label="About me"
    multiline
    InputProps={{
        style: {
            fontSize: '20px', // Adjust the font size as needed
        },
    }}
    //   defaultValue="Default Value"   
/>
 
            <FormHelperText   sx={{
          '.MuiInputBase-input': { fontSize: 24 },
          '& .MuiAutocomplete-inputRoot': {
              minWidth: 375,
              borderColor: '#ccc',
              borderRadius: '5px',
          },
      }}>
                    Add introduction about yourself, your goals and any other information you would like the other users to know
                  </FormHelperText>
     
        </>
    );
}

export default Aboutme;
