import React,{useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Skeleton from '@mui/material/Skeleton';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import styles from './MainSkeleton.module.css'
const drawerWidth = 240;

  
const MainSkeleton = () => {

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
 
    const drawer = (
    <div
    style={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <div>
      <Toolbar />
      <Divider />
      <List>
      <List>
          {Array.from({ length: 5 }).map((_, index) => (
            <ListItem key={index} button>
              <ListItemText primary={<Skeleton variant="text" />} />
            </ListItem>
          ))}
        </List>
      </List>
      <Divider />
      <List>
          {Array.from({ length: 2}).map((_, index) => (
            <ListItem key={index} >
              <ListItemText primary={<Skeleton variant="text" />} />
            </ListItem>
          ))}
        </List>
      <Divider sx={{ mb: "auto" }} style={{ marginBottom: "auto" }} />
     
    </div>
    <div>
    <ListItem  >
              <ListItemText primary={<Skeleton variant="text" />} />
            </ListItem>
    </div>
  </div>
);
let window
const container =
  window !== undefined ? () => window().document.body : undefined;

return (
    <div sx={{ display: 'flex' }}>

  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
    <CssBaseline />

    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        variant="temporary"
     
        // onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  </Box>

  <main sx={{ flexGrow: 1, p: 3 }}>

    <div style={{height:65,backgroundColor:'white',width:'100vw'}}>

    </div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh'}}>
        <div className={styles.hourglass}></div>
        </div>
      </main>
  </div>

);
}


export default MainSkeleton;
