import { mixpanel } from '../firebase/mixPanel';
import { clarity } from 'react-microsoft-clarity';
import { usePostHog } from 'posthog-js/react';

// Custom hook to use PostHog
const useAnalytics= () => {
const posthog = usePostHog();


const logEvent = (event, object = {}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    try {
        mixpanel.track(event, object);
    } catch (error) {
        console.log("🚀 ~ capture mixpanel ~ error:", error)
        
    }
    try {
        posthog.capture(event, object);
    } catch (error) {
        console.log("🚀 ~ capture posthog ~ error:", error)
        
    }
};

const identify = (id,data) => {
    try {
        mixpanel.identify(id);
        if(data){
            //update key on object
            let clone = {name:data.name,"$email":data.email}
            mixpanel.people.set(clone);
        }
    } catch (error) {
        console.log("🚀 ~ identify mixpanel ~ error:", error)
    }
    try {
        posthog.identify(id,data || {});
    } catch (error) {
        console.log("🚀 ~ identify postHog ~ error:", error)
        
    }
    try{
        if(data.email){
            clarity.identify(id,data.email);
        }else{

            clarity.identify( id);
        }
    }catch (error) {
        console.log("🚀 ~ identify clarity ~ error:", error)
        
    }
}

return  {logEvent, identify};
}

export default useAnalytics;