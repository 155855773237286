import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import { Steps, Hints } from 'intro.js-react';

import {
  Collapse,
  TableCell as MuiTableCell,
} from '@mui/material'
import { Link } from "react-router-dom";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Tablecollpase ({row,index,accept,handleDelete,loading,success}){
  const [open, setOpen] = useState(false);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };
  return(
    <>
     <StyledTableRow >
     <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                <Link to={`/profile/${row.userId}`}> {row.userName}</Link>  
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center">
                <> {row.role}</>  
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                  <>{row.when}</>
                </StyledTableCell>
                {/* <StyledTableCell component="th" scope="row">
                  <>{row.when}</>
                </StyledTableCell> */}

                <StyledTableCell align="center">
                  {row.InterviewType.toUpperCase() }  {row.InterviewType.toLowerCase().includes("interview")?"":"Interview".toUpperCase() }
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.topic?(<>{row.topic}</>):<></>}
            
                </StyledTableCell>
                <StyledTableCell align="right">
                  <>
                  <Box sx={{ m: 1, position: "relative" }}>
                {true && (
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={(e) => accept(index)}
                    disabled={loading === index}
                  >
                    Accept
                  </Button>
                )}
                {loading === index && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
                    <Button
                      variant="outlined"
                      onClick={(e) => handleDelete(index)}
                      >
                      ignore
                    </Button>
                  </>
                </StyledTableCell>
                
              </StyledTableRow>
               <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
              {row.interviewDetails}
              </Typography>
             
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
           
    </>
  )
}
export default function NotificationCard({ documents, handleDelete, accept,loading,success }) {
  const [list, setlist] = useState(null);

  
  useEffect(() => {
    const rows = documents.map((doc) => {
      const formatedDate = new Date(doc.time.timestamp).toDateString() 
      const formatedTime = new Date(doc.time.timestamp).toLocaleTimeString("en-US")
      const time = formatedDate.substring(10,0) +' at ' +formatedTime.replace(':00 ',' ')
  
      return {
        userId:doc.userSendingRequestId,
        userName:doc.userSendingRequestName,
        when: time,
        Field: doc.Field,
        InterviewType: doc.interviewType,
        interviewDetails:doc.interviewDetails,
        role:doc.role,
        topic:doc.topic
      };
    });
    setlist(rows);
  }, [documents]);


  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sortDirection="desc"></StyledTableCell>
            <StyledTableCell sortDirection="desc">User</StyledTableCell>
            <StyledTableCell sortDirection="desc" align="center">Role</StyledTableCell>
            {/* <StyledTableCell sortDirection="desc">Target Role</StyledTableCell> */}
            <StyledTableCell sortDirection="desc" align="center">When</StyledTableCell>
            <StyledTableCell align="center">Interview Type</StyledTableCell>
            <StyledTableCell align="center">Topic</StyledTableCell>
            <StyledTableCell align="center">action</StyledTableCell>
          </TableRow>
        </TableHead>
        {list && (
          <TableBody>
            {list.map((row, index) => (
        
    <Tablecollpase key={index} row={row} index={index} accept={accept} handleDelete={handleDelete} success={success} loading={loading}/>
        
            ))}
            
          </TableBody>
        )}
        
        </Table>
        
        </TableContainer>
   
                      </>
  );
}
