import React,{useState,useEffect} from 'react';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import jobList from "assets/jobList"
const Role = ({user,setUser,handleNext,loading,handleSubmit}) => {
    let text = "software developer"
    let delay = 100
    let infinite  = true
    const [placeHolder,setPlaceHolder] =useState('')
    const [currentText, setCurrentText] = useState('');
    const filter = createFilterOptions();
    const  [words,setWords] = useState([ "Software developer     ",
    "Data scientist     ",
    "UI designer     ",
    "Product Manager     ",
    "Doctor     ",
    "Lawyer     ",
    "Engineer     ",
    "Teacher     ",
    "Nurse     ",
    "Pilot    "])
     
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentIndex < words[currentWordIndex].length) {
        setCurrentWord(prevWord => prevWord + words[currentWordIndex][currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      } else {
        setCurrentIndex(0);
        setCurrentWord("");
        setCurrentWordIndex(prevIndex => (prevIndex === words.length - 1 ? 0 : prevIndex + 1));
      }
    }, delay);

    return () => clearInterval(timer);
  }, [currentIndex, currentWordIndex, delay, words]);


    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
                    
                    <Typography variant="h3" gutterBottom sx={{textAlign:"center"}}>
        I want to practice For 
      </Typography>
            {/* </Container> */}

            <Autocomplete
        sx={{ ml: 1, mt: 2 }}
        required
      value={user.targetRole}
      onInputChange={(event, newValue) => {
       setUser({ ...user, targetRole: newValue })

      }}
      
      onChange={(event, newValue) => {
        if(newValue){

          setUser({ ...user, targetRole: newValue.inputValue || newValue.title })
          handleNext(null,null, newValue.inputValue || newValue.title)
        }
      }}
      
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        // console.log("🚀isExisting:", isExisting)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      freeSolo
      handleHomeEndKeys
      id="target-role"
      options={jobList}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      variant="standard" 
      
      
      renderInput={(params) => (
        <TextField  required {...params} value={user.role}  placeholder={currentWord} label="" variant="standard"   helperText="type what role you want to practice interviews for"
        sx={{
          maxWidth:460,
            '.MuiInputBase-input': { fontSize: 36 },
          }}
        />
        
      )}
    />
        </Box>
    );
}

export default Role;
