import { useState, useEffect,useRef } from "react";
import * as React from 'react';
import "./Modal.css";
import Backdrop from "@mui/material/Backdrop";

import { useUpdate } from "../../../hooks/useUpdate";

import ModalContainer from "./ModalContainer";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {mixpanel} from '../../../firebase/mixPanel'
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";
import useAnalytics from "src/assets/useAnalytics"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const humanizeDuration = require("humanize-duration");

export default function ModalForm({ setModal }) {
  const navigate =useNavigate()
  const [timeSinceStarting,settimeSinceStarting]=useState(Date.now())
  const {update,success,loading,setloading} = useUpdate("users")
  const { user:contextUser,userData:document,dispatch } = useAuthContext();
  const [activeStep, setActiveStep] = useState(0);
  const [firstTime,setFirstTime]=useState(false)
  const [opensnack, setOpensnack] = useState(false);
  
  const handleClosesnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpensnack(false);
  };
  const {logEvent,identify} = useAnalytics()


  // targetRole:[]
  //targetCompany:[]
  // educationLevel:"",
  // areaOfStudy:"",
  // university:"",
  // currentPosition:"",
  // currentCompany:"",
  // year of experience
  // interviewTypes:,
  // topics:,
  // aboutMe:"",
  const [user, setUser] = useState({
    displayName:contextUser.displayName,
    educationLevel:"",
    areaOfStudy:"",
    university:"",
    bootCampGraduate:false,
    bootCamp:"",
    currentPosition:"",
    currentCompany:"",
    aboutMe:"",
    topics:[],
    Certifications:[],
    targetRole:'',
    targetCompany:"",
    experience:"",
    interviewTypes:[],
    availabilty:[],
    category:'',
    subCategory:'',
    rating:{
    rating:0,
    numberOfReviews:0,
   totalRatingNotDevided:0,
    },
    reviews:[],
    camera:true,
    timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
    interviews:0,
    costumInterviews:false,
    codingDifficulty:null,
    codingLevel:null,
    previousCodingProblems:[],...document
  });

  // 

  
  const [open, setOpen] = useState(true);
  const handleClose = () => {
  
    setOpen(false);
      setModal(false);
  
  };

  const timer = useRef();

  const handleNext = () => {

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    
  };

  const submitData = async (e) => {
    e.preventDefault()
  
    if(activeStep === 3){
      setloading(true)
      const obj = user
      let diffenceTimeStamp= Date.now()-timeSinceStarting
      const diffrence = humanizeDuration(diffenceTimeStamp,{ largest: 1,round: true  })
      logEvent('update Setup',{
          'Profile Update Time':diffrence,
        'Profile Update Time in seconds':Math.floor(diffenceTimeStamp/1000)
      })
     
      obj.profile=true
      obj.active=true
      obj.activeDuration = Date.now() + 604800000; // make the account active false if time reach to this, active stays active for a week, everytime users login we refresh this duration

      console.log(obj)
      const functions = getFunctions();
      try{
        let now = Date.now()
        if(obj.targetRole!== document.targetRole){

          const {data} = await axios.post('https://peerfect-api-production.up.railway.app/role',{role:obj.targetRole})

          console.log(" data: gotten from the openai", data);
          let categorization = JSON.parse(data.response)
          obj.category = categorization.category
          obj.subCategory = categorization.subCategory
          console.log(JSON.parse(data.response))
          console.log((Date.now()-now)/1000)
        }
    
      }catch(err){

      }
      console.log(obj)
     await update(contextUser.uid,obj)
  
      // // con sole.log(user)
     let privateData ={
      acceptedUsers:[],
      ignoredUsers:[],
      absences:0
      }
      dispatch({ type: "profile", payload: {privateData,userData:obj} });

          timer.current = window.setTimeout(() => {
          setloading(false)
          
          setTimeout(() => {
            
            handleClose()

           
          }, 500);
        }, 500);
      
    
      
    }else{
      handleNext()
    }
    // addDocument(user);
    // console.log(repsonse);
  };
  const childprops = {
    success,
    loading,
    document,
    user,
    setUser,
    submitData,
    handleClose,
    open,
    setActiveStep,
    activeStep,
    handleNext
  };

  useEffect(() => {
    
    console.log(user);

    return () => {
      clearTimeout(timer.current);
    };
  }, [user]);

  useEffect(() => {
    if(document.profile){
      setUser({...document,timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone})
    }else{
      console.log('first')
      setFirstTime(true)
    }
  }, [])
  
 
  return (
    <>
      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      ></Backdrop>
      {/* { Pages[currentpage] === 0 &&  < Modal {...childprops} />}
{ Pages[currentpage] === 1 &&    < ModalSession {...childprops} />} */}
      <ModalContainer {...childprops} />
      {/* { Pages[currentpage] === 1 &&    < TimesModal {...childprops} />} */}
      {/* <Modal  {...childprops} /> */}
      {/* <ModalSession {...childprops}/> */}
      {/* < ModalForm/> */}

    </>
  );
}
