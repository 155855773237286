import { useState, useEffect } from "react";
//images
import "./CardComponent.css";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import jobTarget from '/target-job.png'
import company from '/company.png'

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { red, green } from "@mui/material/colors";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";


import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
//chip
import Chip from "@mui/material/Chip";

//icons
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";


import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Button } from "@mui/material";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

let schoolsfdsf = "warior" 
export default function CardComponent({ item, changeUser, setmodal,handleClickOpen,edit,setModal,publicProfile }) {
  console.log("🚀 ~ CardComponent ~ item:", item.real)

  const [imageLoaded, setImageLoaded] = useState(false);
  function capitalizeWords(input="hello") {
    return input.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

let targetRole = capitalizeWords(item.targetRole)
console.log("🚀 ~ CardComponent ~ item.availability:", item.availability)

  return (
    <>
      {/* {console.log(item)} */}
      <div className="card-user-container">
        {/* <CardActionArea onClick={()=> setmodal(true)}> */}
        <div className="card-top">
          <div className="user-icon">
        
            {(!imageLoaded || item.photoURL) &&     <Avatar
              sx={{ display: "inline-flex", width: 80, height: 80 }}
              aria-label="recipe"
              src={item.photoURL}
            ></Avatar>}

         {!item.photoURL&&    <img onLoad={() => setImageLoaded(true)}
 style={{ display: "inline-flex"}}  src={`https://robohash.org/${item.displayName}.png?size=100x100`} ></img>}
          </div>

          <div className="user-name">
            <h2>{item.displayName.length >17 ?item.displayName.split(" ")[0]:item.displayName}</h2>
          </div>
          <div className="user-rating" onClick={handleClickOpen} style={{cursor:'pointer'}}>
            <Rating
            // sx={{textAlign:'center'}}
            // precision={0.5} 
              name="text-feedback"
              value={item.rating.rating}
              readOnly
              
            />
            <span style={{ fontWeight: 100 }}>{`(${
              item.rating.numberOfReviews ? item.rating.numberOfReviews : 0
            })`}</span>
          </div>

          <div className="user-field">
            <h5 style={{fontSize:'1.1rem' }}>{targetRole}</h5>
          <Tooltip
              title={"target Role"}
              placement="right"
            >

            <img src={jobTarget} alt="here" style={{width:35,marginLeft:5}}/>
            </Tooltip>
          </div>
          <div className="user-experience">
            <h5 style={{fontSize:'1rem' }}>{item.targetCompany?.length>0 && item.targetCompany}</h5>
           {item.targetCompany?.length>0 && <Tooltip
              title={"target company"}
              placement="right"
            >

            <img src={company} alt="here" style={{width:35,marginLeft:5}}/>
            </Tooltip>}

          </div>
          <div className="user-interviews">

        {item.currentPosition &&<h5>
         {item.yoe >=7&&(
      <Box sx={{ color: 'error.main' }}>YOE: {item.yoe}</Box>
         )} 
         {(item.yoe >3 &&item.yoe <7)&&(
      <Box sx={{ color: 'warning.main' }}>YOE: {item.yoe}</Box>
         )} 
         {item.yoe <=3&&(
      <Box sx={{ color: 'success.main' }}>YOE: {item.yoe}</Box>
         )} 
       </h5>  }
          </div>
       
          <div className="user-rank">
          {/* <Tooltip
              title={item.camera ? `this user will turn on his camera on interviews`:`this user will not turn on his camera on interviews`}
              placement="right"
            >
              {item.camera  ? <VideocamIcon  sx={{ color: "#4960A7" }} /> : <VideocamOffIcon  sx={{ color: "#4960A7" }}/>}
            </Tooltip> */}
           {item.codeWars && <>
           <span style={{ cursor: "pointer" }}>
              <Tooltip
                title="This rank is coming from code wars, connect your account to show your skills level"
                placement="right"
              >
                <MilitaryTechIcon sx={{ color: "#4960A7" }} />
              </Tooltip>
            </span>
            <h5 style={{ marginLeft: 4 }}>{item.codeWars?item.codeWars.honor:0}</h5></>}
          </div>
        </div>
        <div className="cardinfo">
          <div className="education-work">
           {item.currentPosition&& <div className="education" >
           <Tooltip
                title="Current Job"
                placement="right"
              >

              <WorkIcon sx={{ color: "#4960A7" }} />
              </Tooltip>
              <h5 style={{fontSize:'0.9rem' }}>
                {item.currentPosition}
                <br />
                <span style={{ fontWeight: 300, textAlign: "center" }}>
                  {item.currentCompany}
                </span>
              </h5>
            </div>}

     {item.educationLevel &&  <div className="work">

     <Tooltip
                title="Education"
                placement="right"
              >


              <SchoolIcon sx={{ color: "#4960A7" }} />
              </Tooltip>
              <h5 style={{margin:0,fontSize:'0.9rem' }}>
                {item.educationLevel} {item.areaOfStudy&& "in"} {item.areaOfStudy} <br />
              </h5 >
                <span style={{ fontWeight: 300, textAlign: "center",fontSize:'0.9rem' }}>
                {(item.university !== "" && item.university ) && `${item.university}`}  
                </span>
                <span style={{ fontWeight: 300, textAlign: "center" ,fontSize:'0.8rem'}}>
          
                </span>
            </div>}
          </div>
          {item.aboutMe && (
            <>
              <Typography

                variant="h6"
                gutterBottom
                sx={{ fontSize: 17, mt: 1 }}
              >
                About me
              </Typography>
              <p style={{textAlign:'left'}}>{item.aboutMe}</p>
            </>
          )}


{            console.log("🚀 ~ CardComponent ~ item.topics:", item)
}        {item.topics?.length>0&&  <Typography variant="h6" gutterBottom sx={{ fontSize: 17, mt: 2 }}>
            Topics
          </Typography>}
          <div className="chips">
            {item.topics &&
              item.topics.map((frame) => (
                <Chip
                  sx={{ m: 0.5 }}
                  label={frame}
                  key={frame}
                  variant="outlined"
                  size="small"
                />
              ))}
   
            {item.skills &&
              item.skills.map((frame) => (
                <Chip
                  sx={{ m: 0.5 }}
                  key={frame}
                  label={frame}
                  variant="outlined"
                  size="small"
                />
              ))}
          </div>
   
          {item?.interviewTypes?.length>0 &&(
            <>
              <Typography variant="h6" gutterBottom sx={{ fontSize: 17, mt: 2 }}>
              Interview Types Interested on:
          </Typography>
           <div className="chips">
            {item?.interviewTypes?.map((frame) => (
                <Chip
                  sx={{ m: 0.5 }}
                  label={frame}
                  key={frame}
                  variant="outlined"
                  size="small"
                />
              ))}

          </div>
            </>
          )}
       
          <Typography variant="h6" gutterBottom sx={{ fontSize: 17 }}>
            Availibilty
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 1,
              pb: 1,
              mb: "auto",
            }}
          >
            <Stack direction="row" spacing={1}>
     
              {item.availability &&
                item.availability.map((day) => (
                  <Avatar
                  
                    key={day.day}
                    sx={{
                      bgcolor: day.hour.length > 0 ? green[500] : red[500],
                      width: 30,
                      height: 30,
                    }}
                  >
                    <Typography variant="caption">
                      {day.day.split(" ")[0]}
                    </Typography>
                  </Avatar>
                ))}
            </Stack>
          </Box>
          {/* </CardActionArea> */}
        </div>
      {!edit &&  <div className="user-buttons">
          <button className="skip" onClick={() => changeUser(item)}>
            <ClearIcon sx={{ height: 35, width: 35 }} />{" "}
          </button>
          <button className="interview" onClick={() => setmodal(true)}>
            <DoneIcon sx={{ height: 35, width: 35 }} />
          </button>
        </div>}
        {publicProfile && ( <div className="user-buttons">
          <button className="interview" onClick={() => setModal(true)}>
            <DoneIcon sx={{ height: 35, width: 35 }} />
          </button>
        </div>)}
        {edit === 'edit'&&<div className="user-buttons">
        <Button className="interview" onClick={() => setModal(true)}>
            Update
          </Button>
          </div>}
      </div>
      {/* {modal && <UserModalContainer item={item} open={modal} handleClose={closeModal}/>} */}
      {/* <UserModalContainer/> */}

      {/* { documents &&    < Posts  documents={documents}/>} */}
      {/* <ModalForm/> */}
    </>
  );
}
