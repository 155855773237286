import React, { useState, useEffect } from "react";

import styles from "./Index.module.css";
import { Alert, Button, Container, TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, updateProfile } from "firebase/auth";

import jobList from "assets/jobList";
import Role from "./TargetRole";
import CurrentJob from "./CurrentJob";
import InterviewTypes from "./InterviewTypes";
import Topics from "./Skills";
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";
import { useUpdate } from "hooks/useUpdate";
import { useNavigate } from "react-router-dom";
import Education from "./Education";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrentCompany from "./CurrentCompany";
import Availability from "./Availability";
import getDocument from "hooks/useDoc";

// import random_name from "node-random-name"
import randomName from "assets/namesList"

import useAnalytics from "src/assets/useAnalytics"
import Aboutme from "./AboutMe";
import mixpanel from "mixpanel-browser";
import ExperienceLevel from "./ExperienceLevel";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 30,
    margin: "-10px 0px",
    backgroundColor: theme.palette.mode === "green" ? "green" : "#faad41",
  },
}));

const Index = ({ setProfileNotDone, setNewUser }) => {
  const { update, success } = useUpdate("users");
  const { documentArrayPush } = useUpdate("global");
  const { update:updatePrivate } = useUpdate("privateUsers");
  const [pages, setPages] = useState([
    // "experienceLevel",
    "role",
    "education",
    "experience",
    "About",
    "availability",
  ]);
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [PageAnimation, setPageAnimation] = useState("form");
  const { authIsReady, user: userAuth, dispatch, userData } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [bar, setBar] = useState(0);
  const navigate = useNavigate();
  const {logEvent,identify} = useAnalytics()

  const [isStudent, setIsStudent] = useState(false);
  const [suggestedSkills, setSuggestedSkills] = useState(null);
  const [user, setUser] = useState({
    targetRole: "",
    interviewTypes: [],
    topics: [],
    currentPosition: "",
    currentCompany: "",
    educationLevel: "",
    areaOfStudy: "",
    university: "",
    bootCampGraduate: false,
    bootCamp: "",
    yoe: 0,
    "availability": [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ]
  });
  const handleNext = async (e, student, value) => {
    if (e) e.preventDefault();
    console.log("next");
    // setLoading(true)
    logEvent("onBoarding-"+currentPage);

    if(currentPage === "experience" && user.currentPosition === "unemployed"){
      setIsStudent(true);
      handleNext(false, true);
    }
    let newPages = pages; 
    // when role answer came back we either
    // we either add "interviews or we delete it"
    if (currentPage === "role") {
      axios
        .post("https://peerfect-api-production.up.railway.app/role", { role: value || user.targetRole })
        .then(({ data }) => {
          let parsed = JSON.parse(data.response);
          console.log("🚀 ~ file: Index.js:102 ~ axios.post ~ parsed:", parsed);
          if (parsed.interview) {
            // newPages.splice(index + 1, 0, "interviews");
            // setPages(newPages);
            setUser((originalUser) => {
              return {
                ...originalUser,
                category: parsed.category,
                subCategory: parsed.subCategory,
                hasTechInterview: true,
              };
            });
          } else {
            setUser((originalUser) => {
              return {
                ...originalUser,
                category: parsed.category,
                subCategory: parsed.subCategory,
                interviewTypes: ["General Interview"],
                hasTechInterview: false,
              };
            });
          }
        });
      let docId= "6YgYuXNdYuagxtlCsPMH"
         getDocument('global',docId)
        .then(( item  ) => {
          let role = value || user.targetRole
          if(item?.roles.includes(role.toLowerCase())){
            console.log("exists")
            return
          }
          axios.post("https://peerfect-api-production.up.railway.app/role/"+role)
          .then(({data}) => {
            console.log("🚀 ~ .then ~ profile:", data)
            if(data){
              data.codingLevel = ""
              documentArrayPush(docId,"roles",role.toLowerCase())
              documentArrayPush(docId,"profiles",data)
 
            }
            // console.timeEnd("time")
          
          })
          console.log("🚀 ~ .then ~ item:", item)
        })
        .catch((err) => {
          console.log("🚀 ~ handleNext ~ err:", err)
         
        })
 
      // axios.post('http://localhost:3001/skills',{role:value}).then(({data})=>{
      //   let {topics} = JSON.parse(data.response)
      //   console.log(topics)
      //   setSuggestedSkills(topics)
      //   if(typeof topics[1] === 'object'){
      //     topics = topics.map((item)=>item.topic)
      //   }
      //   setUser((originalUser=>{
      //     return{...originalUser,skills:topics}
      //   }))
      // })
    }
    let devJobs = [
      "devops engineer",
      "software engineer",
      "software developer",
      "front-end developer",
      "back-end developer",
      "full-stack developer"
    ]

    if (currentPage === "experience") {
      let index = pages.indexOf("experience");
      if (!student) {
        console.log("is student");

        if (devJobs.includes(user.targetRole.toLowerCase())) {
          newPages.splice(index + 1, 0, "company", "topics");
        } else {
          newPages.splice(index + 1, 0, "company");
        }
        setPages(newPages);
        setUser((originalUser) => {
          return { ...originalUser, isStudent: false };
        });
      } else {
        newPages = pages;
        if (devJobs.includes(user.targetRole.toLowerCase())) {
          newPages.splice(index + 1, 0, "topics");
        } else {
          if (pages.includes("topics")) {
            console.log(pages);
            newPages = newPages.filter((item) => item !== "topics");
          }
        }
        if (pages.includes("company")) {
          console.log(pages);
          newPages = newPages.filter((item) => item !== "company");
        }
        setPages(newPages);

        setUser((originalUser) => {
          return { ...originalUser, isStudent: true };
        });
      }
    }

    // if (currentPage === "experience") {
    //   let page = student?"experience":"company"
    //   if (devJobs.includes(user.role)) {
    //     let index = pages.indexOf(page);
    //     newPages.splice(index + 1, 0, "skills");
    //     setPages(newPages);
    //   } else {
    //     if (pages.includes("skills")) {
    //       newPages = pages.filter((item) => item !== "skills");
    //       setPages(newPages);
    //     }
    //   }
    // }

    if (currentPage === pages[pages.length - 1]) {
      handleSubmit();
      return;
    }
    let currentIndex = newPages.indexOf(currentPage);
    // setCurrentPage
    let newPage = newPages[currentIndex + 1];

    setPageAnimation("nextVanish");
    await new Promise((resolve) => setTimeout(resolve, 300));
    setPageAnimation("nextAppear");
    setCurrentPage(newPage);

    if (newPage === "availability") {
      setBar(100);
    } else {
      setBar(bar + 25);
    }
  };

  const handlePrevious = () => {
    let currentIndex = pages.indexOf(currentPage);
    let newPage = pages[currentIndex - 1];
    setPageAnimation("previousVanish");
    setTimeout(() => {
      setPageAnimation("previousAppear");
      if (newPage === "role") {
        setBar(0);
      } else {
        setBar(bar - 25);
      }
      // if(currentPage ===3 && isStudent){
      // }else{
      //   setBar(bar-20)
      //   console.log(bar+20)
      // }
      setCurrentPage(newPage);
    }, 300);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
   if(user.availability.every(day => day.hour.length === 0) || currentPage !== "availability"){
    setCurrentPage("availability");
    setBar(100);
    return
   }

    if (loading) return;
    setLoading(true);
    setError(null);
    console.log(user);
    try {
      let obj = {
        displayName:randomName,
        educationLevel: "",
        areaOfStudy: "",
        university: "",
        bootCampGraduate: false,
        bootCamp: "",
        currentPosition: "",
        currentCompany: "",
        aboutMe: "",
        topics: [],
        Certifications: [],
        targetRole: "",
        targetCompany: "",
        experience: "",
        interviewTypes: ["General Interview"],
        availability: [],
        category: "",
        subCategory: "",
        rating: {
          rating: 0,
          numberOfReviews: 0,
          totalRatingNotDevided: 0,
        },
        reviews: [],
        camera: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        interviews: 0,
        costumInterviews: false,
        codingDifficulty: 4,
        codingLevel: null,
        previousCodingProblems: [],
        active:true,
        profile:true,
        activeDuration: Date.now() + 604800000,
        ...user,
      };

      if(obj.hasTechInterview){
        obj.interviewTypes = ["Behavioral Interview", "Technical Interview"]
      }
      let devJobs =  [
        "devops engineer",
        "software engineer",
        "software developer",
        "front-end developer",
        "back-end developer",
        "full-stack developer"
      ]
      if (devJobs.includes(obj.targetRole.toLowerCase())) {
        obj.interviewTypes = ["Behavioral Interview", "Technical Interview","Coding Interview","System Design"]
      }
      obj.onBoarding = true;
      console.log(obj);
      // return
      await update(userAuth.uid, obj);
      updatePrivate(userAuth.uid,{displayName:randomName})
      const auth = getAuth();
      //add profile propterties
      logEvent("onBoarding-Submit",{
        targetRole:obj.targetRole||"",
        targetCompany:obj.targetCompany||"",
        educationLevel:obj.educationLevel||"",
        areaOfStudy:obj.areaOfStudy||"",
        university:obj.university||"",
        bootCampGraduate:obj.bootCampGraduate||"",
        bootCamp:obj.bootCamp||"",
        currentPosition:obj.currentPosition||"",
        currentCompany:obj.currentCompany||"",
        experience:obj.experience||"",
        interviewTypes:obj.interviewTypes||"",
        category:obj.category||"",
        subCategory:obj.subCategory||"",
      });
      mixpanel.people.set({
        targetRole:obj.targetRole||"",
        targetCompany:obj.targetCompany||"",
        educationLevel:obj.educationLevel||"",
        currentPosition:obj.currentPosition||"",
        experience:obj.experience||"",
        category:obj.category||"",
        subCategory:obj.subCategory||"",
        displayName: randomName
      })
       updateProfile(auth.currentUser, {
        displayName: randomName,
      });
      console.log("runing");
      let privateData = {
        displayName:randomName,
        acceptedUsers: [],
        ignoredUsers: [],
        absences: 0,
      };
      const functions = getFunctions();
      const questionsGenerate = httpsCallable(
        functions,
        "generatePracticeQuestions"
      );
      questionsGenerate({ ...obj, userId: userAuth.uid });
      dispatch({ type: "profile", payload: { privateData, userData: obj } });
      setProfileNotDone(false);
      setNewUser(false);
      navigate("/browse");
      console.log("success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };

  var props = {
    isStudent,
    setIsStudent,
    handleSubmit,
    user,
    setUser,
    handleNext,
    handlePrevious,
    loading,
    suggestedSkills,
  };

  return (
    <div className={styles.container}>
      {/* <Container> */}

      <div className={styles.filler}></div>
      <div className={styles.top}>
        {currentPage !== "role" && (
          <div onClick={handlePrevious} style={{ cursor: "pointer" }}>
            <ArrowBackIcon sx={{ height: 30, width: 30 }} />
          </div>
        )}
        <div className={styles.bar}>
          <BorderLinearProgress variant="determinate" value={bar} />
        </div>
      </div>

      <form
        onSubmit={bar < 100 ? handleNext : handleSubmit}
        className={`${styles[PageAnimation]} ${styles.form}`}
      >
        {/* <Availability {...props}/> */}
        {/* const [pages,setPages] =useState(["role","education","experience","availability"]) */}

        {currentPage === "role" && <Role {...props} />}
        {currentPage === "education" && <Education {...props} />}
        {currentPage === "experience" && <CurrentJob {...props} />}
        {currentPage === "company" && <CurrentCompany {...props} />}
        {currentPage === "interviews" && <InterviewTypes {...props} />}
        {currentPage === "topics" && <Topics {...props} />}
        {currentPage === "About" && <Aboutme {...props} />}
        {currentPage === "availability" && <Availability {...props} />}
        {currentPage === "experienceLevel" && <ExperienceLevel {...props} />}

        <div
          className={styles.buttonContainer}
          style={{
            justifyContent:
              (currentPage === "experience" ||currentPage === "About")? "space-between" : "center",
          }}
        >
          {currentPage === "experience" && (
            <Button
              onClick={() => {
                setIsStudent(true);
                handleNext(false, true);
              }}
              type="button"
              color="success"
              variant="contained"
              sx={{ fontSize: 18, width: 200 }}
            >
              {" "}
             Skip
            </Button>
          )}
          {currentPage === "About" && (
            <Button
              onClick={() => {
                handleNext();
              }}
              type="button"
              color="success"
              variant="contained"
              sx={{ fontSize: 18, width: 200 }}
            >
              {" "}
              Skip
            </Button>
          )}

          <Button type="submit" variant="contained" sx={{ fontSize: 18 }} disabled={(currentPage === "availability"&&user.availability.every(day => day.hour.length === 0))}>
            {currentPage !== "availability" && "Next"}
            {currentPage === "availability" && loading && "..."}
            {currentPage === "availability" && !loading && "Submit"}
          </Button>
        </div>
      </form>
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          something wrong happened try again
        </Alert>
      )}
    </div>
  );
};

export default Index;
