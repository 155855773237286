import React from "react";
import { useState, useEffect,useRef  } from "react";
import styles from "./Modal.module.css";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {default  as RightArrow} from '@mui/icons-material/KeyboardDoubleArrowRight';
import  {default  as LeftArrow} from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  addDays,
  addHours,
  format,
  getHours,
  getMinutes,
  getUnixTime,
} from "date-fns";



const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalSessions = ({ user, setUser, submitData, setModal }) => {


  const theme = useTheme();

  const now = new Date();

  const [weekdays, setweekdays] = useState([]);
  const [chosenhours, setChosenhours] = useState([]);
  // const {user} = useAuthContext()
  const [days, setdays] = useState([
    "",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]);

  const createdays = () => {
    const week = [];

    /// first argument how many days after today, second arguemnt how many days you want to add
    for (let i = 1; i < 8; i++) {
      const nextDay = addDays(
        new Date(now.getFullYear(), now.getMonth(), now.getDate()), i);
      const times = [];

      for (let j = 8; j < 24; j = j + 1) {
        const onehour = addHours(new Date(nextDay), j);
        const HourFormated = `${format(onehour, " hh:mm a")}`;

        times.push({
          HourFormated,
          clicked: false,
        });
      }
      const object = {
        day: days[i],
        hour: times,
      };

      week.push(object);
    }
    

      setweekdays(week);
    // }
  };

  const click = (e) => {
    e.clicked = !e.clicked;
    setweekdays([...weekdays]);
    const newarray = weekdays.map((e, i) => ({
      day: e.day,
      hour: weekdays[i].hour.filter((j) => j.clicked === true),
    }));
    setChosenhours(newarray);
    setUser({ ...user, availability: newarray });
  };

  useEffect((e) => {
   
    if (weekdays.length < 6) {
      createdays();
    }

    
  }, []);

  // const hourouf = [["a", ""]];
  const containerRef = useRef(null);

  const scrollToRight = () => {
    const container = containerRef.current;
    container.scrollBy({ left: 100, behavior: 'smooth' });
  };
  const scrollToLeft = () => {
    const container = containerRef.current;
    container.scrollBy({ left: -100, behavior: 'smooth' });
  };
  return (
      <div style={{position:"relative"}}>
        <DialogContent sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems: 'center',bgcolor:'#F5F5F5',p:0,m:0}}>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ m: 0 }}
          >
            Click at the times where you will be available for mock interviews,  
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            sx={{ m: 0 }}
          >
            The other users will see those sessions and send you requests based on your availability.
          </Typography>
          <div className={styles.modalBody} ref={containerRef}>
          {/* <button type="button" onClick={scrollToRight}>Scroll Right</button> */}
          <div className={styles.arrowContainer}>
        <LeftArrow className={styles.left} onClick={scrollToLeft} />
        <RightArrow className={styles.right} onClick={scrollToRight} />
          </div>
        {/* <button type="button" onClick={scrollToLeft}>Scroll Left</button> */}

            {weekdays &&
              weekdays.map((item,i) => (
                <div key={item.day}>
                  <div style={{ position: "sticky" }}>
                    <p>{item.day}</p>
                    {/* <p>{item.day.substr(item.day.indexOf(" ") + 1)}</p> */}
                  </div>
                  {item.hour.map((e,index) => (
                    <div
                    key={`${item.day} ${e.HourFormated}`}
                    onClick={() => click(e)}
                    className={styles.time}
                    style={{
                      background: e.clicked === true ? "green" : "white",
                    }}
                    >
                      <p>
                        {e.HourFormated}
                      </p>
                    </div>
                  ))}
                </div>
              ))}

          </div>
        </DialogContent>
    </div>
  );
};

export default ModalSessions;
