import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import IconButton from "@mui/material/IconButton";
// import { collection, serverTimestamp,doc, updateDocument ,arrayUnion} from "firebase/firestore";
// import { db } from '../firebase/config';
import Tooltip from "@mui/material/Tooltip";
import NotificationCard from "./NotificationCard";
import { useCostumUpdate } from "../../hooks/useCostumUpdate";
import ClickAwayListener from "@mui/material/ClickAwayListener";


//snackbar imports

import {

  Box,
  Popper,
  Fade,
  Typography,

} from "@mui/material";
import Divider from "@mui/material/Divider";
import "./notification.css"

import { styled } from "@mui/material/styles";

const ITEM_HEIGHT = 48;

const Styledstack = styled(Stack)({
  
    backgroundColor: "#fdfdff",
    '&:hover': {
      overflowY: "auto",
      
      
   },
  
});

const NotificationList = ({ documents,setOpenSnack }) => {
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = async (event) => {
    // setAnchorEl(event.currentTarget);
    setIsOpen(false);
    // console.log(item);
  };
  // const [anchorEl, setAnchorEl] = useState(null);
  const [notificationNumber, setNotificationNumber] = useState(0);
  const [data, setdata] = useState(null);

  const { updateDocument } = useCostumUpdate("notification");
  // const open = Boolean(anchorEl);

  const handleClick = (event) => {
    console.log("fffrun")
    if(isOpen){
      setIsOpen(false)
    }else{
      setAnchorEl(event.currentTarget);
        setIsOpen(true)
      if (documents.notifications.length>0) {
        // console.log(documents.notifications)
        const max = documents.notifications.reduce((acc, i) =>
          i.notificationTimeStamp > acc.notificationTimeStamp ? i : acc
        );
        // console.log("max");
        // console.log(documents.lastSeen, max);
        if (documents.lastSeen > max.notificationTimeStamp) {
          console.log("user is updated on all notifications");
        } else if (documents.lastSeen < max.notificationTimeStamp) {
          const timeNow = Date.now();
          console.log("update now");
  
          updateDocument(documents.user, timeNow);
        }
      }
    }
    
  };
 

  const countHowManyNotificationsActive = () => {
    const userLastTimeActive = documents.lastSeen;
    let notificationNumberCount = 0;
    documents.notifications.map((item) => {
      if (item.notificationTimeStamp > userLastTimeActive){
        return notificationNumberCount++;}
    });

    setNotificationNumber(notificationNumberCount);

    //  var max = Math.max(
    //   documents.notifications.map(item => console.log(item.notificationTimeStamp))
    //  )
  };
  //snack code
  


  useEffect(() => {
    
    if (documents.notifications) {
    
    countHowManyNotificationsActive();
    
    
    setTimeout(() => {
      if(documents.notifications.length>0){

        const max = documents.notifications.reduce((acc, i) =>
        i.notificationTimeStamp > acc.notificationTimeStamp ? i : acc
        );
        // console.log(max)
        if (
        documents.lastSeen < max.notificationTimeStamp &&
        Date.now() - max.notificationTimeStamp < 10000
        ) {
          setOpenSnack(true);
        }
      }
    }, 3000);

    setdata(
      documents.notifications.sort(   (a, b) => a.notificationTimeStamp - b.notificationTimeStamp  )
      .reverse()
    );
    }
  }, [documents]);

  // useEffect(() => {
  //   console.log(data)
  // }, [data])



  // const addNotification = () => {
  //   // use a random type of notification
  //   toast("Lorem ipsum dolor sit amet, consectetur adipiscing elit", {
  //     type: types[Math.floor(Math.random() * types.length)] 
  //   });
  // };

  // const toggleNotificationCenter = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   setIsOpen(!isOpen);
  // };

  const toggleFilter = (e) => {
    setShowUnreadOnly(!showUnreadOnly);
  };


  return (
    <div>
      <Tooltip title="Notifications">
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="error"
          onClick={handleClick}
        >
          <Stack spacing={10} direction="row" sx={{ color: "action.active" }}>
            <Badge color="error" badgeContent={notificationNumber}>
              <NotificationsNoneIcon />
            </Badge>
          </Stack>
        </IconButton>
      </Tooltip>



      {/* <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "35ch",
          },
        }}
      >
        {data &&
          data.map((item, index) => (
            <div key={item.notificationTimeStamp}>
              <Card sx={{ height: 60 }}>
                <NotificationCard
                  item={item}
                  index={index}
                  handleClose={handleClose}
                />
              </Card>
            </div>
          ))}
      </Menu> */}


            <Popper open={isOpen} anchorEl={anchorEl} transition placement='bottom-end'  sx={{zIndex: 1300,}}>

        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={50}>

            <Box sx={{boxShadow: 3,borderRadius: '8px',}}>
<>
              <Box
                sx={{
                  // borderBottom:5,
                  borderTop:1,borderRadius: '8px',
                  background: "white",
                  padding: "8px",
                  paddingBottom:"0px"
                  // display: "flex",
                  // justifyContent: "space-between",
                  // alignItems: "center"
                }}
                >
                <Typography variant="h6" sx={{mb:1}}>
                  Notification center
                </Typography>
                <Divider />
              </Box>
              <Styledstack className="notification"
                sx={{
              
                  
                  borderRadius: '8px',
                  height: "400px",
                  width: "min(40ch, 40ch)",
                  // padding: "12px",
                  background: "white",
                  overflow: 'hidden'
                  
                }}
                spacing={0}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                  <div className="notifications">

                   {data &&
          data.map((item, index) => (
            <div key={item.notificationTimeStamp}>
              {/* <Card sx={{  }}> */}
                <NotificationCard
                  item={item}
                  index={index}
                  notificationNumber={notificationNumber}
                  // handleClose={handleClose}
                  />
              {/* </Card> */}
            </div>
          ))}
          </div>
      </ClickAwayListener>
          
              </Styledstack>
      
</>
            
            </Box>

          </Fade>
        )}

      </Popper>
     
     </div>
  );
};

export default NotificationList;
