import React from 'react'
import {useState} from 'react'

import { auth } from '../firebase/config'
import { signOut } from 'firebase/auth'
// import  useAuthContext  from './useAuthContext'
import { useNavigate } from "react-router-dom";

import { useAuthContext } from './useAuthContext'
export function useLogout() {
    const navigate = useNavigate();

    const [error,setError] = useState(null)
    const [isPending,setIsPending] = useState(null)

    const { dispatch} = useAuthContext()

    const logout = async () =>{

        setError(null)
        setIsPending(true)
        
        try{
            signOut(auth)

            dispatch({ type:'LOGOUT' })
            setIsPending(false)
            setError(null)
            navigate('/');

        }
        catch(err){
            console.log(err.message)
            setError(err.message)
            setIsPending(false)
            
        }
        signOut(auth)
            .then(()=>{
                console.log('user signed out')
                // dispatch({type:'LOGOUT'})
            })
            .catch((err)=>{
                console.log(err.message)
            })
    }


    return {logout,error,isPending}
}
