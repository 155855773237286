import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

const NotFound = () => {
  const navigate = useNavigate();
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '90vh',
      backgroundColor: '#f0f0f0',
      fontFamily: 'Arial, sans-serif',
    },
    title: {
      fontSize: '36px',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
    description: {
      fontSize: '18px',
      marginBottom: '32px',
    },
  };

  return (
    <div style={styles.container}>
   <Helmet>
  <title>404 - Page Not Found</title>
  <meta name="description" content="Oops! The page you are looking for doesn't exist. Return to the homepage." />
</Helmet>
      <h1 style={styles.title}>404 - Page Not Found</h1>
      <p style={styles.description}>Oops! The page you are looking for doesn't exist.</p>
      <Link to="/">Click Here to go back to main page</Link>
    </div>
  );
}

export default NotFound;
