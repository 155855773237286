import React from 'react';
import styles from './InterviewTypes.module.css'
// import something from './../../../pictures/'

import SupportAgentIcon from '@mui/icons-material/SupportAgent';

//product managment 
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

//more

//health care
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';

//law 
import GavelIcon from '@mui/icons-material/Gavel';


import EngineeringIcon from '@mui/icons-material/Engineering';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MoreIcon from '@mui/icons-material/More';
import ComputerIcon from '@mui/icons-material/Computer';
const InterviewTypes = () => {
    const types = [

        {name:"Technology and Software Development",description:'Practice interviews for roles in Software Engineering, data science, web development, IT support, and cybersecurity... ',do(){
            return (
                <div className={styles.image}>

                <ComputerIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        {name:"Business and Finance",description:'Prepare for business and finance roles in areas such as investment banking, financial analysis, accounting, management consulting, and corporate finance...',do(){
            return (
                <div className={styles.image}>

                <BusinessCenterIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
 
        {name:"Healthcare and Medicine",description:"Prepare for interviews in fields such as nursing, medical assisting, physician roles, and healthcare administration",do(){
            return (
                <div className={styles.image}>

                <HealthAndSafetyIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        {name:"Law and Legal",description:"Prepare for law firm interviews, clerkships, legal internships, and other legal positions.",do(){
            return (
                <div className={styles.image}>

                <GavelIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        {name:"Engineering and manufacturing",description:"Prepare for roles in areas such as mechanical, electrical, civil, and industrial engineering, as well as production and quality management...",do(){
            return (
                <div className={styles.image}>

                <EngineeringIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        {name:"Sales and Customer Service",description:'Prepare for various positions, including sales representatives, account managers, customer service agents, and retail associates',do(){
            return (
                <div className={styles.image}>

                <SupportAgentIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},

        {name:"Project management and operations",description:'Practice interviews for project management and operations roles, such as project coordination, operations management, supply chain, and logistics',do(){
            return (
                <div className={styles.image}>

                <ManageAccountsIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        {name:"More",description:"Explore additional practice interviews for a diverse range of fields and industries not covered in the main categories such as public service, Education, research, Marketing and more",do(){
            return (
                <div className={styles.image}>

                <MoreIcon sx={{width:100,height:100}}/>
                </div>
            )
        }},
        // "IT Support",
        // "Cybersecurity",
        // "Networks and Systems",
        // "Cloud Computing",
        // "Database Administration",
        // "product management/ Scram",
        // "More "
    ]
    return (
        <div style={{textAlign:'center'}} className={styles.container}>
            <h2>Interviews In All Fields</h2>
           <div className={styles.interviewsContainer}>
        {types.map((item)=>(
            <div className={styles.interview} key={item.name}>
                {item.do()}
                <div className={styles.text}>

                {item.name}
                </div>
                <div className={styles.subtext}>
            <p>{item.description}</p>
                </div>
            </div>
        ))}

           </div>
        </div>
    );
}

export default InterviewTypes;
