// import ModalSessions from 'pages/profile/userInfoModal/ModalSessions';
import React from 'react';
import { useState, useEffect,useRef  } from "react";
import styles from "./Availability.module.css";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {default  as RightArrow} from '@mui/icons-material/KeyboardDoubleArrowRight';
import  {default  as LeftArrow} from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {
  addDays,
  addHours,
  format,
  getHours,
  getMinutes,
  getUnixTime,
  set,
} from "date-fns";
const Availability = ({user,setUser}) => {
    const [open, setOpen] = useState(true);

 
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const now = new Date();

  const [weekdays, setweekdays] = useState([]);
  const [chosenhours, setChosenhours] = useState([]);
  const [today, settoday] = useState([]);
  // const {user} = useAuthContext()
  const [days, setdays] = useState([
    "",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
    "Sun",
  ]);

  const createdays = () => {
    const week = [];

    /// first argument how many days after today, second arguemnt how many days you want to add
    for (let i = 1; i < 8; i++) {
      const nextDay = addDays(
        new Date(now.getFullYear(), now.getMonth(), now.getDate()), i);
      const times = [];

      for (let j = 8; j < 24; j = j + 2) {
        const onehour = addHours(new Date(nextDay), j);
        const HourFormated = `${format(onehour, " hh:mm a")}`;

        times.push({
          HourFormated,
          clicked: false,
        });
      }
      const object = {
        day: days[i],
        hour: times,
      };

      week.push(object);
    }
    
    // if(user.availability ){
      
    //   const mixBasicHoursAndUserOldChosenhours = week.map((e, i) => ({
    //     day: e.day,
    //     hour: week[i].hour.map((j,index) =>user.availability[i].hour[index]? user.availability[i].hour[index] : j),
    //   }));
  
    //   setweekdays(mixBasicHoursAndUserOldChosenhours);
    // } else {
      setweekdays(week);
    // }
  };

  const click = (e) => {
    e.clicked = !e.clicked;
    setweekdays([...weekdays]);
    const newarray = weekdays.map((e, i) => ({
      day: e.day,
      hour: weekdays[i].hour.filter((j) => j.clicked === true),
    }));
    setChosenhours(newarray);
    setUser({ ...user, availability: newarray });
  };

  useEffect((e) => {
   
    if (weekdays.length < 6) {
      createdays();
    }
setUser({ ...user, availability:  [
  {
    "day": "Mon",
    "hour": []
  },
  {
    "day": "Tue",
    "hour": []
  },
  {
    "day": "Wed",
    "hour": []
  },
  {
    "day": "Thu",
    "hour": []
  },
  {
    "day": "Fri",
    "hour": []
  },
  {
    "day": "Sat",
    "hour": []
  },
  {
    "day": "Sun",
    "hour": []
  }
] });
    
  }, []);
  const containerRef = useRef(null);

  const scrollToRight = () => {
    console.log('fsdfsf')
    const container = containerRef.current;
    container.scrollBy({ left: 100, behavior: 'smooth' });
  };
  const scrollToLeft = () => {
    const container = containerRef.current;
    container.scrollBy({ left: -100, behavior: 'smooth' });
  };
    return (
        <div className={styles.container}>

           <Typography
            variant="h6"
            display="h6"
            gutterBottom
            
          >
            Please select the times you are available for interviews  
          </Typography>
        <LeftArrow className={styles.left} onClick={scrollToLeft} />
        <RightArrow className={styles.right} onClick={scrollToRight} />
          <div className={styles.modalBody} ref={containerRef}>
          {/* <button type="button" onClick={scrollToRight}>Scroll Right</button> */}
       
        {/* <button type="button" onClick={scrollToLeft}>Scroll Left</button> */}

            {weekdays &&
              weekdays.map((item,i) => (
                <div key={item.day}>
                  <div style={{ position: "sticky" }}>
                    <p>{item.day}</p>
                    {/* <p>{item.day.substr(item.day.indexOf(" ") + 1)}</p> */}
                  </div>
                  {item.hour.map((e,index) => (
                    <div
                    key={`${item.day} ${e.HourFormated}`}
                    onClick={() => click(e)}
                    className={styles.time}
                    style={{
                      background: e.clicked === true ? "green" : "white",
                    }}
                    >
                      <p>
                        {e.HourFormated}
                      </p>
                    </div>
                  ))}
                </div>
              ))}

          </div>
        </div>
    );
}

export default Availability;
