import { useState } from "react";

import { auth } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import {mixpanel} from '../firebase/mixPanel'
import { clarity } from 'react-microsoft-clarity';
import useAnalytics from "src/assets/useAnalytics"

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(null);
  const {logEvent,identify} = useAnalytics()

  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const login = (email, password,path) => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        dispatch({ type: "LOGIN", payload: res.user });
        setLoading(false);
        setSuccess(true);

        mixpanel.people.set({ "name": res.user.displayName ,"$email":email});
        identify(res.user.uid);
        logEvent('Log in',{
          'google':false
        });
    
        
        if(path.includes('meet')){

          navigate(path);
        }else{

          navigate("/");
        }
      })
      .catch((err) => {
        setError(err.message);
        console.log(err);
        setLoading(false);
        setSuccess(true);
      
    })

  }
      
  return { error, login, loading, success };
};
