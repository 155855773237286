import { doc, updateDoc, arrayUnion,setDoc,increment } from "firebase/firestore";

import { db } from "../firebase/config";

  export const useCostumUpdate = (collectionName) => {
  
  const addNotification = async ( docId, updatedDoc,type,otherProperty) => {
    //docid equal the user id which equal the userid of the notifcaion that belongs to the said user
    if(type === 'notifications'){

      try{
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          notifications: arrayUnion(...[updatedDoc]),
        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }
    } else if (type === 'review'){
      console.log("reviewing")
      try{
        console.log({...otherProperty})
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          reviews: arrayUnion(...[updatedDoc]),
          ...otherProperty
        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }

    }
     else if (type === 'upvote'){
      try{
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          upvotesByUsers: arrayUnion(updatedDoc),
          upvotes:increment(1)

        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }

    }
     else if (type === 'downvote'){
      try{
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          upvotesByUsers: updatedDoc,
          upvotes:increment(-1)

        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }
    }
    //upvote and downvoting answer
     else if (type === 'answerUpdate'){
      try{
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          answers:updatedDoc
        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }

    }
     else if (type === 'addAnswer'){
      try{
        const docRef = doc(db, collectionName, docId);
        const response = await updateDoc(docRef, {
          answers: arrayUnion(updatedDoc),
        });
        console.log(response,'worked');
      }
      catch(err){
        console.log('failed',err)
      }

    }
  };

  const updateDocument = async (docId, update)=>{
    const docRefs = doc(db, collectionName, docId);
    const response = await setDoc(docRefs,{
      lastSeen: update
    },{ merge: true })
   
  }
  return { addNotification, updateDocument};
};


//orignal function
// const handleClose = async (option) => {
// 
//   const array = [{name:'muvluv',rating:10}]
//   const docRef= doc(db,'Notifications',"UUe8yS6V1Fy677GW02br")
//   updateDoc(docRef,{
//       likes:arrayUnion(...array)
//   })