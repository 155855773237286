import React from "react";
import styles from "./content.module.css";

import UsersList from "./user/UsersList";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import useCollection from "../../hooks/useCollection";
import { useDocumentList } from "../../hooks/useDocumentList";
import MiniSearch from "minisearch";
import getDocument from "hooks/useDoc";

import { useUpdate } from "../../hooks/useUpdate";

import FiltersList from "./components/FiltersList";
import { Typography } from "@mui/material";
import fantoms from "assets/fantoms";
import UserList from "./userlist/Index"
export default function Body() {
  const { user, userData, privateData } = useAuthContext();
  console.log("🚀 ~ file: Body.js:19 ~ Body ~ userData:", userData);
  const { getUsers } = useDocumentList();
  //first arugment collection name, second is rule, third if you want them to be ordered by creation date
  // const {documents } = useCollection('users',[where('userId','!=',user?user.uid:'user'),where('active','==',true)],false)// original
  // const {documents } = useCollection('users',[['activeDuration','>=',Date.now()]],false)

  const [usersList, setUsersList] = useState([]);
  const [ready, setReady] = useState(false);
  const [filters, setFilters] = useState({
    subCategory: 40,
    category: 50,
    targetRole: 100,
    targetCompany: 50,
    areaOfStudy: 30,
    bootCamp: 70,
    real: 400,
  });
  const [documentsList, setDocumentList] = useState(null);
  const [filter, setFilter] = useState({
    role: null,
    Type: null,
    Company: null,
    topic: null,
    Role: null,
    day: null,
  });

  const matchUsers = async () => {
    setReady(false);
    setUsersList([]);
    //TODO: fix the
    let miniSearch = new MiniSearch({
      fields: [
        "platform",
        "targetRole",
        "targetCompany",
        "category",
        "experience",
        "university",
        "areaOfStudy",
        "currentPosition",
        "currentCompany",
        "educationLevel",
        "bootCamp",
        "real",
      ], // fields to index for full-text search
      storeFields: [
        "persona",
        "id",
        "displayName",
        "real",
        "university",
        "areaOfStudy",
        "currentPosition",
        "currentCompany",
        "educationLevel",
        "aboutMe",
        "bootCamp",
        "bootCampGraduate",
        "skills",
        "topics",
        "experience",
        "targetRole",
        "targetCompany",
        "category",
        "subCategory",
        "interviews",
        "availability",
        "availability",
        "timeZone",
        "active",
        "profile",
        "camera",
        "reviews",
        "rating",
        "interviewTypes",
        "codeWars",
        "interviewTypes",
        "yoe",
      ], // fields to return with search results
      // storeFields: Object.keys(documentsList[0])
    });

    const { acceptedUsers, ignoredUsers } = privateData;
    let ignoredUsersUpdated =
      ignoredUsers.filter((item) => item.dueTime >= Date.now()) || [];

    let total = [...acceptedUsers, ...ignoredUsersUpdated];
    const list = documentsList.map((item) => {
      if(!item.persona){

        item.real = "real";
      }
      return item;
    });
    console.log("🚀 ~ matchUsers ~ documentsList:", documentsList[0]);
    let filteredArray = [...list, ...fantoms]
      .filter((item) => !total.some((obj) => obj.id === item.id))
      .filter((item) => item.id != user.uid)
      .filter((item) => !item.error);
    console.log("🚀 ~ matchUsers ~ filteredArray:", filteredArray);

    miniSearch.addAll(filteredArray);
    let rankedUsers = miniSearch.search(
      `real peerfect2023 ${userData.targetRole} ${userData.targetCompany} ${userData.category} ${userData.category}  ${userData.experience} ${userData.university} ${userData.areaOfStudy} ${userData.currentPosition} ${userData.currentCompany} ${userData.educationLevel} ${userData.bootCamp}
      `,
      { fuzzy: 0.2, boost: filters }
    );
    if (userData.profile) {
      rankedUsers.shift();
    }
    // console.log("🚀 ~ file: Body.js:85 ~ matchUsers ~ rankedUsers:", rankedUsers)

    let delay = false;
    if (filter["Role"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) => (item.targetRole === filter["Role"] || item.currentPosition === filter["Role"])
      );
    }
    if (filter["Company"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) =>( item.targetCompany === filter["Company"]|| item.currentCompany === filter["Company"])
      );
    }
    if (filter["Experience level"]) {

      delay = true;
      if(filter["Experience level"] === "Entry Level"){
        rankedUsers = rankedUsers.filter(
          (item) => item.yoe <=2
        );
      }
      else if(filter["Experience level"] === "Mid Level"){
        
        rankedUsers = rankedUsers.filter(
          (item) => item.yoe >=2 && item.yoe <=5
        );

      }
      else if(filter["Experience level"] === "Senior Level"){
        rankedUsers = rankedUsers.filter(
          (item) => item.yoe >=5
        );
      }
      console.log(filter["Experience level"])
      
    }
    console.log(filter);
    if (filter["Availability"]) {
      delay = true;

      //TODO:update all availability to availability
      rankedUsers = rankedUsers.filter((item) => {
        if (Array.isArray(item.availability)) {
          return item.availability.find(
            (time) =>
              time.hour.length > 0 && time.day === filter["Availability"]
          );
        }
        return false;
      });
    }
    if (delay) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    console.log("🚀 ~ matchUsers ~ rankedUsers:", rankedUsers);
    if (rankedUsers.length === 0 && delay) {
      setReady("no results");
    }

    if (rankedUsers.length === 0 && !delay) {
      setReady("runout");
      // update(user.uid,{ignoredUsers:[]})
    }

    setUsersList(rankedUsers);
  };

  useEffect(() => {
    if (privateData && userData && documentsList) {
      matchUsers();
    }
  }, [userData, filter, privateData, documentsList]);

  useEffect(() => {
    (async () => {
      let docId = "6YgYuXNdYuagxtlCsPMH";

      let [documents, global] = await Promise.all([
        getUsers(),
        getDocument("global", docId),
      ]);
      console.log(global);
      if(global){
        console.log("🚀 ~ global:", documents)
        
        setDocumentList([...documents, ...global.profiles]);
      }else{
        setDocumentList(documents);
      }
    })();
  }, []);
  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", margin: 3 }}
      >
        Find Mock Interview Partner
      </Typography>
      <div className={styles.content}>
        <div className={styles.users}>
          <UsersList documents={usersList} ready={ready} />
        </div>
        <div className={styles.filterContainer}>
          <FiltersList setFilter={setFilter} filter={filter} />
        </div>

        {/* <UserList documents={usersList} ready={ready}/> */}
      </div>
    </>
  );
}
