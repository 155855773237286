import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import interview from "/interview.webp";
import find from "/find.png";
import wait from "/wait.jpg";
import request from "/request.jpeg";
import styles from "./Tutorial.module.css"
import { useAuthContext } from 'hooks/useAuthContext';
import { useUpdate } from 'hooks/useUpdate';
import { mixpanel } from "../firebase/mixPanel";
import useAnalytics from "src/assets/useAnalytics"

const Firstpage= ()=>{
    return(
<div className={styles.container}>
<DialogTitle sx={{textAlign:'center'}}>How it works</DialogTitle>
<div className={styles.image}>

<img src={find} alt="" className={styles.find}/>
</div>
<DialogContent key={1}> Find a suitable user and send them a mock interview request with the details about the interview </DialogContent>
</div>
    )
}
const Secondpage= ()=>{
    return(
<div className={styles.container}>
<DialogTitle sx={{textAlign:'center'}}>Prepare For the Mock Interview</DialogTitle>
<div className={styles.image}>

<img src={wait} alt=""className={styles.find} />
</div>
<DialogContent key={1}>Get ready by practicing the provided interview questions, as you'll use them when interviewing your peer</DialogContent>
</div>
    )
}
const Thirdpage= ()=>{
    return(
<div className={styles.container}>
<DialogTitle sx={{textAlign:'center'}}>Sending Request</DialogTitle>
<div className={styles.image}>

<img src={request} alt="" />
</div>
<DialogContent key={1}>When someone accepts your interview requests, or you accept someone else request, the interview is scheduled </DialogContent>
</div>
    )
}
const Fourthpage= ()=>{
    return(
<div className={styles.container}>
<DialogTitle sx={{textAlign:'center'}}>The Mock Interview</DialogTitle>
<div className={styles.image}>

<img src={interview} alt="" />
</div>
<DialogContent key={1}>Participate in a 45-60 minute mock interview with your partner, where you both take turns interviewing each other.</DialogContent>
</div>
    )
}
const TutorialModal = ({ open, handleClose }) => {
  const analytics = useAnalytics()

   const {user}= useAuthContext()
   const {update} = useUpdate("privateUsers")  
   const [currentPage, setCurrentPage] = useState(1);
    const [loading,setloading]=useState(false)
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSubmit= async()=>{
    if(loading)return

    try {
        await update(user.uid,{tutorial:true})
        analytics.logEvent("HowItWorksCompleted");
    handleClose()
    } catch (error) {
        console.log(error)
    }
    setloading(true)
    
  }
  // Content for each page
  const pageContent = [
    <Firstpage/>,<Thirdpage/>,<Secondpage/>,<Fourthpage/>
  ];

  return (
    <div>
      <Dialog open={open} >
       
        {pageContent[currentPage - 1]}
        <DialogActions sx={{display:'flex',justifyContent:'space-between'}}>


            { <Button disabled={currentPage ===1} onClick={handlePreviousPage}>Back</Button>}
          {currentPage  <4 ? (
              <Button onClick={handleNextPage}>Next</Button>
              ) : (
                  <Button onClick={handleSubmit}>{loading?'loading ...':"I understand"}</Button>
                  )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TutorialModal;