import {useState,useEffect} from 'react';
import styles from './InterviewTypes.module.css'

//icons
import TerminalIcon from '@mui/icons-material/Terminal'; 
import ArchitectureIcon from '@mui/icons-material/Architecture'; 
import PersonSearchIcon from '@mui/icons-material/PersonSearch'; 
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'; 
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
 import QueryStatsIcon from '@mui/icons-material/QueryStats';
 import MoreIcon from '@mui/icons-material/More';
import { TextField, Typography } from '@mui/material';
const InterviewTypes = ({profile,setrequestInfo,requestInfo,userData}) => {

  console.log('gggg',userData)
  const [interviewList,setInterviewList] = useState([])

  const list = [
    {
      type: "General Interview",
      description:
        `practice normal interview that covers a range of aspects relevant to the role with topics such as your professional background, skills, accomplishments, problem-solving abilities.`,
    icon:<PersonSearchIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    },
    {
      type: "Behavioral Interview",
      description:
        "Practice behavioral interview questions, covering topics such as describing your recent professional experience, workplace challenges, teamwork skills, and more!",
        icon:<RecordVoiceOverIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    },
    {
      type: "Technical Interview",
      description:
        "practice technical interview aimed at evaluating your expertise and problem-solving skills relevant to the role. This interview will delve into your practical knowledge of tools, technologies, and methodologies essential for the position.",
        icon:<TipsAndUpdatesIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    },

   
    {
      type: "Case Study Interview",
      description:
        "Prepare for a case study interview where you'll be presented with real or hypothetical business scenarios. Your task is to analyze the situation, identify key issues, and propose well-reasoned solutions. This interview assesses your problem-solving, analytical thinking, and communication skills as you walk through your approach to tackling complex challenges.",
        icon:<QueryStatsIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    },
    {
      type: "coding Interview",
      description:
        "Practice Coding problems and leetcode questions. This interview will test your problem solving skills and language featues",
        icon:<TerminalIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    },
    {
      type: "System Design",
      description:
        "Practice system design questions covering topics such as API design, scaling, performance, data modeling, network architecture and more",
        icon:<ArchitectureIcon sx={{height:40,width:40,color:'#4960a7'}}/>
    }

  ]
      useEffect(() => {
        let chosenList = profile.interviewTypes
        if(requestInfo.role === userData.targetRole){
          chosenList = userData.interviewTypes
        }
        console.log("🚀 ~ useEffect ~ chosenList:", chosenList)
        let results = chosenList.map((item)=>{
          console.log("🚀 ~ results ~ item:", item)
          let result =list.find((element)=>element.type.toLowerCase()===item.toLowerCase())
          if(result) return result
          else{
            return {type:item,description:"",}
          }
        })
        console.log("🚀 ~ results ~ profile.interviewTypes:", results)
      setInterviewList(results)
     if(results.length ===1) {

        setrequestInfo({ ...requestInfo, interviewType: results[0].type })
      }
       
      }, []);





      
    return (
        <div className={styles.container}>
              {interviewList.map((item)=>(
                <div key={item.type} className={styles.interview} onClick={()=>setrequestInfo({ ...requestInfo, interviewType: item.type })}
                style={{border:item.type === requestInfo.interviewType?'#fbae41 solid 3px':''}}
                >
                  
                    {item.icon}
                    <Typography variant="h7" gutterBottom sx={{fontSize:18,m:'auto',textAlign:'center'}}>
                    {item.type}
      </Typography>
                </div>
              ))}
          

            {interviewList.map((interview)=>(
             <div key={interview.description}>
             {interview.type === requestInfo.interviewType &&(
                <>
                    <Typography  sx={{ml:1.5,mt:3,textAlign:'center'}} variant="h6" display="block" gutterBottom >
                    {interview.type}
                  </Typography>
                      <Typography  sx={{ml:1.5}} variant="body2" display="block" gutterBottom >
                    {interview.description}
                  </Typography>
                </>
                )}
               </div>
           ))}
           {/* { 'Other'=== requestInfo.interviewType && (
            <div style={{display:'flex',justifyContent:'flex-start',width:'100%',marginLeft:35,alignItems:'flex-end'}}>
                      <Typography  sx={{mr:2,ml:1}} variant="h7" display="block" gutterBottom >
                 Interview Type:
                  </Typography>
                     <TextField sx={{alignSelf:'flex-start',mt:1 }} size="small" placeholder='case study' value={requestInfo.costumInterview}  variant="outlined" onChange={(e)=> setrequestInfo({ ...requestInfo, costumInterview: e.target.value})}/>
            </div>
           )} */}
        </div>
    );
}

export default InterviewTypes;
