import React,{useState} from 'react'

import styles from "./Reviews.module.css"

import Avatar from "@mui/material/Avatar";
import { deepOrange, deepPurple } from "@mui/material/colors";

import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import ReviewCard from '../../../Components/reviews/ReviewCard';
export default function ReviewItem({review}) {
    console.log(review)
  return (
    <div>
            <div className={styles.reviewcontainerall}>
      <div className={styles.reviewcontainer}>
        <div className={styles.icon}>
          { }
          <Avatar sx={{ bgcolor: deepOrange[500], width: 50, height: 50 }}>
          {review.user.substring(0,1).toUpperCase()}
          </Avatar>
        </div>
        <div className={styles.name}>{review.user} </div>
        <div className={styles.date}>
          <Typography variant="caption" display="block" gutterBottom>
          {review.date}
          </Typography>
        </div>
        <div className={styles.ratingskills}>
          <Rating name={styles.readonly} defaultValue={review.reviewRating} readOnly    precision={0.5}
/>
        </div>
        <div className={styles.ratinginterview}>{review.interviewType}</div>
      </div>
      <div className={styles.reviewtext}>
        
        <Typography variant="body2" gutterBottom textAlign={"left"}>
        {review.review}
      </Typography>
      </div>
    </div>
    </div>
  )
}
