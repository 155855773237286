import React, { useState, useEffect } from "react";
import {
    doc,
  query,
  getDoc ,

} from "firebase/firestore";
import { db } from "./../firebase/config";

 const useDoc = async (collectionName,id) => {  
  
    try{
      let  ref = query(doc(db, collectionName,id))
      // let  ref = query(collection(db, "users"))
      
      const docSnap  = await getDoc(ref);
     console.log(docSnap.data(),'data')
      return { ...docSnap.data(), id: docSnap.id }
    }catch(err){
      console.log('error',err)
    }
    
  }

  export default useDoc



