import React, { useState, useEffect } from "react";
import { db } from "../firebase/config";
import { collection, onSnapshot,query,where,orderBy } from "firebase/firestore";

export default function useCollection(collectionName,querytype,order,user) {
  const [documents, setDocuments] = useState(null);
  const [error, setError]= useState(null)
  useEffect(() => {
    if(collectionName === 'reviews' && !user){
      return
    }
    let ref = collection(db, collectionName)
    // console.log()
    if (querytype ){
   
      if(collectionName === 'interviews'){
        ref = query(ref,where(querytype[0],querytype[1],querytype[2]),orderBy("interviewRemainder"))
      }else{
        const editedQuery = querytype.map(item=> where(item[0],item[1],item[2]))
        console.log(editedQuery.length)
        ref = query(ref,...editedQuery)
        if (order){
          ref = query(ref,...editedQuery,orderBy("createdAt", "desc"))
        }
      }
    }
    
    // if (order){
    //   ref = query(ref,orderBy("createdAt", "desc"))
    // }
   const unsub = onSnapshot(ref, (snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        
        results.push({ ...doc.data(), id: doc.id });
      })

      setDocuments(results);
      setError(null)
    },(error)=>{
      console.log(error)
      setError('could not fetch the data')
    });

    return () => unsub()

  }, [ collectionName]);


  
  return {documents,error}
}
