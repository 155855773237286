import React from "react";
import UserCard from "./UserCard";
import { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { mixpanel } from "../../../firebase/mixPanel";

//file imports
import UserModalContainer from "./UserModalContainer";
import Reviews from "../components/Reviews";
import { useUpdate } from "../../../hooks/useUpdate";
import { Button } from "@mui/material";
import useAnalytics from "src/assets/useAnalytics"

const humanizeDuration = require("humanize-duration");

export default function UsersList({ documents, ready }) {
  const [userProfile, setUserProfile] = useState(null);
  const {logEvent,identify} = useAnalytics()

  const [index, setIndex] = useState(0);
  const [skipNumber, setSkipNumber] = useState(0);

  let time = Date.now();
  const { user, dispatch, privateData } = useAuthContext();
  const { documentArrayPush, update ,ArrayDelete} = useUpdate("privateUsers");

  const changeUser = (item) => {
    setUserProfile(false);
    console.log(
      "🚀 ~ file: UsersList.js:34 ~ changeUser ~ documents[index]:",
      documents[index]
    );

    setSkipNumber(skipNumber + 1);
    // console.log(skipsNumber)
    let diffenceTimeStamp = Date.now() - time;
    const diffrence = humanizeDuration(diffenceTimeStamp, {
      largest: 1,
      round: true,
    });
    time = Date.now();
    logEvent("Skip", {
      "Skip Interval": diffrence,
      "Skip Interval As seconds": Math.floor(diffenceTimeStamp / 1000),
    });
    //this array will have users that were already seen
    let userIgnoredPrev = privateData.ignoredUsers.find(
      (item) => item.id === documents[index].id
    );
    let interval = 1
    let nextInterval = 2
   if(userIgnoredPrev){ 
    interval = userIgnoredPrev.interval
    nextInterval = userIgnoredPrev.interval*2
    ArrayDelete(user.uid, "ignoredUsers", userIgnoredPrev);
  }
    
    let days =  86400000 *interval   //one day times interval
    let DaysFromNow= Date.now() + days
    let obj = {
      id: documents[index].id,
      dueTime: DaysFromNow,
      swipe: "left",
      interval:nextInterval,
    };
    console.log(obj)
    documentArrayPush(user.uid, "ignoredUsers", obj);
    dispatch({ type: "update",payload: {
        dataType: "privateData",
        value: obj,
        property: "ignoredUsers",
      },
    });

    //   if(index < documents.length-1){
    //     // console.log(users.length)
    //  setUserProfile(false)

    //  setIndex(index+1)

    //  setTimeout(() => {

    //    setUserProfile(documents[index+1])
    //   console.log(documents[index+1])
    //  }, 100)

    // } else {
    //   setUserProfile(false)
    //   setIndex(0)

    //  setTimeout(() => {

    //    setUserProfile(documents[0])

    //  }, 100)
    // }
  };

  useEffect(() => {
    setUserProfile(documents[0]);
  }, [documents]);
  //  console.log("🚀 ~ file: UsersList.js:91 ~ useEffect ~ documents:", documents)

  const ref = useRef(null);

  const [card1height, setcard1height] = useState(546);
  const [card2height, setcard2height] = useState(551);

  useEffect(() => {
    if (userProfile) {
      setcard1height(ref.current.clientHeight + 5);
      setcard2height(ref.current.clientHeight + 10);
    }
  }, [ref, userProfile]);

  const [openModal, setOpenModal] = useState(false);


  const closeModal = () => {
    setOpenModal(false);
  };


  const [reviewModalOpen,setReviewModal]= useState(false)
  const handleReviewModalOpen = () => {
    setReviewModal(true);
  };
  const handleReviewModalClose = () => {
    setReviewModal(false);
  }

  return (
    <div
      key={() => Math.floor(Math.random() * 20000)}
      className="card-container"
    >
      {userProfile && (
        <div
          key={userProfile.id}
          className="user-card2"
          style={{ height: card2height }}
        >
          <div
            key={userProfile.id}
            className="user-card1"
            style={{ height: card1height }}
          >
            <div key={userProfile.id} ref={ref} className="user-card">
              <UserCard
                item={userProfile}
                changeUser={changeUser}
                setmodal={setOpenModal}
                handleClickOpen={handleReviewModalOpen}
              />
            </div>
          </div>
        </div>
      )}
      {!userProfile && !ready && (
        <div className="user-card2">
          <div className="user-card1">
            <div className="user-card">
              <div className="card-user-container">
                {/* <CardActionArea onClick={()=> setmodal(true)}> */}
                <div className="card-icon">
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                  {/* For other variants, adjust the size with `width` and `height` */}
                  <Skeleton variant="circular" width={40} height={40} />
                </div>
                <div className="cardinfo">
                  <Skeleton variant="rectangular" width={450} height={60} />
                  <Skeleton variant="rounded" width={450} height={60} />
                  <Skeleton variant="rectangular" width={450} height={120} />
                  <Skeleton variant="rounded" width={450} height={120} />
                </div>
                <div className="user-buttons">
                  <button className="skip">
                    <ClearIcon sx={{ height: 35, width: 35 }} />{" "}
                  </button>
                  <button className="interview">
                    <DoneIcon sx={{ height: 35, width: 35 }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!userProfile && ready === "runout" && (
        <div
          style={{ padding: "100px 50px", textAlign: "center" }}
          className="user-card"
        >
          <h2>You've seen all of our recommendations for today.</h2>
          <h4>Check back tomorrow for more.</h4>
        </div>
      )}
      {!userProfile && ready === "no results" && (
        <div
          style={{ padding: "100px 50px", textAlign: "center" }}
          className="user-card"
        >
          <h3>no results found that</h3>
        </div>
      )}

      {reviewModalOpen && (
        <Reviews
          handleClose={handleReviewModalClose}
          handleClickOpen={handleReviewModalOpen}
          open={reviewModalOpen}
          user={userProfile}
        />
      )}
      {openModal && (
        <UserModalContainer
          skipNumber={skipNumber}
          item={userProfile}
          open={openModal}
          handleClose={closeModal}
          user={userProfile}
          setmodal={setOpenModal}
          time={time}
          changeUser={changeUser}
        />
      )}
    </div>
  );
}
