import {default  as RightArrow} from '@mui/icons-material/KeyboardDoubleArrowRight';
import  {default  as LeftArrow} from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Typography from '@mui/material/Typography';

//hooks

const Times = ({containerRef, scrollToLeft, scrollToRight, weekAvailabilty, getMonthDayFromTimestamp, requestInfo, ChooseItem}) => {
    return (
         <div className="pop-container" ref={containerRef}>
        <div className="arrowContainer">
          <LeftArrow className="left" onClick={scrollToLeft} />
          <RightArrow className="right" onClick={scrollToRight} />
        </div>
        {weekAvailabilty && weekAvailabilty.map((each) => (
          each.hour.length > 0 && (
            <div key={each.day} className="day-container">
              <Typography sx={{ m: 0 }} variant="h6" gutterBottom>
                {each.day.split(" ")[0]}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {getMonthDayFromTimestamp(each.hour[0].timestamp)}
              </Typography>
              {each.hour.map((session) => {
                const isAM = session.time.includes('AM');
                return (
                  <div
                    key={session.timestamp}
                    className={`hour ${session.timestamp === requestInfo.time.timestamp ? 'selected' : ''} ${isAM ? 'am' : 'pm'}`}
                    onClick={() => ChooseItem(session)}
                  >
                    {session.time}
                  </div>
                );
              })}
            </div>
          )
        ))}
      </div>
    );
}

export default Times;
