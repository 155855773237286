import React, { useState, useEffect } from 'react';
import { Button, Container, TextField, Typography, Box, useMediaQuery } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Slider from '@mui/material/Slider';
import companyList from "assets/companyList";

const CurrentCompany = ({ user, setUser, handleNext, loading }) => {
    
    const list = companyList.map((item) => ({
        title: item
    }));
    const filter = createFilterOptions();
    const isMobile = useMediaQuery('(max-width:600px)');

    function valuetext(value) {
        return `${value}`;
    }

    const marks = [
        { value: 1, label: '1' },
        { value: 5, label: '5' },
        { value: 10, label: '10' },
        { value: 15, label: '15' },
        { value: 20, label: '20' },
    ];

    return (
        <Container>
            <Typography variant="h3" gutterBottom sx={{ fontSize: "40px", mt: 2 }}>
                Where do you work?
            </Typography>
            <Autocomplete
            clearOnBlur={false}
        sx={{ mt: 2 }}
        
      value={user.currentCompany}
      onInputChange={(event, newValue) => {
       setUser({ ...user, currentCompany: newValue })
      }}
      onChange={(event, newValue) => {
       setUser({ ...user, currentCompany: newValue.inputValue || newValue.title })
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        // console.log("🚀isExisting:", isExisting)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      
      handleHomeEndKeys
      id="target-role"
      options={list}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
      variant="standard" 
      freeSolo
      
      renderInput={(params) => (
        <TextField  
         {...params} 
        value={user.currentCompany}  
        placeholder={"Walmart"} 
        label="" 
        variant="standard"   
        helperText={isMobile ? "Last company" : "Last company you worked at"}
        sx={{
            width:isMobile ? '100%' : 450,
            '.MuiInputBase-input': { fontSize: isMobile ? 22 : 30 },
            
          }}
        />
        
      )}
    //   renderInput={(params) => (
    //     <TextField
    //         required
    //         {...params}
    //         value={user.currentCompany}
    //         // label={isMobile ? "Last company" : "Last company you worked at"}
    //         placeholder="Walmart"
    //         helperText="add the last company you worked at "
    //         variant="standard"
    //         sx={{ width: isMobile ? '100%' : 450 }}
    //         inputProps={{ style: { fontSize: 36 } }}
    //     />
    // )}
    />
            {/* <Typography variant="h3" gutterBottom sx={{ fontSize: "40px", mt: 5 }}>
                Years of Experience
            </Typography>

            <Box sx={{ width: '100%' }}>
                <Slider
                    aria-label="Years of Experience"
                    // defaultValue={user.yoe}
                    value={user.yoe}
                    getAriaValueText={valuetext}
                    step={1}
                    marks={marks}
                    min={1}
                    max={20}
                    valueLabelDisplay="auto"
                    onChange={(e,n)=>setUser({ ...user,yoe:n})}
                />
            </Box> */}
        </Container>
    );
};

export default CurrentCompany;
