import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CancelInterviewConfirmation.module.css';

const CancelInterviewConfirmation = ({ isOpen, onClose, onConfirm, isLoading, otherUser }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <h2 className={styles.title}>Cancel Interview</h2>
        <p className={styles.description}>
          Are you sure you want to cancel this interview? This action cannot be undone.
        </p>
        {otherUser && (
          <p className={styles.rescheduleInfo}>
            If you want to reschedule, you can view{' '}
            <Link to={`/profile/${otherUser.id}`} className={styles.profileLink} onClick={onClose}>
              {otherUser.name}'s profile
            </Link>
            {' '}to find another suitable time.
          </p>
        )}
        <div className={styles.buttons}>
          <button 
            className={`${styles.button} ${styles.cancelButton}`} 
            onClick={onClose} 
            disabled={isLoading}
          >
            Cancel
          </button>
          <button 
            className={`${styles.button} ${styles.confirmButton}`} 
            onClick={onConfirm} 
            disabled={isLoading}
          >
            {isLoading ? 'Cancelling...' : 'Confirm'}
          </button>
        </div>
        {isLoading && <div className={styles.loader}></div>}
      </div>
    </div>
  );
};

export default CancelInterviewConfirmation;