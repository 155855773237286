import "./App.css";
import { useState, useEffect,lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes,Navigate  } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useCollectionItem } from "./hooks/useCollectionItem";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFireStore } from './hooks/useFireStore'
import getDocument from './hooks/useDoc'
import { useUpdate } from "./hooks/useUpdate";
import {Helmet} from 'react-helmet'
import Plausible from 'plausible-tracker'

//pages
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NotFOund from "./pages/404"
import Browse from "./pages/home/Index";
import LandingPage from "./pages/landingPage/Index";
import Interviews from "./pages/interviews/Index";
import Boarding from "./pages/onBoarding/Main"

//should be lazy loaded
const QuestionsSecond = lazy(()=>import("./pages/PersonalInterviewQuestions/Index"))
const CoverLetter = lazy(()=>import("./pages/coverletter/Index"))
const Setting = lazy(()=>import("./pages/settings/Index"))
const Profile = lazy(()=>import("./pages/profile/Index"))
const Reviews = lazy(()=>import("./pages/Reviews"))
const Review = lazy(()=>import("./pages/review/Index"))
const CodeWars = lazy(()=>import("./pages/codeWars/Index"))
const Meeting = lazy(()=>import("./pages/meeting/Index"))
const AddInterview = lazy(()=>import("./pages/questions/addInterview/AddInterview"))
const Practice = lazy(()=>import("./pages/codingPractice/Index"))
//./components
import Nav from "./Components/Nav";
import Footer from "./Components/Footer";
import MainSkeleton from "./Components/MainSkeleton";
import TutorialModal from "./Components/TutorialModal";
import ScrollToTop from "./ScrollToTop";
import ModalForm from "./pages/profile/userInfoModal/ModalForm";

// import 'intro/introjs.css';
// import capture from "assets/analytics"
function App() {

  const { authIsReady, user,dispatch,userData } = useAuthContext();
  // const {addDocument, repsonse} = useFireStore('users')
  const {document:notificationDoc} = useCollectionItem("notification", user?.uid||"")

  const [shownav, setshownav] = useState(true);

  const {update,setloading} = useUpdate("users")
  const [Modal, setModal] = useState(false);
  const [document,setDocument] =useState(null)
  const [profileNotDone,setProfileNotDone]= useState(false)
  const [newUser,setNewUser]= useState(false)

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateActiveStatus=async(doc)=>{
    let diffrence = doc.activeDuration-Date.now()
    if(diffrence< 156 ){
    await  update(user.uid,{activeDuration:Date.now() + 604800000})

    }
  }

  const userContextData= async()=>{
    if(!user){
      return
    }
    const userDataPromise = getDocument('users', user.uid);
    const privateDataPromise = getDocument('privateUsers', user.uid);
    
    const [userData, privateData] = await Promise.all([userDataPromise, privateDataPromise]);
    if(userData && privateData){

      dispatch({ type: "profile", payload: {privateData,userData} });
    }
    if (!userData.onBoarding) {
      setProfileNotDone(true);
    }
    if(!privateData.tutorial){
      handleOpen()
    }
      setDocument(userData)
    updateActiveStatus(userData)
  }

  useEffect(()=>{

    userContextData()
  },[user])
  useEffect(()=>{

    console.log(profileNotDone,'5')
  },[profileNotDone])
  // const userData


  const addQuestions =async () => {

console.log("hi")
    return
    const functions = getFunctions();
      const InterviewRemainderSend = httpsCallable(functions, "generatePracticeQuestions");
  InterviewRemainderSend(userData)
    // console.log("🚀 ~ file: App:86 ~ addQuestions ~ result:", result)
    
  console.log('ru')
  // return
 
    // console.log(questions)
    // for(let us of users){

    //   await addDocument(us)
    //   }
    // await addDocument({hi:'fd'})
      
  };
  if(profileNotDone || newUser){
 return (
 <div>
    <Suspense fallback={<MainSkeleton/>}>

     <BrowserRouter>
          
          {/* <button onClick={addQuestions}>click</button> */}
          <ScrollToTop/>
            <Routes>
              <Route path="/setup" element={<Boarding setProfileNotDone={setProfileNotDone} setNewUser={setNewUser}/>} />
              <Route path="*" element={<Navigate replace={true}  to="/setup"/>} />
            </Routes>

        </BrowserRouter>
        </Suspense>
 </div>
 )
  }



  return (
    <div className="App" >
      <Helmet>
    <title>Peerfect: Practice mock interviews</title>
    <meta name="description" content="Ace Your Interviews, Secure Your Future: Free mock interview Practice for Your Dream Job" />
  </Helmet>
      {authIsReady && (
  <Suspense fallback={<MainSkeleton/>}>

        <BrowserRouter>
          <Nav  setshownav={setshownav} shownav={shownav} notificationDoc={notificationDoc}/>
          <TutorialModal handleClose={handleClose} open={open}/>
          { Modal &&  <ModalForm setModal={setModal} document={document}/>}
          <div className={user?"main-body":'' } style={{marginLeft:(shownav && user)?'240px':'0px'}} >
          {/* <button onClick={addQuestions}>click</button>  */}
          {/* <button onClick={() => console.log(user.fjf.fojdf.ff())}>Break the world</button> */}
          <ScrollToTop/>
            <Routes>
              <Route path="/" element={!user?<LandingPage />: <Navigate replace={true}  to="/browse"/>} />
              <Route path="/setup" element={ profileNotDone ?<Boarding setProfileNotDone={setProfileNotDone} setNewUser={setNewUser}/>:  <Navigate replace={true}  to="/browse"/>} />
              <Route path="/browse" element={user?<Browse />: <Navigate replace={true}  to="/"/>} />
              <Route path="/Reviews" element={<Reviews user={user} />} />
              <Route path="/login" element={<Login user={user} setNewUser={setNewUser}/>} />
              <Route path="/signup" element={<SignUp user={user} setNewUser={setNewUser}/>} />
              <Route
                path="/interviews"
                element={user ? <Interviews user={"ffjff"} /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/codewars"
                element={user ? <CodeWars /> :  <Navigate replace={true}  to="/login"  />}
              />
              <Route
                path="/meet/:id"
                element={user ? <Meeting /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/practice/:id"
                element={user ? <Meeting /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/problems/"
                element={user ? <Practice /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/problemsPractice/:id"
                element={user ? <Meeting /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/questions"
                element={user ? <QuestionsSecond /> :  <Navigate replace={true}  to="/login"/>}
              />
          
              <Route
                path="/profile/:id"
                element={user ? <Profile /> :  <Navigate replace={true}  to="/login"/>}
              />
              {/* <Route
                path="/questions"
                element={user ? <Questions /> : <Questions />}
              /> */}

              <Route
                path="/coverletter"
                element={user ? <CoverLetter /> : <CoverLetter />}
              />

              <Route
                path="/settings"
                element={user ? <Setting /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/addinterview"
                element={user ? <AddInterview /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route
                path="/review/:id"
                element={user ? <Review /> :  <Navigate replace={true}  to="/login"/>}
              />
              <Route path="*" element={<NotFOund/>} />
            </Routes>
          </div>

         {!user&& <Footer />}
        </BrowserRouter>
        </Suspense>

      )}
      {/* {!authIsReady && <MainSkeleton/>} */}
    </div>
  );
}

export default App;
