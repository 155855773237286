import {useState} from 'react'

import { auth } from '../firebase/config'
import { createUserWithEmailAndPassword,updateProfile  } from 'firebase/auth'
import { useAuthContext } from './useAuthContext'
import { useNavigate } from 'react-router-dom'
import {mixpanel} from '../firebase/mixPanel'
import { clarity } from 'react-microsoft-clarity';
import useAnalytics from "src/assets/useAnalytics"


export const useSignUp =() => {
  const [error,setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch} = useAuthContext()
  const {logEvent,identify} = useAnalytics()

  const navigate =useNavigate()

  const signup = async (email,password, displayName) =>{
    setError(null)
    setIsPending(true)
    
    try{
       const res =  await createUserWithEmailAndPassword(auth, email, password)
      //  console.log('user signedup')
      //  console.log(res.user)
      
      if(!res){
        throw new Error('could not complete signup')
      }
      
      //adding name to user
      // await updateProfile(res.user,{displayName})
      // console.log(res.user,'user is ')
      //res.user.displayName    res.user.uid
      dispatch({type: 'LOGIN', payload:res.user})
      dispatch({ type: "profile", payload: {privateData:{acceptedUsers:[],ignoredUsers:[]},userData:{previousCodingProblems:[]}} });

      setIsPending(false)
      setError(null)
   
      identify(res.user.uid,{email:email,name:res.user.displayName})
    logEvent('Sign up', {
      'google':false,
      referee: document.referrer 
    });
      clarity.identify(res.user.uid);
      console.log("work")
      navigate("/setup")
    }
    catch(err){
      console.log(err.message)
      setError(err.message)
      setIsPending(false)
    }

    
  }

  return{error, signup,isPending}
}
