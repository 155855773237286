import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/config";

export const useCollectionItem =  (collectionName, Id) => {
  const [document, setDocument] = useState(null);

  
  useEffect(() => {
      if(Id){

      const docRef = doc(db, collectionName, Id);
      const unsubscribe = onSnapshot( docRef,(doc) => {
        
        // console.log(doc.data(), "document has been received");
          setDocument({ ...doc.data(), id: doc.id });
      
        },
        (error) => {
          console.log(error.message, "error with the document");
        }
      );

    return () => {
      unsubscribe();
    };
  }

  }, [collectionName, Id]);

  return { document };
};
